// filterByDateRange.js
export const filterByDateRange = (data, dateKey, dateRange) => {
    if (!dateRange) return data;
    const now = new Date();
    return data.filter(item => {
        const itemDate = new Date(item[dateKey]);
        switch (dateRange) {
            case 'last7Days':
                return now - itemDate <= 7 * 24 * 60 * 60 * 1000;
            case 'lastMonth':
                return itemDate.getMonth() === now.getMonth() - 1;
            case 'last3Months':
                return itemDate.getMonth() >= now.getMonth() - 3 && itemDate.getMonth() <= now.getMonth();
            case 'lastYear':
                return itemDate.getFullYear() === now.getFullYear() - 1;
            default:
                return true;
        }
    });
};
