import { Navigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AdminRoute = ({ children }) => {
  const [user, setUser] = useState(null); 
  const [token] = useState(() => localStorage.getItem('token') || null);
  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    const fetchProfile = async () => {
      if (!token) {
        setLoading(false); 
        return;
      }

      try {
        const response = await axios.get('https://admin.bizstor.lk/api/profile', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUser(response.data);
      } catch (error) {
        console.log('');
      } finally {
        setLoading(false); 
      }
    };

    fetchProfile();
  }, [token]);

  if (loading) {
    return;
  }

  if (user?.email === "bizstoradmin@gmail.com") {
    return children;
  } else {
    return <Navigate to="/dashboard" />;
  }
};

export default AdminRoute;
