import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    TextField,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    Typography,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    ListSubheader,
    IconButton
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const productPrices = {
    "DOT4 250ml (White)": 850,
    "DOT3 250ml (Red)": 580,
    "DOT3 250ml (White)": 580,
    "DOT3 100ml (Red)": 290,
    "DOT3 100ml (White)": 290,
    "DOT3 50ml (Red)": 170,
    "DOT3 50ml (White)": 170,
    "Green 1L": 750,
    "4L + 4 Water(L)": 1800,
    "4L Pure": 1350
};

const FormWithTable = () => {
    const [rows, setRows] = useState([{ id: 1, product: '', quantity: '', rate: '', price: '', servicedate: new Date().toISOString().split('T')[0], tax: '' }]);
    const [fullname, setFullname] = useState('');
    const [email, setEmail] = useState('');
    const [type, setType] = useState('');
    const [payamount, setPayamount] = useState('');
    const [customer, setCustomer] = useState('');
    const [address, setBillingAddress] = useState('');
    const [term, setTerm] = useState('due on receipt');
    const [invoicedate, setInvoiceDate] = useState(new Date().toISOString().split('T')[0]);
    const [duedate, setDueDate] = useState('');
    const [description, setDescription] = useState('');
    const [discount, setDiscount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [customers, setCustomers] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedCustomerData, setSelectedCustomerData] = useState({
        id: '',
        fullname: '',
        email: '',
        address: '',
    });

    useEffect(() => {
        const fetchCustomers = async () => {
            try {
                const response = await axios.get('https://admin.bizstor.lk/api/get-customer-data');
                setCustomers(response.data.customers);
            } catch (error) {
                console.error('Error fetching customers:', error);
            }
        };

        fetchCustomers();
    }, []);

    const handleOptionChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedOption(selectedValue);

        if (selectedValue === 'Direct Customer') {
            setSelectedCustomerData({
                id: '',
                fullname: '',
                email: '',
                address: '',
            });
        } else {
            const selectedCustomer = customers.find((customer) => customer.id === selectedValue);
            if (selectedCustomer) {
                setSelectedCustomerData({
                    id: selectedCustomer.id,
                    fullname: selectedCustomer.firstname,
                    email: selectedCustomer.email,
                    address: selectedCustomer.address,
                });
            }
        }
    };

    const handleAddRow = () => {
        const newRow = {
            id: rows.length + 1,
            product: '',
            quantity: '',
            rate: '',
            price: '',
            serviceDate: '',
            tax: ''
        };
        setRows([...rows, newRow]);
    };

    const handleDeleteRow = (index) => {
        const newRows = rows.filter((row, i) => i !== index);
        setRows(newRows);
    };

    const handleInputChange = (index, field, value) => {
        const newRows = rows.map((row, i) => {
            if (i === index) {
                const updatedRow = { ...row, [field]: value };
                if (field === 'product') {
                    updatedRow.rate = productPrices[value] || '';
                    updatedRow.price = (updatedRow.rate || 0) * (row.quantity || 0);
                }
                if (field === 'quantity' || field === 'rate') {
                    updatedRow.price = (updatedRow.rate || 0) * (updatedRow.quantity || 0);
                }
                return updatedRow;
            }
            return row;
        });

        setRows(newRows);

        if (field === 'product' && value !== '' && index === rows.length - 1) {
            handleAddRow();
        }
    };

    useEffect(() => {
        const invoiceDateObj = new Date(invoicedate);
        let daysToAdd = 0;

        switch (term) {
            case 'net 15':
                daysToAdd = 15;
                break;
            case 'net 30':
                daysToAdd = 30;
                break;
            case 'net 60':
                daysToAdd = 60;
                break;
            default:
                daysToAdd = 0;
        }

        invoiceDateObj.setDate(invoiceDateObj.getDate() + daysToAdd);
        setDueDate(invoiceDateObj.toISOString().split('T')[0]);
    }, [term, invoicedate]);

    const handleSubmit = async () => {
        setLoading(true);

        try {
            const data = {
                fullname: selectedOption === 'Direct Customer' ? fullname : selectedCustomerData.fullname,
                email: selectedOption === 'Direct Customer' ? email : selectedCustomerData.email,
                customer: customer,
                address: selectedOption === 'Direct Customer' ? address : selectedCustomerData.address,
                term: term,
                invoicedate: invoicedate,
                duedate: duedate,
                type: type,
                description: description,
                discount: discount,
                payamount: payamount,
                items: rows.filter(row => row.product && row.quantity).map(row => ({
                    product: row.product,
                    quantity: row.quantity,
                    rate: row.rate,
                    price: row.price,
                    servicedate: row.servicedate,
                    tax: row.tax
                }))
            };

            const response = await axios.post('https://admin.bizstor.lk/api/save-data', data);
            console.log('Data saved successfully:', response.data);

            setFullname('');
            setEmail('');
            setType('');
            setPayamount('');
            setCustomer('');
            setBillingAddress('');
            setTerm('due on receipt');
            setInvoiceDate(new Date().toISOString().split('T')[0]);
            setDueDate(new Date().toISOString().split('T')[0]);
            setDescription('');
            setDiscount(0);
            setRows([{ id: 1, product: '', quantity: '', rate: '', price: '', servicedate: '', tax: '' }]);

            setAlertOpen(true);
        } catch (error) {
            console.error('Error saving data:', error);
        } finally {
            setLoading(false);
        }
    };

    const calculateTotalAmount = () => {
        const total = rows.reduce((sum, row) => sum + parseFloat(row.price || 0), 0);
        const discountAmount = (total * discount) / 100;
        const taxAmount = rows.reduce((sum, row) => sum + parseFloat(row.tax || 0), 0);
        return total - discountAmount + taxAmount;
    };

    const calculateBalancedue = () => {
        const total = rows.reduce((sum, row) => sum + parseFloat(row.price || 0), 0);
        const discountAmount = (total * discount) / 100;
        const taxAmount = rows.reduce((sum, row) => sum + parseFloat(row.tax || 0), 0);

        let balanceDue = total - discountAmount + taxAmount;

        if (term === 'due on receipt') {
            balanceDue = total - discountAmount + taxAmount;
        } else {
            balanceDue = total - discountAmount + taxAmount - parseFloat(payamount || 0);
        }

        return balanceDue;
    };

    const calculateDiscount = () => {
        const total = rows.reduce((sum, row) => sum + parseFloat(row.price || 0), 0);
        const discountAmount = (total * discount) / 100;
        return discountAmount;
    };

    const handleDirectCustomerChange = (field, value) => {
        if (field === 'fullname') {
            setFullname(value);
        } else if (field === 'email') {
            setEmail(value);
        } else if (field === 'address') {
            setBillingAddress(value);
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                maxWidth: '1200px',
                margin: '0 auto',
                boxShadow: 1,
                padding: '20px',
                borderRadius: '5px',
                bgcolor: '#f9f9f9',
                marginTop: '20px',
                position: 'relative',
            }}
        >
            <Typography variant="h4" sx={{ mb: 2 }}>
                Invoice Form
            </Typography>
            <FormControl sx={{ mb: 2 }}>
                <InputLabel>Customer</InputLabel>
                <Select
                    value={selectedOption}
                    onChange={handleOptionChange}
                >
                    <MenuItem value="Direct Customer">Direct Customer</MenuItem>
                    <ListSubheader>Existing Customers</ListSubheader>
                    {customers.map((customer) => (
                        <MenuItem key={customer.id} value={customer.id}>
                            {customer.firstname} - {customer.email} - {customer.address}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <TextField
                label="Full Name"
                variant="outlined"
                value={selectedOption === 'Direct Customer' ? fullname : selectedCustomerData.fullname}
                onChange={(e) => handleDirectCustomerChange('fullname', e.target.value)}
                disabled={selectedOption !== 'Direct Customer'}
                sx={{ mb: 2 }}
            />
            <TextField
                label="Email"
                variant="outlined"
                value={selectedOption === 'Direct Customer' ? email : selectedCustomerData.email}
                onChange={(e) => handleDirectCustomerChange('email', e.target.value)}
                disabled={selectedOption !== 'Direct Customer'}
                sx={{ mb: 2 }}
            />
            <TextField
                label="Billing Address"
                variant="outlined"
                value={selectedOption === 'Direct Customer' ? address : selectedCustomerData.address}
                onChange={(e) => handleDirectCustomerChange('address', e.target.value)}
                disabled={selectedOption !== 'Direct Customer'}
                sx={{ mb: 2 }}
            />
<TextField
    label="Customer type"
    variant="outlined"
    value={selectedOption === 'Direct Customer' ? 'Direct Customer' : 'existing customer'}
    onChange={(e) => setCustomer(e.target.value)}
    disabled
    sx={{ mb: 2 }}
/>
            <TextField
                label="Description"
                variant="outlined"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                sx={{ mb: 2 }}
            />
            <TextField
                label="Invoice Date"
                variant="outlined"
                type="date"
                value={invoicedate}
                onChange={(e) => setInvoiceDate(e.target.value)}
                sx={{ mb: 2 }}
                InputLabelProps={{
                    shrink: true,
                }}
            />
            <TextField
                label="Due Date"
                variant="outlined"
                type="date"
                value={duedate}
                onChange={(e) => setDueDate(e.target.value)}
                sx={{ mb: 2 }}
                InputLabelProps={{
                    shrink: true,
                }}
            />
            <FormControl sx={{ mb: 2 }}>
                <InputLabel>Term</InputLabel>
                <Select
                    value={term}
                    onChange={(e) => setTerm(e.target.value)}
                >
                    <MenuItem value="due on receipt">Due on Receipt</MenuItem>
                    <MenuItem value="net 15">Net 15</MenuItem>
                    <MenuItem value="net 30">Net 30</MenuItem>
                    <MenuItem value="net 60">Net 60</MenuItem>
                </Select>
            </FormControl>
            <TextField
                label="Type"
                variant="outlined"
                value={type}
                onChange={(e) => setType(e.target.value)}
                sx={{ mb: 2 }}
            />
            <TextField
                label="Discount (%)"
                variant="outlined"
                type="number"
                value={discount}
                onChange={(e) => setDiscount(parseFloat(e.target.value))}
                sx={{ mb: 2 }}
            />
            <TextField
                label="Payment Amount"
                variant="outlined"
                type="number"
                value={payamount}
                onChange={(e) => setPayamount(e.target.value)}
                sx={{ mb: 2 }}
            />
            <TableContainer component={Paper} sx={{ mb: 2 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Product</TableCell>
                            <TableCell>Quantity</TableCell>
                            <TableCell>Rate</TableCell>
                            <TableCell>Price</TableCell>
                            <TableCell>Service Date</TableCell>
                            <TableCell>Tax</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => (
                            <TableRow key={row.id}>
                                <TableCell>
                                    <FormControl fullWidth>
                                        <Select
                                            value={row.product}
                                            onChange={(e) => handleInputChange(index, 'product', e.target.value)}
                                        >
                                            {Object.keys(productPrices).map((product) => (
                                                <MenuItem key={product} value={product}>
                                                    {product}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        type="number"
                                        value={row.quantity}
                                        onChange={(e) => handleInputChange(index, 'quantity', e.target.value)}
                                        sx={{ width: '100px' }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        type="number"
                                        value={row.rate}
                                        onChange={(e) => handleInputChange(index, 'rate', e.target.value)}
                                        disabled
                                        sx={{ width: '100px' }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        type="number"
                                        value={row.price}
                                        onChange={(e) => handleInputChange(index, 'price', e.target.value)}
                                        disabled
                                        sx={{ width: '100px' }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        type="date"
                                        value={row.servicedate}
                                        onChange={(e) => handleInputChange(index, 'servicedate', e.target.value)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        type="number"
                                        value={row.tax}
                                        onChange={(e) => handleInputChange(index, 'tax', e.target.value)}
                                    />
                                </TableCell>
                                <TableCell>
                                    <IconButton onClick={() => handleDeleteRow(index)} color="secondary">
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Typography variant="h6">
                Total Amount: Rs. {calculateTotalAmount().toFixed(2)}
            </Typography>
            <Typography variant="h6">
                Discount Amount: Rs. {calculateDiscount().toFixed(2)}
            </Typography>
            <Typography variant="h6">
                Balance Due: Rs. {calculateBalancedue().toFixed(2)}
            </Typography>
            <Button variant="contained" color="primary" onClick={handleSubmit} disabled={loading}>
                {loading ? 'Saving...' : 'Submit'}
            </Button>
        </Box>
    );
};

export default FormWithTable;
