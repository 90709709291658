import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; 
import axios from 'axios';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import HomeIcon from '@mui/icons-material/Home'; 
import ReceiptIcon from '@mui/icons-material/Receipt'; 
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn'; 
import InventoryIcon from '@mui/icons-material/Inventory'; 
import MoneyOffIcon from '@mui/icons-material/MoneyOff'; 
import SummarizeIcon from '@mui/icons-material/Summarize'; 
import PersonAddIcon from '@mui/icons-material/PersonAdd'; 
import PeopleIcon from '@mui/icons-material/People'; 
import CategoryIcon from '@mui/icons-material/Category'; 
import PersonIcon from '@mui/icons-material/Person'; 
import ExpandLess from '@mui/icons-material/ExpandLess'; 
import BarChartIcon from '@mui/icons-material/BarChart';
import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import ExpandMore from '@mui/icons-material/ExpandMore'; 
import { styled, keyframes } from '@mui/system'; 
import { Typography } from '@mui/material';
import logo from '../AppbarAndDrawer/bizstorlogo.png';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CreditScoreIcon from '@mui/icons-material/CreditScore';

const slideInFromLeft = keyframes`
  0% {
    transform: translateX(-20px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const fadeInAndMove = keyframes`
    from {
        opacity: 0;
        transform: translateY(-20px); // Start from above
    }
    to {
        opacity: 1;
        transform: translateY(0); // End at the original position
    }
`;

// Styled component to hide scrollbar
const CustomDrawerContent = styled(Box)(({ theme }) => ({
    width: 250,
    height: '100%',
    backgroundColor: 'black',
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    color: 'white',
    overflowY: 'auto',
    overflowX: 'hidden', // Hide horizontal scrollbar
    '&::-webkit-scrollbar': {
        width: '0px', // Hide scrollbar for WebKit browsers
    },
    scrollbarWidth: 'none', // Hide scrollbar for Firefox
    msOverflowStyle: 'none', // Hide scrollbar for IE and Edge
}));

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
    width: '225px', // Make sure the button occupies the full width
    borderRadius: '8px', // Add border-radius for rounded corners
    position: 'relative', // Required for the pseudo-element
    overflow: 'hidden', // Ensures the hover effect stays within the button

    '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '0%',
        height: '100%',
        backgroundColor: 'rgba(33, 33, 33)', // Hover color
        transition: 'width 0.4s ease', // Smooth transition for the effect
        zIndex: 0, // Make sure it is behind the content
    },

    '&:hover::before': {
        width: '100%', // Expands the hover effect from left to right
    },

    '&:hover': {
        backgroundColor: 'transparent', // Prevent default background change
    },

    '& .MuiListItemText-root, & .MuiListItemIcon-root': {
        position: 'relative',
        zIndex: 1, // Make sure the text and icon stay on top of the hover effect
    },

    animation: `${slideInFromLeft} 0.7s ease-out`, // Apply animation
}));



const StyledListItemText = styled(ListItemText)(({ theme }) => ({
    '& .MuiTypography-root': {
        fontSize: '0.900rem', // Small text
        animation: `${slideInFromLeft} 0.7s ease-out`, // Apply animation
    },
}));

const AnimatedTypography = styled(Typography)(({ theme }) => ({
    animation: `${fadeInAndMove} 0.5s ease-out`, // Apply animation
    color: 'orange', // Set text color
}));

const AnimatedImage = styled('img')(({ theme }) => ({
    width: '15%',
    height: '15%',
    borderRadius: '10%',
    animation: `${fadeInAndMove} 0.5s ease-out`, // Apply the animation
    transition: 'transform 0.3s ease', // Smooth transition for transformations
    '&:hover': {
        transform: 'scale(1.1)', // Scale image on hover
    },
}));

export default function TemporaryDrawer() {
    const [user, setUser] = useState({});
    const [token, setToken] = useState(() => localStorage.getItem('token') || null);
    const [open, setOpen] = React.useState(false);
    const [addOpen, setAddOpen] = React.useState(false); // Define addOpen state

    useEffect(() => {
        if (token) {
            localStorage.setItem('token', token);
        }
    }, [token]);

    useEffect(() => {
        const fetchProfile = async () => {
            if (!token) return; 

            try {
                const response = await axios.get('https://admin.bizstor.lk/api/profile', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setUser(response.data);
            } catch (error) {
                console.log('');
            }
        };

        fetchProfile();
    }, [token]);


    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    const handleAddClick = (e) => {
        e.stopPropagation(); // Prevent the click event from closing the drawer
        setAddOpen((prev) => !prev); // Toggle addOpen state
    };

    const DrawerList = (
        <CustomDrawerContent role="presentation" onClick={toggleDrawer(false)}>
            <Box
            sx={{
                display: 'flex',
                justifyContent: 'center', // Center horizontally
                alignItems: 'center', // Center vertically if needed
            }}
        >
            <AnimatedImage
                src={logo}
                alt="Logo"
            />
            <AnimatedTypography
                variant="h6"
                noWrap
                component="div"
                sx={{
                    display: { xs: 'none', sm: 'block' },
                    ml: 1
                }}
            >
               {/** BIZSTOR*/} 
            </AnimatedTypography>
        </Box>
            <List
                sx={{
                    marginTop: 2
                }}
            >
            
            <ListItem key="home" disablePadding>
            <Link to="/dashboard" style={{ textDecoration: 'none', color: 'inherit' }}>
                <StyledListItemButton
                    onClick={toggleDrawer(false)}
                >
                    <ListItemIcon sx={{ color: 'white' }}>
                        <HomeIcon />
                    </ListItemIcon>
                    <StyledListItemText primary="Dashboard" />
                </StyledListItemButton>
            </Link>
        </ListItem>
        {user.email !== 'bizstoradmin@gmail.com' && (
                <ListItem key="invoice" disablePadding>
                    <Link to="/invoice" style={{ textDecoration: 'none', color: 'inherit' }}>
                        <StyledListItemButton onClick={toggleDrawer(false)}>
                            <ListItemIcon sx={{ color: 'white' }}>
                                <ReceiptIcon />
                            </ListItemIcon>
                            <StyledListItemText primary="Invoice" />
                        </StyledListItemButton>
                    </Link>
                </ListItem>)}
                {user.email !== 'bizstoradmin@gmail.com' && (
                <ListItem key="return" disablePadding>
                    <Link to="/return" style={{ textDecoration: 'none', color: 'inherit' }}>
                        <StyledListItemButton onClick={toggleDrawer(false)}>
                            <ListItemIcon sx={{ color: 'white' }}>
                                <KeyboardReturnIcon />
                            </ListItemIcon>
                            <StyledListItemText primary="Return" />
                        </StyledListItemButton>
                    </Link>
                </ListItem>)}
                {user.email !== 'bizstoradmin@gmail.com' && (
                <ListItem key="inventory" disablePadding>
                    <Link to="/inventory" style={{ textDecoration: 'none', color: 'inherit' }}>
                        <StyledListItemButton onClick={toggleDrawer(false)}>
                            <ListItemIcon sx={{ color: 'white' }}>
                                <InventoryIcon />
                            </ListItemIcon>
                            <StyledListItemText primary="Inventory" />
                        </StyledListItemButton>
                    </Link>
                </ListItem>)}
                {user.email !== 'bizstoradmin@gmail.com' && (
                <ListItem key="expenses" disablePadding>
                    <Link to="/expenses" style={{ textDecoration: 'none', color: 'inherit' }}>
                        <StyledListItemButton onClick={toggleDrawer(false)}>
                            <ListItemIcon sx={{ color: 'white' }}>
                                <MoneyOffIcon />
                            </ListItemIcon>
                            <StyledListItemText primary="Expenses" />
                        </StyledListItemButton>
                    </Link>
                </ListItem>)}
                {user.email !== 'bizstoradmin@gmail.com' && (
                <ListItem key="Investment" disablePadding>
                    <Link to="/investment" style={{ textDecoration: 'none', color: 'inherit' }}>
                        <StyledListItemButton onClick={toggleDrawer(false)}>
                            <ListItemIcon sx={{ color: 'white' }}>
                                <AccountBalanceIcon />
                            </ListItemIcon>
                            <StyledListItemText primary="Investment" />
                        </StyledListItemButton>
                    </Link>
                </ListItem>)}
                {user.email !== 'bizstoradmin@gmail.com' && (
                <ListItem key="report" disablePadding>
                    <Link to="/allreport" style={{ textDecoration: 'none', color: 'inherit' }}>
                        <StyledListItemButton onClick={toggleDrawer(false)}>
                            <ListItemIcon sx={{ color: 'white' }}>
                                <SummarizeIcon />
                            </ListItemIcon>
                            <StyledListItemText primary="Report" />
                        </StyledListItemButton>
                    </Link>
                </ListItem>)}
                {user.email !== 'bizstoradmin@gmail.com' && (
                <ListItem key="inventory-details" disablePadding>
                    <Link to="/inventory-details" style={{ textDecoration: 'none', color: 'inherit' }}>
                        <StyledListItemButton onClick={toggleDrawer(false)}>
                            <ListItemIcon sx={{ color: 'white' }}>
                                <InventoryIcon />
                            </ListItemIcon>
                            <StyledListItemText primary="Inventory Details" />
                        </StyledListItemButton>
                    </Link>
                </ListItem>)}
                {user.email !== 'bizstoradmin@gmail.com' && (
                <ListItem key="chart" disablePadding>
                <Link to="/chart" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <StyledListItemButton onClick={toggleDrawer(false)}>
                        <ListItemIcon sx={{ color: 'white' }}>
                            <BarChartIcon />
                        </ListItemIcon>
                        <StyledListItemText primary="Chart" />
                    </StyledListItemButton>
                    </Link>
                </ListItem>)}
                {user.email !== 'bizstoradmin@gmail.com' && (
                <ListItem key="customer" disablePadding>
                <Link to="/addcustomer" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <StyledListItemButton onClick={toggleDrawer(false)}>
                        <ListItemIcon sx={{ color: 'white' }}>
                            <PersonAddIcon />
                        </ListItemIcon>
                        <StyledListItemText primary="Add Customer" />
                    </StyledListItemButton>
                    </Link>
                </ListItem>)}
                {user.email !== 'bizstoradmin@gmail.com' && (
                <ListItem key="supplier" disablePadding>
                <Link to="/addsupplier" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <StyledListItemButton onClick={toggleDrawer(false)}>
                        <ListItemIcon sx={{ color: 'white' }}>
                            <PeopleIcon />
                        </ListItemIcon>
                        <StyledListItemText primary="Add Supplier" />
                    </StyledListItemButton>
                    </Link>
                </ListItem>)}
                {user.email !== 'bizstoradmin@gmail.com' && (
                <ListItem key="product" disablePadding>
                <Link to="/addproduct" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <StyledListItemButton onClick={toggleDrawer(false)}>
                        <ListItemIcon sx={{ color: 'white' }}>
                            <CategoryIcon />
                        </ListItemIcon>
                        <StyledListItemText primary="Add Product" />
                    </StyledListItemButton>
                    </Link>
                </ListItem>)}
                {user.email !== 'bizstoradmin@gmail.com' && (
                <ListItem key="expenses" disablePadding>
                <Link to="/addexpenses" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <StyledListItemButton onClick={toggleDrawer(false)}>
                        <ListItemIcon sx={{ color: 'white' }}>
                            <MoneyOffIcon />
                        </ListItemIcon>
                        <StyledListItemText primary="Add Expenses" />
                    </StyledListItemButton>
                    </Link>
                </ListItem>)}
            {/**<ListItem key="add" disablePadding>
                    <StyledListItemButton onClick={handleAddClick}>
                        <ListItemIcon sx={{ color: 'white' }}>
                            <AddIcon />
                        </ListItemIcon>
                        <StyledListItemText primary="Add" />
                        {addOpen ? <ExpandLess sx={{ color: 'white', mr: 3 }} /> : <ExpandMore sx={{ color: 'white', mr: 3 }} />}
                    </StyledListItemButton>
                </ListItem>
                <Collapse in={addOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem key="customer" disablePadding>
                            <Link to="/addcustomer" style={{ textDecoration: 'none', color: 'inherit' }}>
                                <StyledListItemButton sx={{ pl: 4 }} onClick={(e) => e.stopPropagation()}>
                                    <ListItemIcon sx={{ color: 'white' }}>
                                        <PersonAddIcon />
                                    </ListItemIcon>
                                    <StyledListItemText primary="Customer" />
                                </StyledListItemButton>
                            </Link>
                        </ListItem>
                        <ListItem key="supplier" disablePadding>
                            <Link to="/addsupplier" style={{ textDecoration: 'none', color: 'inherit' }}>
                                <StyledListItemButton sx={{ pl: 4 }} onClick={(e) => e.stopPropagation()}>
                                    <ListItemIcon sx={{ color: 'white' }}>
                                        <PeopleIcon />
                                    </ListItemIcon>
                                    <StyledListItemText primary="Supplier" />
                                </StyledListItemButton>
                            </Link>
                        </ListItem>
                        <ListItem key="product" disablePadding>
                            <Link to="/addproduct" style={{ textDecoration: 'none', color: 'inherit' }}>
                                <StyledListItemButton sx={{ pl: 4 }} onClick={(e) => e.stopPropagation()}>
                                    <ListItemIcon sx={{ color: 'white' }}>
                                        <CategoryIcon />
                                    </ListItemIcon>
                                    <StyledListItemText primary="Product" />
                                </StyledListItemButton>
                            </Link>
                        </ListItem>
                        <ListItem key="expenses" disablePadding>
                            <Link to="/addexpenses" style={{ textDecoration: 'none', color: 'inherit' }}>
                                <StyledListItemButton sx={{ pl: 4 }} onClick={(e) => e.stopPropagation()}>
                                    <ListItemIcon sx={{ color: 'white' }}>
                                        <MoneyOffIcon />
                                    </ListItemIcon>
                                    <StyledListItemText primary="Expenses" />
                                </StyledListItemButton>
                            </Link>
                        </ListItem>
                    </List>
                </Collapse> */}   
                {user.email !== 'bizstoradmin@gmail.com' && ( 
                <ListItem key="profile" disablePadding>
                <Link to="/profile" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <StyledListItemButton onClick={toggleDrawer(false)}>
                        <ListItemIcon sx={{ color: 'white' }}>
                            <PersonIcon />
                        </ListItemIcon>
                        <StyledListItemText primary="Profile" />
                    </StyledListItemButton>
                </Link>
                </ListItem>)}
                
                {user.email === 'bizstoradmin@gmail.com' && (
                    <ListItem key="user-managemnt" disablePadding>
                        <Link to="/user-management" style={{ textDecoration: 'none', color: 'inherit' }}>
                            <StyledListItemButton onClick={toggleDrawer(false)}>
                                <ListItemIcon sx={{ color: 'white' }}>
                                    <PersonIcon />
                                </ListItemIcon>
                                <StyledListItemText primary="User Management" />
                            </StyledListItemButton>
                        </Link>
                    </ListItem>
                )}
                {user.email === 'bizstoradmin@gmail.com' && (
                    <ListItem key="Package" disablePadding>
                        <Link to="/payments" style={{ textDecoration: 'none', color: 'inherit' }}>
                            <StyledListItemButton onClick={toggleDrawer(false)}>
                                <ListItemIcon sx={{ color: 'white' }}>
                                    <CreditScoreIcon />
                                </ListItemIcon>
                                <StyledListItemText primary="Package" />
                            </StyledListItemButton>
                        </Link>
                    </ListItem>
                )}
                {user.email === 'bizstoradmin@gmail.com' && (
                    <ListItem key="reports" disablePadding>
                        <Link to="/payment-reports" style={{ textDecoration: 'none', color: 'inherit' }}>
                            <StyledListItemButton onClick={toggleDrawer(false)}>
                                <ListItemIcon sx={{ color: 'white' }}>
                                    <SummarizeIcon />
                                </ListItemIcon>
                                <StyledListItemText primary="Payment Reports" />
                            </StyledListItemButton>
                        </Link>
                    </ListItem>
                )}
            </List>
        </CustomDrawerContent>
    );

    return (
        <div>
      {/* IconButton for opening the drawer */}
      <IconButton onClick={toggleDrawer(true)} color="inherit">
      <ViewHeadlineIcon sx={{ color: 'orange' }} />
      </IconButton>

      {/* Drawer component */}
      <Drawer open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </div>
    );
}
