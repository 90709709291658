export function printContent(
  groupedOrders, totalPriceAmount, totalDiscountAmount, totalTaxAmount, grandTotal,
  groupedDamages, damageTotalQuantity, damageTotalPrice,
  groupedExpenses, expensesTotalAmount,
  groupedStocks, stockTotalFilled, stockTotalEmpty, stockTotalItem,
  user, selectedMonthName
) {
  const profit = grandTotal - expensesTotalAmount - damageTotalPrice;
  const printWindow = window.open('', '', 'width=800,height=600');

  // Write the content to the new window
  printWindow.document.write('<html><head><title>Summary</title>');
  printWindow.document.write('<style>');
  printWindow.document.write('body { font-family: Arial, sans-serif; line-height: 1.6; margin: 20px; }');
  printWindow.document.write('h2 { text-align: center; }');
  printWindow.document.write('h4 { text-align: center; }');
  printWindow.document.write('h5 { border-bottom: 2px solid #black; padding-bottom: 5px; text-align: center; }');
  printWindow.document.write('table { width: 100%; border-collapse: collapse; margin-bottom: 20px; }');
  printWindow.document.write('th, td { padding: 12px; text-align: left; }'); // Removed border styling
  printWindow.document.write('th { color: #333; font-weight: bold; }'); // Removed background color
  printWindow.document.write('tr:nth-child(even) { }'); // Removed background color for even rows
  printWindow.document.write('div { margin-bottom: 20px; }');
  printWindow.document.write('p { font-size: 16px; margin: 5px 0; }');
  printWindow.document.write('hr { border: none; height: 1px; background-color: #333; margin: 20px 0; }');
  printWindow.document.write('</style>');
  printWindow.document.write('</head><body>');

  // Corrected interpolation for the company name
  printWindow.document.write(`<h2>${user.companyname}</h2>`);
  printWindow.document.write('<h4>Summary</h4>');
  printWindow.document.write(`<h5 style="border-bottom:2px solid black">${selectedMonthName}</h5>`);
  printWindow.document.write('<h3>Sales</h3>');
  printWindow.document.write('<table>');
  printWindow.document.write('<thead><tr><th>Product</th><th>Rate</th><th>Qty</th><th>Price</th><th>Discount</th><th>Tax</th><th style="text-align: right;">TOTAL</th></tr></thead><tbody>');

  Object.values(groupedOrders).forEach(({ product, rate, quantity, totalPrice, totalDiscount, totalTax, total }) => {
    printWindow.document.write(`<tr><td>${product}</td><td>${rate}</td><td>${quantity}</td><td>${totalPrice.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td><td>${totalDiscount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td><td>${totalTax.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td><td style="text-align: right;">${total.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td></tr>`);
  });
  printWindow.document.write('</tbody></table>');

  printWindow.document.write('<div style="text-align: right; border-bottom:1px solid black;border-top:1px solid black">');
  printWindow.document.write(`<p style="display:flex; justify-content:space-between; font-weight:bold;"><span>Total</span><span>Lkr.${totalPriceAmount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></p>`);
  printWindow.document.write(`<p style="display:flex; justify-content:space-between; font-weight:bold;"><span>Total Discount</span><span>Lkr.${totalDiscountAmount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></p>`);
  printWindow.document.write(`<p style="display:flex; justify-content:space-between; font-weight:bold;"><span>Total Tax</span><span>Lkr.${totalTaxAmount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></p>`);
  printWindow.document.write(`<p style="display:flex; justify-content:space-between; font-weight:bold;"><span>Grand Total</span><span>Lkr.${grandTotal.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></p>`);

  //printWindow.document.write(`<p><strong>Total :</strong> Lkr. ${totalPriceAmount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>`);
  //printWindow.document.write(`<p><strong>Total Discount :</strong> Lkr. ${totalDiscountAmount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>`);
  //printWindow.document.write(`<p><strong>Total Tax :</strong> Lkr. ${totalTaxAmount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>`);
  //printWindow.document.write(`<p><strong>Grand Total:</strong> Lkr. ${grandTotal.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p><hr>`);
  printWindow.document.write('</div>');

  printWindow.document.write('<h3>Returns</h3>');
  printWindow.document.write('<table>');
  printWindow.document.write('<thead><tr><th>Product</th><th>Rate</th><th>Qty</th><th>Type</th><th style="text-align: right;">TOTAL</th></tr></thead><tbody>');

  Object.values(groupedDamages).forEach(({ product, rate, quantity, totalPrice, type }) => {
    printWindow.document.write(`<tr><td>${product}</td><td>${rate}</td><td>${quantity}</td><td>${type}</td><td style="text-align: right;">${totalPrice.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td></tr>`);
  });
  printWindow.document.write('</tbody></table>');

  printWindow.document.write('<div style="text-align: right; border-bottom:1px solid black;border-top:1px solid black">');
  printWindow.document.write(`<p style="display:flex; justify-content:space-between; font-weight:bold;"><span>Return Total Qty</span><span>${damageTotalQuantity}</span></p>`);
  printWindow.document.write(`<p style="display:flex; justify-content:space-between; font-weight:bold;"><span>Return Total Price</span><span>Lkr.${damageTotalPrice.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></p>`);

  //printWindow.document.write(`<p><strong>Return Total Qty:</strong> ${damageTotalQuantity}</p>`);
  //printWindow.document.write(`<p><strong>Return Total Price:</strong> Lkr. ${damageTotalPrice.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p><hr>`);
  printWindow.document.write('</div>');

  printWindow.document.write('<h3>Expenses</h3>');
  printWindow.document.write('<table>');
  printWindow.document.write('<thead><tr><th>Category</th><th style="text-align: right;">TOTAL</th></tr></thead><tbody>');

  Object.values(groupedExpenses).forEach(({ category, totalAmount }) => {
    printWindow.document.write(`<tr><td>${category}</td><td style="text-align: right;">${totalAmount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td></tr>`);
  });
  printWindow.document.write('</tbody></table>');

  printWindow.document.write('<div style="text-align: right; border-bottom:1px solid black;border-top:1px solid black">');
  printWindow.document.write(`<p style="display:flex; justify-content:space-between; font-weight:bold;"><span>Total Expense</span><span>Lkr.${expensesTotalAmount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></p>`);

  //printWindow.document.write(`<p><strong>Total Expense :</strong> Lkr. ${expensesTotalAmount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p><hr>`);
  printWindow.document.write('</div>');

  printWindow.document.write('<h3>Stock</h3>');
  printWindow.document.write('<table>');
  printWindow.document.write('<thead><tr><th>Product</th><th>Status</th><th style="text-align: right;">QTY</th></tr></thead><tbody>');

  Object.values(groupedStocks).forEach(({ product, quantity, status }) => {
    printWindow.document.write(`<tr><td>${product}</td><td>${status}</td><td style="text-align: right;">${quantity}</td></tr>`);
  });
  printWindow.document.write('</tbody></table>');

  printWindow.document.write('<div style="text-align: right; border-bottom:1px solid black;border-top:1px solid black">');
  printWindow.document.write(`<p style="display:flex; justify-content:space-between; font-weight:bold;"><span>Total Item Qty</span><span>${stockTotalItem}</span></p>`);
  printWindow.document.write(`<p style="display:flex; justify-content:space-between; font-weight:bold;"><span>Total Filled Qty</span><span>${stockTotalFilled}</span></p>`);
  printWindow.document.write(`<p style="display:flex; justify-content:space-between; font-weight:bold;"><span>Total Empty Qty</span><span>${stockTotalEmpty}</span></p>`);

  //printWindow.document.write(`<p><strong>Total Item Qty:</strong> ${stockTotalItem}</p>`);
  //printWindow.document.write(`<p><strong>Total Filled Qty:</strong> ${stockTotalFilled}</p>`);
  //printWindow.document.write(`<p><strong>Total Empty Qty:</strong> ${stockTotalEmpty}</p>`);
  printWindow.document.write('</div>');

  printWindow.document.write('</body></html>');

  printWindow.document.close();
  printWindow.focus();
  printWindow.print();
}
