import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import {
  TextField,
  Button,
  Typography,
  Container,
  Box,
  Snackbar,
  Alert,
} from '@mui/material';
import { styled, keyframes } from '@mui/system';

// Animation for sliding in from the left
const slideInFromLeft = keyframes`
  0% {
    transform: translateX(-20px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

// Styled Typography with animation
const AnimatedTypography = styled(Typography)(({ theme }) => ({
  animation: `${slideInFromLeft} 0.5s ease-out`,
}));

const ResetPassword = () => {
  const { id, email: emailParam } = useParams(); // Get the id and email from the URL params
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [key, setKey] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false); // State for Snackbar visibility
  const [snackbarMessage, setSnackbarMessage] = useState(''); // State for Snackbar message
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // State for Snackbar severity
  const [loading, setLoading] = useState(false);

  const handleSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  useEffect(() => {
    if (emailParam) {
      setEmail(emailParam); // Auto-fill the email field
    }
    setKey(prevKey => prevKey + 1); // Update key to force re-render for animation
  }, [emailParam]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(''); // Clear any previous errors
    setLoading(true); // Start loading before the request

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      setLoading(false); // Stop loading if passwords do not match
      return;
    }

    try {
      await axios.post('https://admin.bizstor.lk/api/reset-password', {
        id,
        email,
        password,
        password_confirmation: confirmPassword,
      });

      setSuccess(true);
      handleSnackbar('Password reset successfully!', 'success'); // Show success message
      setLoading(false); // Stop loading after success

      setTimeout(() => {
        navigate('/');
      }, 3000);
    } catch (err) {
      setError(err.response?.data?.message || 'An error occurred'); // Display error message
      handleSnackbar('Password reset failed!', 'error'); // Show error message
      setLoading(false); // Stop loading after error
    }
  };


  return (
    <Container maxWidth="xs">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',  // Center vertically
          height: '100vh',  // Full viewport height
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            p: 4,
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
            borderRadius: 2,
            bgcolor: 'background.paper',
          }}
        >
        <AnimatedTypography key={key} component="h1" variant="h5">
          Reset Password
        </AnimatedTypography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            variant="standard"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            autoComplete="email"
          />
          <TextField
            variant="standard"
            margin="normal"
            required
            fullWidth
            id="password"
            label="New Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            autoComplete="new-password"
          />
          <TextField
            variant="standard"
            margin="normal"
            required
            fullWidth
            id="confirm-password"
            label="Confirm Password"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            autoComplete="new-password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              mt: 3,
              mb: 2,
              borderRadius: 20,
              background: 'gray',
              '&:hover': {
                backgroundColor: 'black',
              },
            }}
          >
            Reset Password
          </Button>

          {loading && (
            <Box sx={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100vw',
              height: '100vh',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              bgcolor: 'white',
              zIndex: 9999
            }}>
              <l-trio
                size="50"
                speed="1.3"
                color="red"
              ></l-trio>
            </Box>
          )}

          <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          >
            <Alert
              onClose={handleSnackbarClose}
              severity={snackbarSeverity}
              sx={{
                width: '100%',

              }}
            >
              {snackbarMessage}
            </Alert>
          </Snackbar>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default ResetPassword;
