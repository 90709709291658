import React from 'react';
import { Box, Tabs, Tab, Typography, Paper, useTheme, useMediaQuery } from '@mui/material';
import Stock from '../Stock';
import Items from '../Stock/Item';

// Create a functional component for each tab's content
const TabPanel = ({ value, index, children }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const AllReport = () => {
  const [tabValue, setTabValue] = React.useState(0);
  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm')); // Check if screen is small

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Paper square>
        <Box
          sx={{
            overflowX: isSmScreen ? 'auto' : 'hidden', // Scrollable on small screens, hidden on large screens
            display: 'flex',
            justifyContent: isSmScreen ? 'flex-start' : 'center', // Center tabs on large screens
          }}
        >
          <Tabs
            value={tabValue}
            onChange={handleChange}
            aria-label="report tabs"
            variant={isSmScreen ? 'scrollable' : 'standard'} // Scrollable on small screens
            scrollButtons={isSmScreen ? 'auto' : false} // Show scroll buttons on small screens
            allowScrollButtonsMobile={isSmScreen}
          >
            <Tab label="production" />
            <Tab label="item" />
          </Tabs>
        </Box>
      </Paper>
      <TabPanel value={tabValue} index={0}>
        <Stock />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <Items />
      </TabPanel>
    </Box>
  );
};

export default AllReport;
