import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow, 
    Paper,
    Box, 
    Typography, 
    TextField, 
    IconButton, 
    TablePagination
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { styled, keyframes } from '@mui/system';

const slideInFromLeft = keyframes`
  0% {
    transform: translateX(-20px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const AnimatedTypography = styled(Typography)(({ theme }) => ({
    animation: `${slideInFromLeft} 0.5s ease-out`,
}));

const CustomerReport = () => {
    const [user, setUser] = useState({});
    const [token, setToken] = useState(() => localStorage.getItem('token') || null);
    const [loading, setLoading] = useState(false);
    const [fetchedData, setFetchedData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const navigate = useNavigate();

    useEffect(() => {
        if (token) {
            localStorage.setItem('token', token);
        }
    }, [token]);

    useEffect(() => {
        const fetchProfile = async () => {
            if (!token) return; // Exit if no token

            try {
                const response = await axios.get('https://admin.bizstor.lk/api/profile', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setUser(response.data);
            } catch (error) {
                console.log('');
            }
        };

        fetchProfile();
    }, [token]);

    useEffect(() => {
        if (user.id) {
            fetchData();
        }
    }, [user.id]);

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axios.get('https://admin.bizstor.lk/api/get-data');
            if (user.id) {
                console.log('console.log("");:', user.id);

                // Filter orders based on user.id
                const Orders = response.data.filter(order =>
                    order.usertoken.toLowerCase() === user.id.toString().toLowerCase()
                );

                setFetchedData(Orders);
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const filterData = (query, data) => {
        if (!query) {
            return data;
        } else {
            return data.filter((d) =>
                d.fullname.toLowerCase().includes(query.toLowerCase())
            );
        }
    };

    // Remove duplicate fullnames
    const uniqueData = Array.from(new Set(fetchedData.map(order => order.fullname)))
        .map(fullname => fetchedData.find(order => order.fullname === fullname));

    const dataFiltered = filterData(searchQuery, uniqueData);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Box>
            <AnimatedTypography
                component="h1"
                sx={{
                    ml: 5,
                    mt: 3,
                    fontSize: '1rem',      // Adjust font size as needed
                    color: 'secondary.main', // Use theme color or specify a color like '#333'
                    letterSpacing: '0.01em' // Adjust letter spacing as needed
                }}
            >
                Dashboard / Report / Customer
            </AnimatedTypography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '95%',
                    maxWidth: 'auto',
                    margin: 'auto',
                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                    padding: '10px',
                    borderRadius: '5px',
                    bgcolor: 'background.paper',
                    marginTop: '30px',
                    position: 'relative'
                }}
            >
             <Typography variant="h4" >
             Customers
        </Typography>

                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        mb: 2, // Margin bottom
                        ml: 2
                    }}
                >
                    <TextField
                        variant="outlined"
                        margin="normal"
                        onChange={(e) => setSearchQuery(e.target.value)}
                        value={searchQuery}
                        size="small"
                        placeholder="Search…"
                        InputProps={{
                            startAdornment: (
                                <IconButton sx={{ padding: 0, mr: 1 }}>
                                    <SearchIcon />
                                </IconButton>
                            ),
                        }}
                        sx={{ width: '250px' }}
                    />
                </Box>

                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell><b>Customers</b></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dataFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((order) => (
                                <TableRow
                                    hover
                                    tabIndex={-1}
                                    key={order.id}
                                    onClick={() => navigate(`/orders/${order.fullname}`)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <TableCell>{order.fullname}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 15]}
                        component="div"
                        count={dataFiltered.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </TableContainer>
            </Box>
            {loading && (
                <Box sx={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: 'white',
                    zIndex: 9999
                }}>
                    <l-trio
                        size="50"
                        speed="1.3"
                        color="red"
                    ></l-trio>
                </Box>
            )}
        </Box>
    );
};

export default CustomerReport;
