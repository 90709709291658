import React, { useEffect, useState } from 'react';
import { Grid, Box, Typography, Paper,} from '@mui/material';
import {  AttachMoney, AccountBalance, MoneyOff,  } from '@mui/icons-material';
import Monthlyincome from '../Incomeandexpenses/Monthlyincome';
import Anualincome from '../Incomeandexpenses/Anualincome';
import Expenses from '../Incomeandexpenses/Expenses';
import Damages from '../Incomeandexpenses/Returns';
import Chart3 from '../Chart/Chart3';
import Stock from '../Home/Stock';
import Invoice from '../Popupdialogbox/Invoice';
import axios from 'axios';
import { styled, keyframes } from '@mui/system';
import Bank from '../Incomeandexpenses/BankBalance';
import Profit from '../Incomeandexpenses/Profit';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import PeopleIcons from '@mui/icons-material/PersonAdd'; 
import PeopleIcon from '@mui/icons-material/People';
import PersonIcon from '@mui/icons-material/Person';

const slideInFromLeft = keyframes`
  0% {
    transform: translateX(-20px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const AnimatedTypography = styled(Typography)(({ theme }) => ({
    animation: `${slideInFromLeft} 0.5s ease-out`,
}));

const Card = ({ title, value, icon: Icon, color }) => {
    return (
        <Paper
            elevation={3}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                p: 2,
                bgcolor: 'background.paper',
                boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                borderLeft: '3px solid orange',
                ml: 4,
                mr: 4,
                mt: 2, 
            }}
        >
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography variant="subtitle2" color={color} fontWeight="bold" textTransform="uppercase" mb={1}>
                    {title}
                </Typography>
                <Icon fontSize="large" style={{ color: 'gray', marginLeft: 8 }} />
            </Box>
            <Box mt={2}>
                <Typography variant="h5" fontWeight="bold" color="text.primary">
                    {value}
                </Typography>
            </Box>
        </Paper>
    );
};

const Home = ({ token, setToken }) => {
    const [user, setUser] = useState({});
    const [userPayments, setUserPayments] = useState([]); 
    const [totalAmount, setTotalAmount] = useState(0);
    const [monthlyTotal, setMonthlyTotal] = useState(0);
    const [annualTotal, setAnnualTotal] = useState(0);
    const [totalExpenses, setTotalExpenses] = useState(0);
    const [totalDamages, setTotalDamages] = useState(0);
    const [totalBanks, setTotalBanks] = useState(0);
    const [totalProfits, setTotalProfits] = useState(0);
    const [users, setUsers] = useState([]);
    const [userCount, setUserCount] = useState(0); 
    const [activeUserCount, setActiveUserCount] = useState(0);

    const handleMonthlyTotalChange = (amount) => {
        setMonthlyTotal(amount);
    };

    const handleAnnualTotalChange = (amount) => {
        setAnnualTotal(amount);
    };
    

    const handleTotalExpensesChange = (amount) => {
        setTotalExpenses(amount);
    };

    const handleTotalDamagesChange = (amount) => {
        setTotalDamages(amount);
    };


    const handleTotalBanksChange = (amount) => {
        setTotalBanks(amount);
    }

    const handleTotalProfitsChange = (amount) => {
        setTotalProfits(amount);
    }


    const handleTotalAmountChange = (data) => {
        console.log('Total amount data:', data);
    };

    useEffect(() => {
        const storedToken = token || localStorage.getItem('token');

        if (!storedToken) {
            alert('No token found, please log in.');
            return;
        }

        const fetchProfile = async () => {
            try {
                const response = await axios.get('https://admin.bizstor.lk/api/profile', {
                    headers: {
                        Authorization: `Bearer ${storedToken}`,
                    },
                });
                setUser(response.data);
            } catch (error) {
                console.log('');
            }
        };

        fetchProfile();
    }, [token]);

    const [greeting, setGreeting] = useState('');
    const [showHello, setShowHello] = useState(false);

    useEffect(() => {
        const currentHour = new Date().getHours();

        if (currentHour < 12) {
            setGreeting('Good Morning');
        } else if (currentHour < 18) {
            setGreeting('Good Afternoon');
        } else {
            setGreeting('Good Evening');
        }

        const greetingTimer = setTimeout(() => {
            setGreeting('');
            setShowHello(true);
        }, 10000);

        return () => clearTimeout(greetingTimer);
    }, []);

    const calculateProfit = (annualTotal, totalExpenses, totalDamages) => {
        const total = parseFloat(annualTotal.toFixed(2)) - parseFloat(totalExpenses.toFixed(2)) - parseFloat(totalDamages.toFixed(2));

        return `Lkr. ${total.toFixed(2)}`;
    };
    
    useEffect(() => {
        axios
          .get("https://admin.bizstor.lk/api/users")
          .then((response) => {
            setUsers(response.data); 
            setUserCount(response.data.length - 1); 
          })
          .catch((error) => {
            console.error("Error fetching users:", error);
          });
      }, []);

      useEffect(() => {
        axios
          .get("https://admin.bizstor.lk/api/users")
          .then((response) => {
            const allUsers = response.data;
            setUsers(allUsers); 
            const activeUsers = allUsers.filter(user => user.status === 'active'); 
            setActiveUserCount(activeUsers.length - 1); 
          })
          .catch((error) => {
            console.error("Error fetching users:", error);
          });
      }, []);

       useEffect(() => {
        axios
            .get("https://admin.bizstor.lk/api/get-user-payment")
            .then((response) => {
                if (Array.isArray(response.data.userpayment)) {
                    const payments = response.data.userpayment;
                    setUserPayments(payments); 

                    const totalAmount = payments.reduce((total, payment) => {
                        return total + Number(payment.amount); 
                    }, 0);

                    setTotalAmount(totalAmount); 
                } else {
                    console.error("Expected an array for userPayments");
                }
            })
            .catch((error) => {
                console.error("Error fetching user payments:", error);
            })
            .finally(() => {
            });
    }, []);

    return (
        <Box>
            {greeting && (
                <AnimatedTypography
                    component="h1"
                    variant="h5"
                    sx={{
                        ml: 5,
                        mt: 3,
                        fontSize: '2rem',
                        color: 'orange',
                        fontWeight: 'bold',
                        letterSpacing: '0.01em'
                    }}
                >
                    {greeting} {user.name} !
                </AnimatedTypography>
            )}

            {showHello && (
                <AnimatedTypography
                    component="h1"
                    sx={{
                        ml: 5,
                        mt: 3,
                        fontSize: '1rem',      
                        color: 'secondary.main', 
                        letterSpacing: '0.01em' 
                    }}
                >
                    Dashboard
                </AnimatedTypography>
            )}

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '95%',
                    maxWidth: 'auto',
                    margin: 'auto',
                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                    padding: '10px',
                    borderRadius: '5px',
                    bgcolor: 'background.paper',
                    marginTop: '30px',
                    position: 'relative'
                }}
            >
                <Grid container spacing={2} justifyContent="center">
                    <Monthlyincome onTotalAmountChange={handleMonthlyTotalChange} />
                    <Anualincome onTotalAmountChange={handleAnnualTotalChange} />
                    <Expenses onTotalExpensesChange={handleTotalExpensesChange} />
                    <Damages onTotalDamagesChange={handleTotalDamagesChange} />
                    <Bank onBalanceChange={handleTotalBanksChange} />
                    <Profit onProfitChange={handleTotalProfitsChange} />


                    {/* Earnings Cards */}
                    {user.email !== 'bizstoradmin@gmail.com' && (
                    <Grid item xs={12} md={3}>
                        <Card
                            title="Earnings (Monthly)"
                            value={`Lkr. ${monthlyTotal.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                            icon={AttachMoney}
                            color="primary.main"
                        />
                    </Grid>)}
                    {user.email !== 'bizstoradmin@gmail.com' && (
                    <Grid item xs={12} md={3}>
                        <Card
                            title="Profit (Monthly)"
                            value={`Lkr. ${totalProfits.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                            icon={ShowChartIcon}
                            color="success.main"
                        />
                    </Grid>)}
                    {user.email !== 'bizstoradmin@gmail.com' && (
                    <Grid item xs={12} md={3}>
                        <Card
                            title="Expenses"
                            value={`Lkr. ${totalExpenses.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                            icon={MoneyOff}
                            color="error.main"
                        />
                    </Grid>)}
                    {user.email !== 'bizstoradmin@gmail.com' && (
                    <Grid item xs={12} md={3}>
                        <Card
                            title="Bank"
                            value={`Lkr. ${((annualTotal + totalBanks - totalExpenses).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","))}`}
                            icon={AccountBalance}
                            color="secondary.main"
                        />
                    </Grid>)}
                    {user.email === 'bizstoradmin@gmail.com' && (
                    <Grid item xs={12} md={3}>
                        <Card
                            title="All Users"
                            value={userCount}
                            icon={PeopleIcon}
                            color="secondary.main"
                        />
                    </Grid>)}
                    {user.email === 'bizstoradmin@gmail.com' && (
                    <Grid item xs={12} md={3}>
                        <Card
                            title="Active Users"
                            value={activeUserCount}
                            icon={PersonIcon}
                            color="success.main"
                        />
                    </Grid>)}
                    {user.email === 'bizstoradmin@gmail.com' && (
                    <Grid item xs={12} md={3}>
                        <Card
                            title="Deactivate Users"
                            value={userCount - activeUserCount}
                            icon={PersonIcon}
                            color="error.main"
                        />
                    </Grid>)}
                    {user.email === 'bizstoradmin@gmail.com' && (
                    <Grid item xs={12} md={3}>
                        <Card
                            title="Total Payment"
                            value={`Lkr. ${totalAmount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                            icon={AttachMoney}
                            color="primary.main"
                        />
                    </Grid>)}
                    {user.email !== 'bizstoradmin@gmail.com' && (
                    <Grid container spacing={2} sx={{ mt: 4 }}>
                        <Grid item xs={12} md={4}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '90%',
                                    p: 2,
                                    bgcolor: 'background.paper',
                                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                                    borderRadius: 1,
                                    ml: 3,
                                    mr: 3,

                                }}
                            >
                                <Typography variant="h6" color="text.primary">
                                    <b>Shortcut</b>
                                </Typography>
                                <Box display="flex" alignItems="center" mt={2}>

                                    <Invoice />

                                    {/*<Link component={RouterLink} to="/reports" underline="none">
                                    <IconButton>
                                        <Assessment fontSize="large" />
                                    </IconButton>
                                </Link>*/}
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Box
                                sx={{
                                    p: 2,
                                    bgcolor: 'background.paper',
                                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                                    borderRadius: 1,
                                    height: '90%',
                                    ml: 3,
                                    mr: 3,

                                }}
                            >
                                <Box mt={2} >
                                    <Chart3 onTotalAmountChange={handleTotalAmountChange} />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>)}
                </Grid>
            </Box>
            {user.email !== 'bizstoradmin@gmail.com' && (
            <Stock />
            )}
        </Box>
    );
};

export default Home;
