import React, { useState, useEffect } from 'react';
import axios from 'axios';

const YourComponent = ({ orderId }) => {
    const [payments, setPayments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalPaymentAmount, setTotalPaymentAmount] = useState(0);

    const fetchPaymentData = async () => {
        setLoading(true);
        try {
            const response = await axios.get('https://admin.bizstor.lk/api/get-payment-data');

            // Define an array of payment_ids to filter
            const paymentIds = [88, 75, 98];

            // Filter payments where payment_id is in the array
            const filteredPayments = response.data.filter(payment => paymentIds.includes(payment.payment_id));

            // Set the filtered payments to state
            setPayments(filteredPayments);

            // Calculate the total amount for the filtered payments
            const total = filteredPayments.reduce((acc, payment) => acc + payment.amount, 0);
            setTotalPaymentAmount(total);
        } catch (error) {
            console.error('Error fetching payment data:', error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchPaymentData();
    }, [orderId]);

    return (
        <div>
            {loading ? (
                <p>Loading...</p>
            ) : (
                <div>
                    <h2>Total Payment Amount: {totalPaymentAmount}</h2>
                    {/* Render the filtered payment data if needed */}
                    <ul>
                        {payments.map((payment, index) => (
                            <li key={index}>Payment ID: {payment.payment_id}, Amount: {payment.amount}</li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default YourComponent;
