import React, { useState, useEffect } from "react";
import axios from "axios";
import {
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Button,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TablePagination,
    Box,
    Typography,
    Snackbar,
    Alert,TableContainer
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment } from '@mui/material';
import { styled, keyframes } from '@mui/system';

const slideInFromLeft = keyframes`
  0% {
    transform: translateX(-20px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const AnimatedTypography = styled(Typography)(({ theme }) => ({
    animation: `${slideInFromLeft} 0.5s ease-out`,
}));

const UserPaymentsTable = () => {
    const [userPayments, setUserPayments] = useState([]);
    const [loading, setLoading] = useState(true); // Add loading state
    const [open, setOpen] = useState(false);
    const [selectedPayment, setSelectedPayment] = useState(null);
    const [editData, setEditData] = useState({
        person: "",
        package: "",
        amount: "",
        date: "",
        expiredate: "",
    });
    const [selectedPackage, setSelectedPackage] = useState(""); // For handling package selection
    const [searchQuery, setSearchQuery] = useState(""); // For search query
    const [page, setPage] = useState(0); // Page number for pagination
    const [rowsPerPage, setRowsPerPage] = useState(5); // Number of rows per page
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const handleSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    // Fetch user payment data
    useEffect(() => {
        setLoading(true); // Start loading when the request starts
        axios
            .get("https://admin.bizstor.lk/api/get-user-payment")
            .then((response) => {
                if (Array.isArray(response.data.userpayment)) {
                    setUserPayments(response.data.userpayment); // Set user payment data
                } else {
                    console.error("Expected an array for userPayments");
                }
            })
            .catch((error) => {
                console.error("Error fetching user payments:", error);
            })
            .finally(() => {
                setLoading(false); // End loading after the request finishes
            });
    }, []);

    // Filtered payments based on search query
    // Filtered payments based on search query, added check for undefined
    const filteredPayments = userPayments.filter((payment) =>
        payment?.person ? payment.person.toLowerCase().includes(searchQuery.toLowerCase()) : false
    );

    // Paginate filtered payments, added check for undefined
    const paginatedPayments = filteredPayments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);


    // Paginate filtered payments

    // Open the dialog and set the selected payment data
    const handleClickOpen = (payment) => {
        if (payment) {
            setSelectedPayment(payment);
            setEditData({
                person: payment.person || "",
                package: payment.package || "",
                amount: payment.amount || "",
                date: payment.date || "",
                expiredate: payment.expiredate || "",
            });
            setSelectedPackage(payment.package || ""); // Set the selected package from the payment data
            setOpen(true);
        }
    };

    // Close the dialog
    const handleClose = () => {
        setOpen(false);
    };

    // Handle input changes in the dialog
    const handleChange = (e) => {
        setEditData({
            ...editData,
            [e.target.name]: e.target.value,
        });
    };

    // Handle package selection change
    const handlePackageChange = (e) => {
        setSelectedPackage(e.target.value);
        setEditData({
            ...editData,
            package: e.target.value, // Update the package in editData
        });
    };

    // Handle submitting the updated data via Axios PUT request
    const handleSubmit = () => {
        setLoading(true);
        if (selectedPayment) {
            // Calculate expiredate based on the selected package
            let expireDate = new Date(editData.date || new Date());

            switch (selectedPackage) {
                case '01 month':
                    expireDate.setMonth(expireDate.getMonth() + 1);
                    break;
                case '03 month':
                    expireDate.setMonth(expireDate.getMonth() + 3);
                    break;
                case '06 month':
                    expireDate.setMonth(expireDate.getMonth() + 6);
                    break;
                case '01 year':
                    expireDate.setFullYear(expireDate.getFullYear() + 1);
                    break;
                default:
                    break;
            }

            // Update expiredate in editData
            const updatedData = {
                ...editData,
                expiredate: expireDate.toISOString().split('T')[0], // Format it as 'YYYY-MM-DD'
            };

            axios
                .put(`https://admin.bizstor.lk/api/update-user-payment/${selectedPayment.id}`, updatedData)
                .then((response) => {
                    // Update the state with the new data
                    setUserPayments((prevPayments) =>
                        prevPayments.map((payment) =>
                            payment.id === selectedPayment.id ? response.data.updatedPayment : payment
                        )
                    );
                    handleClose();
                    setLoading(false);
                    handleSnackbar('Payment updated successfully!', 'success');

                })
                .catch((error) => {
                    console.error("Error updating user payment:", error);
                    setLoading(false);
                    handleSnackbar('Error updating payment!', 'error');
                });
        }
    };

    // Handle deleting a payment
    const handleDelete = (id) => {
        setLoading(true);
        axios
            .delete(`https://admin.bizstor.lk/api/delete-user-payment/${id}`)
            .then((response) => {
                // Remove the deleted payment from the list
                setUserPayments((prevPayments) =>
                    prevPayments.filter((payment) => payment.id !== id)
                );
                setLoading(false);
                handleSnackbar('Payment deleted successfully!', 'success');
            })
            .catch((error) => {
                console.error("Error deleting user payment:", error);
                setLoading(false);
                handleSnackbar('Error deleting payment!', 'success');
            });
    };

    // Handle search input
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    // Handle page change
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // Handle rows per page change
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Box>
        <AnimatedTypography
            component="h1"
            sx={{
                ml: 5,
                mt: 3,
                fontSize: '1rem',
                color: 'secondary.main',
                letterSpacing: '0.01em',
            }}
        >
            Dashboard / Payment Reports
        </AnimatedTypography>
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '95%',
                maxWidth: 'auto',
                margin: 'auto',
                mb: 3,
                boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                padding: '10px',
                borderRadius: '5px',
                bgcolor: 'background.paper',
                marginTop: '30px',
                position: 'relative',
            }}
        >
            <Typography variant="h4">
                Payment Reports
            </Typography>
    
            <Paper style={{ padding: "16px" }}>
                {/* Search Bar */}
                <TextField
                    variant="outlined"
                    size="small"
                    margin="normal"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    placeholder="Search…"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    sx={{ width: '250px', mb: 2 }}
                />
    
                {/* Loading Spinner */}
                {loading ? (
                    <Box sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100vw',
                        height: '100vh',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        bgcolor: 'white',
                        zIndex: 9999,
                    }}>
                        <l-trio size="50" speed="1.3" color="red"></l-trio>
                    </Box>
                ) : (
                    <>
                        {/* Payments Table */}
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ fontWeight: 'bold' }}>Email</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }}>Package</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }}>Amount</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }}>Date</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }}>Expire Date</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }}>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {paginatedPayments.length > 0 ? (
                                        paginatedPayments.map((payment, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{payment.person || "N/A"}</TableCell>
                                                <TableCell>{payment.package || "N/A"}</TableCell>
                                                <TableCell>Rs.{Number(payment.amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "N/A"}</TableCell>
                                                <TableCell>{payment.date || "N/A"}</TableCell>
                                                <TableCell>{payment.expiredate || "N/A"}</TableCell>
                                                <TableCell>
                                                    <IconButton aria-label="update" onClick={() => handleClickOpen(payment)}>
                                                        <EditIcon />
                                                    </IconButton>
                                                    <IconButton aria-label="delete" onClick={() => handleDelete(payment.id)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={7}>No payment records found</TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
    
                        {/* Pagination */}
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={filteredPayments.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </>
                )}
    
                {/* Popup Dialog for editing payment */}
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Edit Payment</DialogTitle>
                    <DialogContent>
                        {/* Grid for the text fields and select component */}
                        <Grid container spacing={2}>
                            {/* Person TextField */}
                            <Grid item xs={12}>
                                <TextField
                                    margin="dense"
                                    label="Person"
                                    name="person"
                                    fullWidth
                                    value={editData.person || ""}
                                    onChange={handleChange}
                                    size="small"
                                    disabled
                                />
                            </Grid>
    
                            {/* Package Select */}
                            <Grid item xs={12}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel>Package</InputLabel>
                                    <Select
                                        value={selectedPackage || ""}
                                        onChange={handlePackageChange}
                                        label="Package"
                                        size="small"
                                    >
                                        <MenuItem value="01 month">01 month</MenuItem>
                                        <MenuItem value="03 month">03 month</MenuItem>
                                        <MenuItem value="06 month">06 month</MenuItem>
                                        <MenuItem value="01 year">01 year</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
    
                            {/* Amount TextField */}
                            <Grid item xs={12}>
                                <TextField
                                    margin="dense"
                                    label="Amount"
                                    name="amount"
                                    type="number"
                                    fullWidth
                                    value={editData.amount || ""}
                                    onChange={handleChange}
                                    size="small"
                                />
                            </Grid>
    
                            {/* Date TextField */}
                            <Grid item xs={12}>
                                <TextField
                                    margin="dense"
                                    label="Date"
                                    name="date"
                                    type="date"
                                    fullWidth
                                    value={editData.date || ""}
                                    onChange={handleChange}
                                    size="small"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="secondary">Cancel</Button>
                        <Button onClick={handleSubmit} color="primary">Save</Button>
                    </DialogActions>
                </Dialog>
            </Paper>
        </Box>
        <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
            <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
                {snackbarMessage}
            </Alert>
        </Snackbar>
    </Box>
        );
};

export default UserPaymentsTable;
