import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table as MuiTable, TableBody, TableRow, TableCell, Checkbox, Typography } from '@mui/material';

const Table = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [user, setUser] = useState({});
    const [searchQuery, setSearchQuery] = useState('');    
    const [token, setToken] = useState(() => localStorage.getItem('token') || null);

    useEffect(() => {
        if (token) {
            localStorage.setItem('token', token);
        }
    }, [token]);
    
    useEffect(() => {
        const fetchProfile = async () => {
            if (!token) return; // Exit if no token
    
            try {
                const response = await axios.get('https://admin.bizstor.lk/api/profile', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setUser(response.data);
            } catch (error) {
                console.log('');
            }
        };
    
        fetchProfile();
    }, [token]);
    
    // Update searchQuery whenever user.id changes
    useEffect(() => {
        if (user.id) {
            setSearchQuery(String(user.id)); // Ensure searchQuery is a string
        }
    }, [user.id]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await axios.get('https://admin.bizstor.lk/api/get-data');
                setData(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    // Filter data based on search query
    const filteredData = data.filter((order) =>
        order.usertoken.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div>
            {user.id}
            {user.name}
            <MuiTable>
                <TableBody>
                    {filteredData.length > 0 ? (
                        filteredData.map((order, index) => (
                            <TableRow
                                key={index}
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                style={{ backgroundColor: '#f0f0f0' }}
                            >
                                <TableCell padding="checkbox">
                                    <Checkbox />
                                </TableCell>
                                <TableCell>
                                    <Typography variant="body2" fontWeight="bold">
                                        {order.usertoken}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="body2" fontWeight="bold">
                                        {order.fullname}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="body2" fontWeight="bold">
                                        {order.customer}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="body2" fontWeight="bold">
                                        {order.term}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="body2" fontWeight="bold">
                                        {order.invoicedate}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="body2" fontWeight="bold">
                                        {order.duedate}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ))
                    ) : (
                        <TableRow>
                            <TableCell colSpan={7} align="center">
                                <Typography variant="body2" color="textSecondary">
                                    No data found
                                </Typography>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </MuiTable>
        </div>
    );
};

export default Table;
