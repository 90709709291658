import { useState, useEffect } from 'react';
import axios from 'axios';

const BalanceSheet = ({ onBalanceChange }) => {
    const [user, setUser] = useState({});
    const [token, setToken] = useState(() => localStorage.getItem('token') || null);
    const [investments, setInvestments] = useState([]);
    const [balance, setBalance] = useState(0);

    useEffect(() => {
        if (token) {
            localStorage.setItem('token', token);
        }
    }, [token]);

    useEffect(() => {
        const fetchProfile = async () => {
            if (!token) return; // Exit if no token

            try {
                const response = await axios.get('https://admin.bizstor.lk/api/profile', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setUser(response.data);
            } catch (error) {
                console.log('');
            }
        };

        fetchProfile();
    }, [token]);

    useEffect(() => {
        if (user.id) {
            fetchInvestments();
        }
    }, [user.id]);

    const fetchInvestments = async () => {
        try {
            const response = await axios.get('https://admin.bizstor.lk/api/get-investment');
            if (user.id) {
                const filteredInvestments = response.data.investment.filter(
                    (investment) => investment.usertoken.toLowerCase() === user.id.toString().toLowerCase()
                );
                setInvestments(filteredInvestments);
            } else {
                setInvestments([]); // In case user.id is not found
            }
        } catch (error) {
            console.error('Error fetching investment data:', error);
        }
    };

    useEffect(() => {
        const totalFromBank = investments
            .filter((investment) => investment.from === 'bank')
            .reduce((sum, investment) => sum + (Number(investment.amount) || 0), 0);

        const totalToBank = investments
            .filter((investment) => investment.to === 'bank')
            .reduce((sum, investment) => sum + (Number(investment.amount) || 0), 0);

        const calculatedBalance = totalToBank - totalFromBank;
        setBalance(calculatedBalance);

        if (onBalanceChange) {
            onBalanceChange(calculatedBalance);
        }
    }, [investments, onBalanceChange]);

    // Return null to render nothing in the component
    return null;
};

export default BalanceSheet;
