import React from 'react';
import { Box, Tabs, Tab, Typography, Paper } from '@mui/material';
import Chart1 from '../Linechart';
import Chart2 from '../Linechart3';
import Chart3 from '../Linechart2';


// Create a functional component for each tab's content
const TabPanel = ({ value, index, children }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const AllChart = () => {
  const [tabValue, setTabValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Paper square>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="report tabs"
          centered
        >
          <Tab label="weekly" />
          <Tab label="monthly" />
          <Tab label="yearly" />
        </Tabs>
      </Paper>
      <TabPanel value={tabValue} index={0}>
        <Chart1/>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
      <Chart2/>
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
      <Chart3/>
      </TabPanel>
    </Box>
  );
};

export default AllChart;
