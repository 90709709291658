import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    TextField,
    Button,
    Box,
    Typography,
    Snackbar,
    Grid,
    Alert,
    TableCell,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@mui/material';
import { styled, keyframes } from '@mui/system';

const slideInFromLeft = keyframes`
  0% {
    transform: translateX(-20px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const AnimatedTypography = styled(Typography)(({ theme }) => ({
    animation: `${slideInFromLeft} 0.5s ease-out`,
}));

const Payments = () => {
    const [user, setUser] = useState({});
    const [token, setToken] = useState(() => localStorage.getItem('token') || null);
    const [amount, setAmount] = useState('');
    const [date, setDate] = useState(new Date().toISOString().split('T')[0]); 
    const [selectedUser, setSelectedUser] = useState('');
    const [selectedPackage, setSelectedPackage] = useState('');
    const [loading, setLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [users, setUsers] = useState([]); 

    const handleSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    useEffect(() => {
        if (token) {
            localStorage.setItem('token', token);
        }
    }, [token]);

    useEffect(() => {
        const fetchProfile = async () => {
            if (!token) return; 

            try {
                const response = await axios.get('https://admin.bizstor.lk/api/profile', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setUser(response.data);
            } catch (error) {
                console.log('');

            }
        };

        fetchProfile();
    }, [token]);

    const handleSubmit = async () => {
        setLoading(true);
    
        try {
            // Calculate expire date based on selected package
            let expireDate = new Date(date || new Date());
    
            switch (selectedPackage) {
                case '01 month':
                    expireDate.setMonth(expireDate.getMonth() + 1);
                    break;
                case '03 month':
                    expireDate.setMonth(expireDate.getMonth() + 3);
                    break;
                case '06 month':
                    expireDate.setMonth(expireDate.getMonth() + 6);
                    break;
                case '01 year':
                    expireDate.setFullYear(expireDate.getFullYear() + 1);
                    break;
                default:
                    break;
            }
    
            // Format the expireDate to YYYY-MM-DD
            const formattedExpireDate = expireDate.toISOString().split('T')[0];
    
            const data = {
                person: selectedUser,
                package: selectedPackage,
                amount: amount,
                date: date || new Date().toISOString().split('T')[0],
                expiredate: formattedExpireDate,
            };
    
            await axios.post('https://admin.bizstor.lk/api/user-payment', data);
    
            // Reset form fields
            setSelectedUser('');
            setSelectedPackage('');
            setAmount('');
            setDate(new Date().toISOString().split('T')[0]);
    
            handleSnackbar('Payment added successfully!', 'success');
        } catch (error) {
            console.error('Error saving data:', error);
            handleSnackbar('Error saving data!', 'error');
        } finally {
            setLoading(false);
        }
    };
    
    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await axios.get("https://admin.bizstor.lk/api/users");
                setUsers(response.data); 
            } catch (error) {
                console.error("Error fetching users:", error);
            }
        };

        fetchUsers();
    }, []);

    return (
        <Box>
            <AnimatedTypography
                component="h1"
                sx={{
                    ml: 5,
                    mt: 3,
                    fontSize: '1rem',
                    color: 'secondary.main',
                    letterSpacing: '0.01em',
                }}
            >
                Dashboard / Package
            </AnimatedTypography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '95%',
                    maxWidth: 'auto',
                    margin: 'auto',
                    mb: 3,
                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                    padding: '10px',
                    borderRadius: '5px',
                    bgcolor: 'background.paper',
                    marginTop: '30px',
                    position: 'relative',
                }}
            >
                <Typography variant="h5">Package</Typography>
                <hr style={{ borderTop: '1px solid orange', width: '100%', margin: '10px 0' }} />

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        justifyContent: 'space-between',
                        padding: '20px',
                    }}
                >
                    <Grid container spacing={2} item xs={12} md={6}>
                        <Grid item xs={12}>
                            <FormControl variant="outlined" fullWidth size="small">
                                <InputLabel id="user-label">Person</InputLabel>
                                <Select
                                    labelId="user-label"
                                    id="user-select"
                                    value={selectedUser}
                                    onChange={(e) => setSelectedUser(e.target.value)}
                                    label="Person"
                                    sx={{ mb: 1 }}
                                >
                                    {users
                                        .filter(user => user.email !== 'bizstoradmin@gmail.com')
                                        .map((user) => (
                                            <MenuItem key={user.id} value={user.email}>
                                                {user.email}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl variant="outlined" fullWidth size="small">
                                <InputLabel id="package-label">Package</InputLabel>
                                <Select
                                    labelId="package-label"
                                    id="package-select"
                                    value={selectedPackage}
                                    onChange={(e) => setSelectedPackage(e.target.value)}
                                    label="package"
                                    sx={{ mb: 1 }}
                                >                                   
                                <MenuItem value="01 month">1 month</MenuItem>
            <MenuItem value="03 month">3 months</MenuItem>
            <MenuItem value="06 month">6 months</MenuItem>
            <MenuItem value="01 year">1 year</MenuItem>                                                            
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Amount"
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                                variant="outlined"
                                fullWidth
                                size="small"
                                sx={{ mb: 1 }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                type="date"
                                value={date || new Date().toISOString().split('T')[0]}
                                onChange={(e) => setDate(e.target.value)}
                                variant="outlined"
                                fullWidth
                                size="small"
                                sx={{ mb: 1 }}
                            />
                        </Grid>
                    </Grid>

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-end',
                            justifyContent: 'center',
                            textAlign: 'right',
                            marginLeft: { xs: 0, md: '20px' },
                            marginTop: { xs: '20px', md: 0 },
                        }}
                    >
                        <Typography variant="subtitle1">AMOUNT</Typography>
                        <Typography variant="h4" fontWeight="bold">
                            Lkr. {Number(amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        style={{
                            backgroundColor: '#4287f5',
                            color: 'white',
                            marginTop: '30px',
                            borderRadius: '20px',
                            padding: '8px 24px',
                            '&:hover': {
                                backgroundColor: '#3567c9',
                            },
                        }}
                        disabled={loading}
                    >
                        {loading ? 'Processing Payment...' : 'Add'}
                    </Button>
                </Box>
            </Box>

            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            
        </Box>
    );
};

export default Payments;
