// Helper function to calculate the total amount
const calculateTotalAmount = (items) => {
  return items.reduce((sum, item) => sum + (item.quantity * item.rate), 0);
};

// Helper function to calculate the total quantity
const calculateTotalQuantity = (items) => {
  return items.reduce((sum, item) => sum + item.quantity, 0);
};

export const printReturnRows = (selectedRowsData) => {
  // Create a new window for printing
  const printWindow = window.open('', '_blank');

  // Set up the document structure and styling
  printWindow.document.write('<html><head><title>Return Details</title>');
  printWindow.document.write('<style>');
  printWindow.document.write(`
    body {
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      margin: 20px;
      color: #333;
    }
    h2 {
      text-align: center;
      color: #4CAF50;
    }
    table {
      width: 100%;
      border-collapse: collapse;
      margin-bottom: 20px;
      font-size: 14px;
    }
    th, td {
      border: 1px solid #ddd;
      padding: 12px;
      text-align: left;
    }
    th {
      background-color: #f7f7f7;
      font-weight: bold;
    }
    tr:nth-child(even) {
      background-color: #f9f9f9;
    }
    tr:hover {
      background-color: #f1f1f1;
    }
    .summary-row {
      background-color: #4CAF50;
      color: #fff;
    }
    .summary-row td {
      font-weight: bold;
    }
    .no-border {
      border: none;
    }
    .header-row {
      background-color: #e0e0e0;
      font-weight: bold;
      text-transform: uppercase;
    }
  `);
  printWindow.document.write('</style>');
  printWindow.document.write('</head><body>');

  // Add a title
  printWindow.document.write('<h2>Return Details</h2>');

  // Add the main table structure for selected return rows
  printWindow.document.write('<table>');
  printWindow.document.write('<thead>');
  printWindow.document.write('<tr class="header-row"><th>ID</th><th>Full Name</th><th>Customer Type</th><th>Invoice Date</th><th>Return Type</th></tr>');
  printWindow.document.write('</thead>');
  printWindow.document.write('<tbody>');

  selectedRowsData.forEach((row) => {
    printWindow.document.write('<tr>');
    printWindow.document.write(`<td>${row.id}</td>`);
    printWindow.document.write(`<td>${row.fullname}</td>`);
    printWindow.document.write(`<td>${row.customer}</td>`);
    printWindow.document.write(`<td>${row.invoicedate}</td>`);
    printWindow.document.write(`<td>${row.type}</td>`);
    printWindow.document.write('</tr>');

    // Add a row for items header
    printWindow.document.write('<tr><td colspan="5" class="no-border"><strong>Return Details</strong></td></tr>');

    // Add a table for the items within each return
    printWindow.document.write('<tr><td colspan="5" class="no-border"><table style="width:100%; border-collapse: collapse;">');
    printWindow.document.write('<thead>');
    printWindow.document.write('<tr class="header-row"><th>Product</th><th>Qty</th><th>Rate</th><th>Price</th><th>Service Date</th></tr>');
    printWindow.document.write('</thead>');
    printWindow.document.write('<tbody>');

    row.items && row.items.forEach(item => {
      printWindow.document.write('<tr>');
      printWindow.document.write(`<td>${item.product}</td>`);
      printWindow.document.write(`<td>${item.quantity}</td>`);
      printWindow.document.write(`<td>Rs.${item.rate}.00</td>`);
      printWindow.document.write(`<td>Rs.${item.price}.00</td>`);
      printWindow.document.write(`<td>${item.servicedate}</td>`);
      printWindow.document.write('</tr>');
    });

    // Conditionally display total based on return type
    if (row.type === 'money back') {
      printWindow.document.write('<tr class="summary-row">');
      printWindow.document.write('<td colspan="3" align="right"><strong>Total Amount</strong></td>');
      printWindow.document.write('<td>Rs.' + calculateTotalAmount(row.items) + '.00</td>');
      printWindow.document.write('<td></td>'); // Empty cell
      printWindow.document.write('</tr>');
    } else if (row.type === 'replace') {
      printWindow.document.write('<tr class="summary-row">');
      printWindow.document.write('<td colspan="3" align="right"><strong>Total Quantity</strong></td>');
      printWindow.document.write('<td></td>'); // Empty cell
      printWindow.document.write('<td>' + calculateTotalQuantity(row.items) + '</td>');
      printWindow.document.write('</tr>');
    }

    printWindow.document.write('</tbody>');
    printWindow.document.write('</table></td></tr>');
  });

  printWindow.document.write('</tbody>');
  printWindow.document.write('</table>');
  printWindow.document.write('</body></html>');

  // Print and close the window
  printWindow.document.close();
  printWindow.print();
  printWindow.close();
};
