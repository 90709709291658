import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    TextField,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    IconButton,
    Typography,
    Grid,
    Select,
    Snackbar,
    MenuItem,
    InputLabel,
    FormControl,
    ListSubheader,
    Alert,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from 'react-router-dom';
import { printFormData } from '../Tested/Print';
import { styled, keyframes } from '@mui/system';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';


const slideInFromLeft = keyframes`
  0% {
    transform: translateX(-20px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const AnimatedTypography = styled(Typography)(({ theme }) => ({
    animation: `${slideInFromLeft} 0.5s ease-out`,
}));

const FormWithTable = () => {
    const [rows, setRows] = useState([{ id: 1, product: '', quantity: '', rate: '', price: '', servicedate: new Date().toISOString().split('T')[0], tax: '' }]);
    const [fullname, setFullname] = useState('');
    const [email, setEmail] = useState('');
    const [type, setType] = useState('');
    const [payamount, setPayamount] = useState('');
    const [customer, setCustomer] = useState('');
    const [address, setBillingAddress] = useState('');
    const [term, setTerm] = useState('due on receipt');
    const [invoicedate, setInvoiceDate] = useState(new Date().toISOString().split('T')[0]);
    const [duedate, setDueDate] = useState('');
    const [description, setDescription] = useState('');
    const [discount, setDiscount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [customers, setCustomers] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedCustomerData, setSelectedCustomerData] = useState({
        fullname: '',
        email: '',
        address: '',
    });
    const [invoiceNo, setInvoiceNo] = useState(1);
    const [user, setUser] = useState({});
    const [token, setToken] = useState(() => localStorage.getItem('token') || null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');


    const [isMinimized, setIsMinimized] = useState(false);

    const toggleBoxSize = () => {
        setIsMinimized(!isMinimized);
    };


    const handleSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const fetchCustomers = () => {
        setLoading(true);
        axios.get('https://admin.bizstor.lk/api/get-customer-data')
            .then(response => {
                if (user.id) {
                    console.log('console.log("");:', user.id);
                    const Customers = response.data.customers.filter(customer =>
                        customer.usertoken.toLowerCase() === user.id.toString().toLowerCase()
                    );
                    setCustomers(Customers);
                }
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching customers:', error);
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchCustomers();
    }, []);

    const handleOptionChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedOption(selectedValue);

        if (selectedValue === 'Direct Customer') {
            setSelectedCustomerData({
                id: '',
                fullname: '',
                email: '',
                address: '',
            });
        } else {
            const selectedCustomer = customers.find((customer) => customer.id === selectedValue);
            if (selectedCustomer) {
                setSelectedCustomerData({
                    fullname: selectedCustomer.firstname,
                    email: selectedCustomer.email,
                    address: selectedCustomer.address,
                });
            }
        }
    };

    const handleAddRow = () => {
        const newRow = {
            id: rows.length + 1,
            product: '',
            quantity: '',
            rate: '',
            price: '',
            serviceDate: new Date().toISOString().split('T')[0],
            tax: ''
        };
        setRows([...rows, newRow]);
    };

    const handleDeleteRow = (index) => {
        const newRows = rows.filter((row, i) => i !== index);
        setRows(newRows);
    };

    const handleInputChange = (index, field, value) => {
        const newRows = rows.map((row, i) => {
            if (i === index) {
                const updatedRow = { ...row, [field]: value };
                if (field === 'product') {
                    updatedRow.rate = productPrices[value] || '';
                    updatedRow.price = (updatedRow.rate || 0) * (row.quantity || 0);
                }
                if (field === 'quantity' || field === 'rate') {
                    updatedRow.price = (updatedRow.rate || 0) * (updatedRow.quantity || 0);
                }
                return updatedRow;
            }
            return row;
        });

        setRows(newRows);

        {/** if (field === 'product' && value !== '' && index === rows.length - 1) {
            handleAddRow();
        }*/}
    };

    useEffect(() => {
        const invoiceDateObj = new Date(invoicedate);
        let daysToAdd = 0;

        switch (term) {
            case 'net 15':
                daysToAdd = 15;
                break;
            case 'net 30':
                daysToAdd = 30;
                break;
            case 'net 60':
                daysToAdd = 60;
                break;
            default:
                daysToAdd = 0;
        }

        invoiceDateObj.setDate(invoiceDateObj.getDate() + daysToAdd);
        setDueDate(invoiceDateObj.toISOString().split('T')[0]);
    }, [term, invoicedate]);

    useEffect(() => {
        const lastInvoiceNo = parseInt(localStorage.getItem('lastInvoiceNo')) || 1;
        setInvoiceNo(lastInvoiceNo);
    }, []);

    const handleSubmit = async () => {
        setLoading(true);

        try {
            const data = {
                usertoken: user.id,
                invoiceNo: invoiceNo,
                fullname: selectedOption === 'Direct Customer' ? fullname : selectedCustomerData.fullname,
                email: selectedOption === 'Direct Customer' ? email : selectedCustomerData.email,
                customer: selectedOption === 'Direct Customer' ? 'Direct Customer' : 'Existing Customer',
                address: selectedOption === 'Direct Customer' ? address : selectedCustomerData.address,
                term: term,
                invoicedate: invoicedate,
                duedate: duedate,
                description: description,
                discount: discount,
                payamount: payamount,
                company: user.companyname,
                address: user.address,
                uemail: user.email,
                web: user.website,
                phone: user.phone,
                image: user.image,
                items: rows.filter(row => row.product && row.quantity).map(row => ({
                    product: row.product,
                    quantity: row.quantity,
                    rate: row.rate,
                    price: row.price,
                    servicedate: row.servicedate || new Date().toISOString().split('T')[0],
                    tax: row.tax
                }))
            };

            console.log('Data to be saved:', data);

            const response = await axios.post('https://admin.bizstor.lk/api/save-data', data);
            console.log('Invoice saved successfully:', response.data);

            printFormData(data);

            try {
                await axios.post('https://admin.bizstor.lk/api/send-invoice-email', {
                    email: data.email,
                    invoiceData: data
                });
                handleSnackbar('Invoice email sent successfully!', 'success');

            } catch (emailError) {
                console.error('Error sending invoice email:', emailError);
            }

            const nextInvoiceNo = invoiceNo + 1;
            setInvoiceNo(nextInvoiceNo);
            localStorage.setItem(`lastInvoiceNo_${user.id}`, nextInvoiceNo);

            setFullname('');
            setEmail('');
            setType('');
            setPayamount('');
            setCustomer('');
            setBillingAddress('');
            setTerm('due on receipt');
            setInvoiceDate(new Date().toISOString().split('T')[0]);
            setDueDate(new Date().toISOString().split('T')[0]);
            setDescription('');
            setDiscount(0);
            setRows([{ id: 1, product: '', quantity: '', rate: '', price: '', servicedate: '', tax: '' }]);

            handleSnackbar('Invoice save and email send successfully!', 'success');
            setLoading(false);
        } catch (error) {
            handleSnackbar('error saving invoice!', 'error');
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };


    const handleSubmitAndPrint = async () => {
        setLoading(true);

        try {
            const data = {
                usertoken: user.id,
                invoiceNo: invoiceNo,
                fullname: selectedOption === 'Direct Customer' ? fullname : selectedCustomerData.fullname,
                email: selectedOption === 'Direct Customer' ? email : selectedCustomerData.email,
                customer: selectedOption === 'Direct Customer' ? 'Direct Customer' : 'Existing Customer',
                address: selectedOption === 'Direct Customer' ? address : selectedCustomerData.address,
                term: term,
                invoicedate: invoicedate,
                duedate: duedate,
                description: description,
                discount: discount,
                payamount: payamount,
                company: user.companyname,
                address: user.address,
                uemail: user.email,
                web: user.website,
                phone: user.phone,
                image: user.image,
                items: rows.filter(row => row.product && row.quantity).map(row => ({
                    product: row.product,
                    quantity: row.quantity,
                    rate: row.rate,
                    price: row.price,
                    servicedate: row.servicedate || new Date().toISOString().split('T')[0],
                    tax: row.tax
                }))
            };


            const response = await axios.post('https://admin.bizstor.lk/api/save-data', data);

            printFormData(data);


            const nextInvoiceNo = invoiceNo + 1;
            setInvoiceNo(nextInvoiceNo);
            localStorage.setItem(`lastInvoiceNo_${user.id}`, nextInvoiceNo);

            setFullname('');
            setEmail('');
            setType('');
            setPayamount('');
            setCustomer('');
            setBillingAddress('');
            setTerm('due on receipt');
            setInvoiceDate(new Date().toISOString().split('T')[0]);
            setDueDate(new Date().toISOString().split('T')[0]);
            setDescription('');
            setDiscount(0);
            setRows([{ id: 1, product: '', quantity: '', rate: '', price: '', servicedate: '', tax: '' }]);

            handleSnackbar('Invoice save successfully!', 'success');
            setLoading(false);
        } catch (error) {
            handleSnackbar('error saving invoice!', 'error');
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };



    const calculateTotalAmount = () => {
        const total = rows.reduce((sum, row) => {
            const rowPrice = parseFloat(row.price) || 0;
            return sum + rowPrice;
        }, 0);

        return total;
    };

    const grandTotal = () => {
        const total = rows.reduce((sum, row) => {
            const rowPrice = parseFloat(row.price) || 0;
            const rowTax = row.tax ? (rowPrice * (parseFloat(row.tax) / 100)) : 0;
            return sum + rowPrice + rowTax;
        }, 0);

        const discountAmount = (total * (parseFloat(discount) || 0)) / 100;

        const grandTotal = total - discountAmount;

        return grandTotal;
    }

    const TotalTax = () => {
        const total = rows.reduce((sum, row) => {
            const rowPrice = parseFloat(row.price) || 0;
            const rowTax = row.tax ? (rowPrice * (parseFloat(row.tax) / 100)) : 0;
            return rowTax;
        }, 0);


        return total;
    }

    const TotalDiscount = () => {
        const total = rows.reduce((sum, row) => {
            const rowPrice = parseFloat(row.price) || 0;
            return sum + rowPrice;
        }, 0);

        const discountAmount = (total * (parseFloat(discount) || 0)) / 100;

        const grandTotal = total - discountAmount;

        return discountAmount;
    }


    const calculateBalancedue = () => {
        const totalBeforeDiscount = rows.reduce((sum, row) => {
            const rowPrice = parseFloat(row.price) || 0;
            const rowTax = row.tax ? (rowPrice * (parseFloat(row.tax) / 100)) : 0;
            return sum + rowPrice + rowTax;
        }, 0);

        const discountAmount = (totalBeforeDiscount * (parseFloat(discount) || 0)) / 100;

        let balanceDue = totalBeforeDiscount - discountAmount;

        if (term === 'due on receipt') {
            balanceDue = balanceDue;
        } else {
            balanceDue = balanceDue - parseFloat(payamount) || 0;
        }

        return balanceDue;
    };


    const calculateDiscount = () => {
        const totalBeforeDiscount = rows.reduce((sum, row) => sum + parseFloat(row.price) || 0, 0);

        const discountAmount = (totalBeforeDiscount * (parseFloat(discount) || 0)) / 100;
        return discountAmount;
    };



    const handleDirectCustomerChange = (field, value) => {
        if (field === 'fullname') {
            setFullname(value);
        } else if (field === 'email') {
            setEmail(value);
        } else if (field === 'address') {
            setBillingAddress(value);
        }
    };


    const handleCustomerTypeChange = (event) => {
        const type = event.target.value;
        setSelectedOption(type);
        setCustomer(type === 'Direct Customer' ? 'direct' : '');
    };
    const [products, setProducts] = useState([]);

    const fetchProducts = () => {
        setLoading(true);
        axios.get('https://admin.bizstor.lk/api/get-product-data')
            .then(response => {
                if (user.id) {
                    console.log('console.log("");:', user.id);
                    const Products = response.data.products.filter(product =>
                        product.usertoken.toLowerCase() === user.id.toString().toLowerCase()
                    );
                    setProducts(Products);
                }
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching product data:', error);
                setLoading(false);
            });
    };

    const productPrices = {};
    products.forEach(product => {
        productPrices[product.product] = product.price;
    });

    useEffect(() => {
        if (token) {
            localStorage.setItem('token', token);
        }
    }, [token]);

    useEffect(() => {
        const fetchProfile = async () => {
            if (!token) return;

            try {
                const response = await axios.get('https://admin.bizstor.lk/api/profile', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setUser(response.data);
            } catch (error) {
                console.log('');
            }
        };

        fetchProfile();
    }, [token]);

    useEffect(() => {
        if (user.id) {
            fetchProducts();
            fetchCustomers();
        }
    }, [user.id]);

    useEffect(() => {
        if (user && user.id) {
            const storedInvoiceNo = localStorage.getItem(`lastInvoiceNo_${user.id}`);
            if (storedInvoiceNo) {
                setInvoiceNo(parseInt(storedInvoiceNo, 10));
            } else {
                setInvoiceNo(1);
            }
        }
    }, [user]);

    const formattedPayAmount = Number(payamount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");


    return (
        <Box>

            <AnimatedTypography
                component="h1"
                sx={{
                    ml: 5,
                    mt: 3,
                    fontSize: '1rem',      // Adjust font size as needed
                    color: 'secondary.main', // Use theme color or specify a color like '#333'
                    letterSpacing: '0.01em' // Adjust letter spacing as needed
                }}
            >
                Dashboard / Invoice
            </AnimatedTypography>

            <Box
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '95%',
                        maxWidth: 'auto',
                        margin: 'auto',
                        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                        padding: '10px',
                        borderRadius: '5px',
                        bgcolor: 'background.paper',
                        marginTop: '30px',
                        position: 'relative',
                        marginBottom: {
                            xs: '400px',
                            sm: '150px',
                        },
                    }}
                >
                    <Typography variant="h5"
                        sx={{
                            ml: 2
                        }}
                    >
                        Invoice
                    </Typography>
                    <hr style={{ borderTop: '1px solid orange', width: '100%', margin: '10px 0' }} />


                    <TableContainer>
                        <Table

                            sx={{
                                marginBottom: '30px',
                                maxWidth: '100%'
                            }}
                        >
                            <TableHead>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        <FormControl fullWidth variant="outlined" size="small">
                                            <InputLabel>Customer</InputLabel>
                                            <Select
                                                value={selectedOption}
                                                onChange={handleOptionChange}
                                                fullWidth
                                                label="Customer"
                                                variant="outlined"
                                                sx={{ width: '100%' }}
                                            >
                                                <MenuItem value="Direct Customer">Direct Customer</MenuItem>
                                                <ListSubheader>Existing Customers</ListSubheader>
                                                {customers.map((customer) => (
                                                    <MenuItem key={customer.id} value={customer.id}>
                                                        {customer.firstname} - {customer.email}
                                                    </MenuItem>
                                                ))}
                                                <MenuItem value="">
                                                    <Link to="/addcustomer" style={{ textDecoration: 'none', color: 'inherit' }}>
                                                        Add Customer
                                                    </Link>
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            label="Full Name"
                                            variant="outlined"
                                            value={selectedOption === 'Direct Customer' ? fullname : selectedCustomerData.fullname}
                                            onChange={(e) => handleDirectCustomerChange('fullname', e.target.value)}
                                            disabled={selectedOption !== 'Direct Customer'}
                                            fullWidth
                                            size="small"
                                            sx={{ width: '100%' }}
                                        />
                                    </TableCell>
                                    <TableCell >
                                        <TextField
                                            label="Email"
                                            variant="outlined"
                                            value={selectedOption === 'Direct Customer' ? email : selectedCustomerData.email}
                                            onChange={(e) => handleDirectCustomerChange('email', e.target.value)}
                                            disabled={selectedOption !== 'Direct Customer'}
                                            fullWidth
                                            size="small"
                                            sx={{ width: '100%' }}
                                        />
                                    </TableCell>
                                </TableRow>



                                <TableRow>
                                    <TableCell>
                                        <TextField
                                            label="Billing Address"
                                            variant="outlined"
                                            value={selectedOption === 'Direct Customer' ? address : selectedCustomerData.address}
                                            onChange={(e) => handleDirectCustomerChange('address', e.target.value)}
                                            disabled={selectedOption !== 'Direct Customer'}
                                            fullWidth
                                            size="small"
                                            sx={{ width: '100%' }}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <FormControl variant="outlined" fullWidth size="small">
                                            <InputLabel id="term-label">Terms</InputLabel>
                                            <Select
                                                labelId="term-label"
                                                id="term"
                                                value={term}
                                                onChange={(e) => setTerm(e.target.value)}
                                                label="Terms"
                                                sx={{ width: '100%' }}
                                            >
                                                <MenuItem value="due on receipt">Due on Receipt</MenuItem>
                                                <MenuItem value="net 15">Net 15</MenuItem>
                                                <MenuItem value="net 30">Net 30</MenuItem>
                                                <MenuItem value="net 60">Net 60</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            type="date"
                                            label="Invoice Date"
                                            value={invoicedate}
                                            onChange={(e) => setInvoiceDate(e.target.value)}
                                            variant="outlined"
                                            fullWidth
                                            size="small"
                                            sx={{ width: '100%' }}
                                        />
                                    </TableCell>

                                </TableRow>
                                <TableRow>

                                    <TableCell>
                                        <TextField
                                            type="date"
                                            label="Due Date"
                                            value={duedate}
                                            onChange={(e) => setDueDate(e.target.value)}
                                            variant="outlined"
                                            fullWidth
                                            size="small"
                                            sx={{ width: '100%' }}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TableContainer component={Paper} style={{ marginTop: '15px' }}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Product</TableCell>
                                    <TableCell>Qty</TableCell>
                                    <TableCell>Rate</TableCell>
                                    <TableCell>Amount</TableCell>
                                    <TableCell>Tax</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row, index) => (
                                    <TableRow key={row.id}>
                                        <TableCell>{row.id}</TableCell>
                                        <TableCell>
                                            <FormControl fullWidth>
                                                <Select
                                                    value={row.product}
                                                    onChange={(e) => handleInputChange(index, 'product', e.target.value)}
                                                    fullWidth
                                                    size="small"
                                                    sx={{ width: '150px' }}
                                                >
                                                    {Object.keys(productPrices).map((product) => (
                                                        <MenuItem key={product} value={product}>
                                                            {product}
                                                        </MenuItem>
                                                    ))}
                                                    <MenuItem value="">
                                                        <Link to="/addproduct" style={{ textDecoration: 'none', color: 'inherit' }}>
                                                            Add Product
                                                        </Link>
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                type="number"
                                                value={row.quantity}
                                                onChange={(e) => handleInputChange(index, 'quantity', e.target.value)}
                                                fullWidth
                                                size="small"
                                                sx={{ width: '100px' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                type="number"
                                                value={row.rate}
                                                onChange={(e) => handleInputChange(index, 'rate', e.target.value)}
                                                fullWidth
                                                size="small"
                                                sx={{ width: '100px' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                type="number"
                                                value={Number(row.price).toFixed(2)}
                                                onChange={(e) => handleInputChange(index, 'price', e.target.value)}
                                                disabled
                                                fullWidth
                                                size="small"
                                                sx={{ width: '150px' }}
                                            />
                                        </TableCell>
                                        {/**      <TableCell>
                                   <TextField
                                        type="date"
                                        value= {row.servicedate || new Date().toISOString().split('T')[0]}
                                        onChange={(e) => handleInputChange(index, 'servicedate', e.target.value)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        fullWidth
                                        size="small"
                                        sx={{ width: '150px' }}
                                    />
                                </TableCell>*/}
                                        <TableCell>
                                            <TextField
                                                type="number"
                                                value={row.tax}
                                                onChange={(e) => handleInputChange(index, 'tax', e.target.value)}
                                                fullWidth
                                                size="small"
                                                sx={{ width: '100px' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <IconButton
                                                onClick={() => handleDeleteRow(index)}
                                                size="small"
                                                color='inherit'
                                                sx={{
                                                    '&:hover': {
                                                        color: 'red',
                                                    },
                                                }}
                                            >
                                                <DeleteIcon fontSize="small" />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>

                        </Table>
                    </TableContainer>


                    <Box display="flex" justifyContent="flex-start" marginTop="15px">
                        <Button variant="contained" color="primary" onClick={handleAddRow} size="small" style={{ backgroundColor: 'black', color: 'white' }}
                        >
                            Add Line
                        </Button>
                    </Box>

                    <Grid container spacing={1} style={{ marginTop: '15px' }}>
                        <Grid item xs={6}>
                            <TextField
                                label="Description"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                variant="outlined"
                                fullWidth
                                multiline  // Enable multiline input
                                rows={4}  // Adjust the number of rows as needed
                                size="small"
                            />
                        </Grid>
                    </Grid>
                </Box>

                <Box
                    sx={{
                        width: '95%',
                        height: isMinimized ? '60px' : 'auto', // Adjust height based on minimized state
                        padding: 'auto',
                        boxShadow: 4,
                        bgcolor: '#f4f6f8',
                        borderRadius: '12px',
                        position: 'fixed',
                        bottom: '0',
                        left: '3.8%',
                        transform: 'translateX(-2.5%)',
                        zIndex: 1000,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        flexDirection: { xs: 'column', sm: 'row' },
                        padding: { xs: '8px', sm: '16px' },
                        '& > div': {
                            flex: { xs: '1 0 100%', sm: 'initial' },
                            marginBottom: { xs: '8px', sm: '0' }
                        }
                    }}
                >
                    {/* Toggle Button for Minimize/Maximize */}
                    <IconButton onClick={toggleBoxSize} sx={{ position: 'absolute', top: '-1px', right: '20px' }}>
                        {isMinimized ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                    </IconButton>

                    {!isMinimized && (
                        <>
                            {/* Content of the Box */}
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    textAlign: 'left',
                                    flex: { xs: '1 0 100%', sm: 'auto' },
                                }}
                            >
                                <Typography variant="body1" sx={{ color: '#333' }}>
                                    Invoice No: {invoiceNo}
                                </Typography>

                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#666' }}>
                                    TOTAL
                                </Typography>
                                <Typography variant="h4" fontWeight="bold" sx={{ color: '#4287f5' }}>
                                    Lkr. {grandTotal().toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </Typography>
                            </Box>

                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-end',
                                    textAlign: 'right',
                                    flex: { xs: '1 0 100%', sm: 'auto' },
                                }}
                            >
                                <Typography variant="subtitle1" sx={{ color: '#333' }}>
                                    Total: Lkr. {calculateTotalAmount().toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </Typography>
                                <Typography variant="subtitle1" sx={{ color: '#333' }}>
                                    Discount: Lkr. {Number(TotalDiscount()).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </Typography>
                                <Typography variant="subtitle1" sx={{ color: '#333' }}>
                                    Tax: Lkr. {Number(TotalTax()).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </Typography>
                                <Typography variant="subtitle1" sx={{ color: '#333' }}>
                                    {term !== 'due on receipt' ? `Pay amount: Lkr. ${formattedPayAmount}` : ''}
                                </Typography>

                                <Typography variant="subtitle1" sx={{ color: '#4287f5', fontWeight: 'bold' }}>
                                    {term === 'due on receipt' ? 'Sub Total:' : 'Balance Due:'} Lkr. {calculateBalancedue().toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </Typography>
                            </Box>

                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-end',
                                    textAlign: 'right',
                                    flex: { xs: '1 0 100%', sm: 'auto' },
                                }}
                            >
                                <TextField
                                    label="Discount (%)"
                                    type="number"
                                    value={discount}
                                    onChange={(e) => setDiscount(e.target.value)}
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    sx={{
                                        width: '100%',
                                        mb: 2,
                                    }}
                                />
                                <TextField
                                    label="Pay Amount"
                                    type="number"
                                    value={payamount}
                                    onChange={(e) => setPayamount(e.target.value)}
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    sx={{
                                        width: '100%',
                                    }}
                                />
                            </Box>

                            <Box display="flex" alignItems="center" flexDirection={{ xs: 'column', sm: 'row' }} sx={{ mt: { xs: '8px', sm: '0' } }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSubmitAndPrint}
                                    disabled={loading}
                                    sx={{
                                        backgroundColor: '#4287f5',
                                        color: 'white',
                                        borderRadius: '20px',
                                        padding: '8px 24px',
                                        '&:hover': {
                                            backgroundColor: '#356bc4',
                                        },
                                        marginBottom: { xs: '8px', sm: '0' }
                                    }}
                                >
                                    Submit
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={handleSubmit}
                                    sx={{
                                        backgroundColor: '#f54949',
                                        color: 'white',
                                        borderRadius: '20px',
                                        padding: '8px 24px',
                                        ml: { xs: 0, sm: 2 },
                                        '&:hover': {
                                            backgroundColor: '#d93e3e',
                                        },
                                    }}
                                >
                                    Submit and Send
                                </Button>
                            </Box>
                        </>
                    )}
                </Box>

                {loading && (
                    <Box sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100vw',
                        height: '100vh',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        bgcolor: 'white',
                        zIndex: 9999
                    }}>
                        <l-trio
                            size="50"
                            speed="1.3"
                            color="red"
                        ></l-trio>
                    </Box>
                )}
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={handleSnackbarClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                >
                    <Alert
                        onClose={handleSnackbarClose}
                        severity={snackbarSeverity}
                        sx={{
                            width: '100%',

                        }}
                    >
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </Box>
        </Box>
    );
};

export default FormWithTable;
