import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    CircularProgress,
    Typography,
    Collapse,
    IconButton,
    TablePagination,
    Snackbar,
    Checkbox,
    Menu,
    MenuItem,
    Dialog,
    DialogTitle,
    DialogActions,
    Button
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const Stocktable = () => {
    const [fetchedData, setFetchedData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openRow, setOpenRow] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [deleteMessage, setDeleteMessage] = useState('');
    const [alertOpen, setAlertOpen] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axios.get('https://admin.bizstor.lk/api/get-stock-data');
            setFetchedData(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleRowClick = (index) => {
        setOpenRow(openRow === index ? null : index);
    };

    const handleRowSelect = (event, stockId) => {
        const selectedIndex = selectedRows.indexOf(stockId);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedRows, stockId);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedRows.slice(1));
        } else if (selectedIndex === selectedRows.length - 1) {
            newSelected = newSelected.concat(selectedRows.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedRows.slice(0, selectedIndex),
                selectedRows.slice(selectedIndex + 1),
            );
        }

        setSelectedRows(newSelected);
    };

    const isSelected = (stockId) => selectedRows.indexOf(stockId) !== -1;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDelete = async () => {
        setLoading(true);
        try {
            const promises = selectedRows.map(async (id) => {
                const response = await axios.delete(`https://admin.bizstor.lk/api/delete-stock-data/${id}`);
                return response.status === 200;
            });
            const results = await Promise.all(promises);

            if (results.every((result) => result)) {
                setFetchedData(fetchedData.filter(stock => !selectedRows.includes(stock.id)));
                setDeleteMessage('Selected stocks deleted successfully.');
                setAlertOpen(true);
                setSelectedRows([]);
                setDeleteConfirmationOpen(false);
            } else {
                setDeleteMessage('Failed to delete selected stocks.');
            }
        } catch (error) {
            console.error('Error deleting data:', error);
            setDeleteMessage('Error deleting stocks.');
        } finally {
            setLoading(false);
        }
    };

    const clearDeleteMessage = () => {
        setDeleteMessage('');
    };

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleOpenDeleteConfirmation = () => {
        setDeleteConfirmationOpen(true);
    };

    const handleCloseDeleteConfirmation = () => {
        setDeleteConfirmationOpen(false);
    };

    const calculateTotalQuantity = (items) => {
        return items.reduce((total, item) => total + item.quantity, 0);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                maxWidth: '1200px',
                margin: '0 auto',
                boxShadow: 1,
                padding: '20px',
                borderRadius: '5px',
                bgcolor: 'background.paper',
                marginTop: '30px',
                position: 'relative'
            }}
        >
            <Typography variant="h4" component="h1" gutterBottom>
                Stock 
            </Typography>
            <Snackbar
                open={alertOpen}
                autoHideDuration={6000}
                onClose={() => setAlertOpen(false)}
                message="Deleted successfully!"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            />
            <Dialog
                open={deleteConfirmationOpen}
                onClose={handleCloseDeleteConfirmation}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Are you sure you want to delete selected stocks?"}</DialogTitle>
                <DialogActions>
                    <Button onClick={handleCloseDeleteConfirmation} color="primary">
                        No
                    </Button>
                    <Button onClick={handleDelete} color="primary" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
                <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={handleMenuOpen}
                    disabled={selectedRows.length === 0}
                >
                    <DeleteIcon />
                </IconButton>
                <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                >
                    <MenuItem onClick={handleOpenDeleteConfirmation}>Delete Selected Stocks</MenuItem>
                </Menu>
            </Box>
            <TableContainer component={Paper} style={{ marginTop: '20px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell padding="checkbox">
                                <Checkbox
                                    indeterminate={selectedRows.length > 0 && selectedRows.length < fetchedData.length}
                                    checked={selectedRows.length === fetchedData.length}
                                    onChange={() => {
                                        if (selectedRows.length === fetchedData.length) {
                                            setSelectedRows([]);
                                        } else {
                                            setSelectedRows(fetchedData.map((stock) => stock.id));
                                        }
                                    }}
                                />
                            </TableCell>
                            <TableCell><b>Branch</b></TableCell>
                            <TableCell><b>Quantity</b></TableCell>
                            <TableCell><b>Date</b></TableCell>
                            <TableCell><b>Created At</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <TableRow>
                                <TableCell colSpan={4}>
                                <Box sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100vw',
                        height: '100vh',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        bgcolor: 'white', 
                        zIndex: 9999
                    }}>
                        <l-trio
                            size="50"
                            speed="1.3"
                            color="red"
                        ></l-trio>
                    </Box>
                                </TableCell>
                            </TableRow>
                        ) : (
                            fetchedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((stock, index) => (
                                <React.Fragment key={index}>
                                    <TableRow
                                        hover
                                        onClick={() => handleRowClick(index)}
                                        role="checkbox"
                                        aria-checked={isSelected(stock.id)}
                                        tabIndex={-1}
                                        selected={isSelected(stock.id)}
                                        style={{ backgroundColor: openRow === index ? '#f0f0f0' : 'inherit' }}
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={isSelected(stock.id)}
                                                onChange={(event) => handleRowSelect(event, stock.id)}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body2" fontWeight="bold">
                                                {stock.branch}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body2" fontWeight="bold">
                                            {calculateTotalQuantity(stock.items)}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body2" fontWeight="bold">
                                                {stock.date}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body2" fontWeight="bold">
                                                {new Date(stock.created_at).toLocaleDateString()}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                            <Collapse in={openRow === index} timeout="auto" unmountOnExit>
                                                <Box margin={1} padding={2} bgcolor="#f9f9f9" borderRadius={4} boxShadow={3}>
                                                    <Typography variant="h6" gutterBottom component="div" color="primary">
                                                        Stock Details
                                                    </Typography>
                                                    <Table size="small" aria-label="stock details">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell><b>Product</b></TableCell>
                                                                <TableCell><b>Status</b></TableCell>
                                                                <TableCell><b>Quantity</b></TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {stock.items.map((item, idx) => (
                                                                <TableRow key={idx}>
                                                                    <TableCell>{item.product}</TableCell>
                                                                    <TableCell>{item.status}</TableCell>
                                                                    <TableCell>{item.quantity}</TableCell>
                                                                </TableRow>
                                                            ))}
                                                            <TableRow>
                                                                <TableCell colSpan={2} align="right">
                                                                    <Typography variant="body1" fontWeight={700}>Total Quantity</Typography>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Typography variant="body1" fontWeight={700}>{calculateTotalQuantity(stock.items)}</Typography>
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </Box>
                                            </Collapse>
                                        </TableCell>
                                    </TableRow>

                                </React.Fragment>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={fetchedData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Box>
    );
};

export default Stocktable;
