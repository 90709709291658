import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    IconButton,
    Box,
    Typography,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Snackbar,
    Alert,
    TablePagination
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete'; // Import delete icon from Material-UI Icons

const ProductTest = () => {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true); // Loading state for data fetching and delete operation

    // Pagination state
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    // Confirmation dialog state
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [selectedProductId, setSelectedProductId] = useState(null);

    useEffect(() => {
        fetchProducts();
    }, []); // Fetch products on component mount

    const fetchProducts = () => {
        setLoading(true); // Set loading to true before fetching data

        axios.get('https://admin.bizstor.lk/api/get-product-data')
            .then(response => {
                setProducts(response.data.products);
                setLoading(false); // Set loading to false after data is fetched
            })
            .catch(error => {
                console.error('Error fetching product data:', error);
                setLoading(false); // Set loading to false on error
            });
    };

    const handleDelete = (productId) => {
        setSelectedProductId(productId); // Set the selected product ID for deletion
        setOpenConfirmation(true); // Open confirmation dialog
    };

    const confirmDelete = () => {
        setLoading(true); // Set loading to true before delete operation

        // Perform delete operation
        axios.delete(`https://admin.bizstor.lk/api/delete-product-data/${selectedProductId}`)
            .then(response => {
                // Filter out the deleted product from state
                setProducts(products.filter(product => product.id !== selectedProductId));
                setLoading(false); // Set loading to false after deletion

                // Show success alert
                setOpenConfirmation(false); // Close confirmation dialog
                setAlertMessage('Product deleted successfully.'); // Set alert message
                setOpenAlert(true); // Open alert
            })
            .catch(error => {
                console.error('Error deleting product:', error);
                setLoading(false); // Set loading to false on error

                // Show error alert
                setOpenConfirmation(false); // Close confirmation dialog
                setAlertMessage('Failed to delete product.'); // Set alert message
                setOpenAlert(true); // Open alert
            });
    };

    // Alert state
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    // Pagination event handlers
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                maxWidth: '1200px',
                margin: '0 auto',
                boxShadow: 1,
                padding: '20px',
                borderRadius: '5px',
                bgcolor: 'background.paper',
                marginTop: '30px',
                position: 'relative'
            }}
        >
            <Typography variant="h5">
                All Products
            </Typography>
            <hr style={{ borderTop: '1px solid orange', width: '100%', margin: '10px 0' }} />

            {loading && (
                <Box sx={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: 'white',
                    zIndex: 9999
                }}>
                    <CircularProgress />
                </Box>
            )}

            {!loading && (
                <>
                    <TableContainer component={Paper} sx={{ marginTop: '20px' }} >
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ fontWeight: 'bold' }}>ID</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>Product</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>Price</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>Action</TableCell> {/* New column for delete action */}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {products
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map(product => (
                                        <TableRow key={product.id}>
                                            <TableCell>{product.id}</TableCell>
                                            <TableCell>{product.product}</TableCell>
                                            <TableCell>Rs.{product.price}.00</TableCell>
                                            <TableCell>
                                                <IconButton onClick={() => handleDelete(product.id)} aria-label="delete"
                                                    sx={{
                                                        '&:hover': {
                                                            color: 'red',
                                                        },
                                                    }}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 15]}
                        component="div"
                        count={products.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </>
            )}

            {/* Delete confirmation dialog */}
            <Dialog
                open={openConfirmation}
                onClose={() => setOpenConfirmation(false)}
                aria-labelledby="delete-dialog-title"
                fullWidth
            >
                <DialogTitle id="delete-dialog-title">Confirm Delete</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        Are you sure you want to delete this product?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenConfirmation(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={confirmDelete} color="error">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Alert for success or failure */}
            <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert} severity={alertMessage.includes('successfully') ? 'success' : 'error'}>
                    {alertMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default ProductTest;
