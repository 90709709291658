export function printContent(
  groupedOrders, totalPriceAmount, totalDiscountAmount, totalTaxAmount, grandTotal,
  groupedDamages, damageTotalQuantity, damageTotalPrice,
  groupedExpenses, expensesTotalAmount,
  groupedStocks, stockTotalFilled, stockTotalEmpty,
  selectedMonthName, user
) {
  const profit = grandTotal - expensesTotalAmount - damageTotalPrice;
  const printWindow = window.open('', '', 'width=800,height=600');

  // Write the content to the new window
  printWindow.document.write('<html><head><title>Print</title>');
  printWindow.document.write('<style>');
  printWindow.document.write('body { font-family: Arial, sans-serif; line-height: 1.6; margin: 20px; }');
  printWindow.document.write('h2 { text-align: center; }');
  printWindow.document.write('h5 { border-bottom: 2px solid black; padding-bottom: 5px; text-align: center;}');
  printWindow.document.write('h4 { text-align: center;}');
  printWindow.document.write('table { width: 100%; border-collapse: collapse; margin-bottom: 20px; }');
  printWindow.document.write('th, td {  padding: 12px; text-align: left; }');
  printWindow.document.write('th { font-weight: bold; }');
  printWindow.document.write('div { margin-bottom: 20px; }');
  printWindow.document.write('p { font-size: 16px; margin: 5px 0; }');
  printWindow.document.write('</style>');
  printWindow.document.write('</head><body>');

  // Add the selected month name to the printed content
  printWindow.document.write(`<h2>${user.companyname}</h2>`);
  printWindow.document.write(`<h4>Sales by product</h4>`);
  printWindow.document.write(`<h5>${selectedMonthName}</h5>`);
  printWindow.document.write('<table>');
  printWindow.document.write('<thead><tr><th>Product</th><th>Qty</th><th style="text-align: right;">TOTAL</th></tr></thead><tbody>');
  Object.values(groupedOrders).forEach(({ product, quantity, total }) => {
    printWindow.document.write(`<tr><td>${product}</td><td>${quantity}</td><td style="text-align: right;">${total.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td></tr>`);
  });
  printWindow.document.write('</tbody></table>');

  printWindow.document.write(`<p style="display:flex; justify-content:space-between; font-weight:bold; border-bottom:1px solid black;border-top:1px solid black"><span>TOTAL</span><span> Lkr. ${grandTotal.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></p>`);
  printWindow.document.write('</div>');

  printWindow.document.write('</body></html>');

  printWindow.document.close();
  printWindow.focus(); 
  printWindow.print();
}
