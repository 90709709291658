export const printData = ({ 
    filteredData, 
    fullname, 
    totalQuantity, 
    totalAmount,
    selectedMonth

 }) => {
    const printWindow = window.open('', '', 'height=600,width=800');
    printWindow.document.write('<html><head><title>Print</title>');
    printWindow.document.write('<style>');
    printWindow.document.write('body { font-family: Arial, sans-serif; }');
    printWindow.document.write('h1 { text-align: center; }');
    printWindow.document.write('table { width: 100%; border-collapse: collapse; }');
    printWindow.document.write('th, td { border: 1px solid #ddd; padding: 8px; }');
    printWindow.document.write('th { background-color: #f2f2f2; text-align: left; }');
    printWindow.document.write('tr:nth-child(even) { background-color: #f9f9f9; }');
    printWindow.document.write('tr:hover { background-color: #ddd; }');
    printWindow.document.write('</style>');
    printWindow.document.write('</head><body>');
    printWindow.document.write(`<h1>Order History</h1>`);
    printWindow.document.write(`<p style="text-align: center">${selectedMonth}</p>`); 
    printWindow.document.write(`<p><b>Customer:</b> ${fullname}</p>`);
    printWindow.document.write(`<p><b>Total Qty:</b> ${totalQuantity}</p>`);
    printWindow.document.write(`<p><b>Total Amount (excluding tax):</b> Lkr.${(totalAmount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>`);
    printWindow.document.write(`<p><b>Total Amount (including tax):</b> Lkr.${totalAmount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>`);
    printWindow.document.write('<table border="1" cellpadding="5" cellspacing="0">');
    printWindow.document.write('<tr><th>Product</th><th>Quantity</th><th>Rate</th><th>Price</th><th>Tax</th><th>Discount</th><th>Total Price (After Discount & Tax)</th><th>Date</th></tr>');

    filteredData.forEach(order => {
        const discountPercentage = order.discount || 0; // Default to 0 if discount is null or undefined
        order.items.forEach(item => {
            const itemTotal = item.price * (1 + (item.tax / 100 || 0)); // Price including tax
            const discountAmount = (itemTotal * discountPercentage) / 100; // Calculate discount
            const finalAmount = itemTotal - discountAmount; // Final amount after discount

            printWindow.document.write(
                `<tr>
                    <td>${item.product}</td>
                    <td>${item.quantity}</td>
                    <td>${item.rate}</td>
                    <td>${item.price.toFixed(2)}</td>
                    <td>${item.tax || 'N/A'}%</td>
                    <td>${discountPercentage || 'N/A'}%</td>
                    <td>${finalAmount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                    <td>${order.invoicedate}</td>
                </tr>`
            );
        });
    });

    printWindow.document.write('</table>');
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.print();
};
