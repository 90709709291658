import { useState, useEffect } from 'react';
import axios from 'axios';

const Anualincome = ({ onTotalAmountChange }) => {
    const [user, setUser] = useState({});
    const [token, setToken] = useState(() => localStorage.getItem('token') || null);

    const [fetchedData, setFetchedData] = useState([]);

    useEffect(() => {
        if (token) {
            localStorage.setItem('token', token);
        }
    }, [token]);

    useEffect(() => {
        const fetchProfile = async () => {
            if (!token) return; // Exit if no token

            try {
                const response = await axios.get('https://admin.bizstor.lk/api/profile', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setUser(response.data);
            } catch (error) {
                console.log('');
            }
        };

        fetchProfile();
    }, [token]);

    useEffect(() => {
        if (user.id) {
            fetchData();
            
        }
    }, [user.id]);

    const fetchData = async () => {
        try {
            const response = await axios.get('https://admin.bizstor.lk/api/get-data');
            if (user.id) {
                console.log('console.log("");:', user.id);
                const Order = response.data.filter(order =>
                    order.usertoken.toLowerCase() === user.id.toString().toLowerCase()
                );
                setFetchedData(Order);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        const currentYear = new Date().getFullYear();

        let total = 0;
        fetchedData.forEach(order => {
            const createdAt = new Date(order.created_at);
            if (createdAt.getFullYear() === currentYear) {
                let orderTotal = 0;
                order.items.forEach(item => {
                    const itemPrice = item.price;
                    const itemTax = item.tax || 0;
                    const itemTotal = itemPrice + (itemPrice * (itemTax / 100)); // Adding item-specific tax
                    orderTotal += itemTotal;
                });

                const discountPercentage = order.discount || 0;
                const discountAmount = (orderTotal * discountPercentage) / 100; // Calculate discount amount
                orderTotal -= discountAmount; // Apply discount

                total += orderTotal;
            }
        });

        if (onTotalAmountChange) {
            onTotalAmountChange(total);
        }
    }, [fetchedData, onTotalAmountChange]);

    return null; // No markup is returned
};

export default Anualincome;
