export const printTable = (selectedRowsData) => {
    // Create a new window
    const printWindow = window.open('', '_blank');

    // Create a simple HTML structure for printing with custom styles
    printWindow.document.write('<html><head><title>Inventory Details</title>');
    printWindow.document.write('<style>');
    printWindow.document.write(`
        body { 
            font-family: Arial, sans-serif; 
            margin: 20px; 
            color: #333; 
            background-color: #f9f9f9; 
        }
        table { 
            width: 100%; 
            border-collapse: collapse; 
            margin-bottom: 20px; 
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
        }
        th, td { 
            border: 1px solid #ddd; 
            padding: 12px 15px; 
            text-align: left; 
        }
        th { 
            background-color: #4CAF50; 
            color: white; 
            font-weight: bold; 
            text-transform: uppercase;
        }
        tr:nth-child(even) { 
            background-color: #f2f2f2; 
        }
        tr:hover { 
            background-color: #e6f7ff; 
            cursor: pointer; 
        }
        td[colspan="3"] { 
            border: none; 
            padding: 15px 0; 
            font-weight: bold; 
            color: #4CAF50;
            text-transform: uppercase;
        }
        td[colspan="3"] table { 
            margin-top: 10px; 
        }
        td[colspan="3"] th { 
            background-color: #4CAF50; 
            color: white; 
        }
        td[colspan="3"] td { 
            border: 1px solid #ddd; 
            padding: 8px; 
        }
    `);
    printWindow.document.write('</style>');
    printWindow.document.write('</head><body>');

    // Add the table with the selected data
    printWindow.document.write('<table>');
    printWindow.document.write('<thead>');
    printWindow.document.write('<tr><th>Date</th><th>Total Qty</th></tr>');
    printWindow.document.write('</thead>');
    printWindow.document.write('<tbody>');

    selectedRowsData.forEach((stock) => {
        const totalQuantity = calculateTotalQuantity(stock.items);

        printWindow.document.write('<tr>');
        printWindow.document.write(`<td>${stock.date}</td>`);
        printWindow.document.write(`<td>${totalQuantity}</td>`);
        printWindow.document.write('</tr>');

        // Add a row for items header
        printWindow.document.write('<tr><td colspan="3">Stock Details</td></tr>');

        // Add a table for the items within each stock
        printWindow.document.write('<tr><td colspan="3"><table style="width:100%; border-collapse: collapse;">');
        printWindow.document.write('<thead>');
        printWindow.document.write('<tr><th>Product</th><th>Status</th><th>Qty</th></tr>');
        printWindow.document.write('</thead>');
        printWindow.document.write('<tbody>');

        stock.items && stock.items.forEach(item => {
            printWindow.document.write('<tr>');
            printWindow.document.write(`<td>${item.product}</td>`);
            printWindow.document.write(`<td>${item.status}</td>`);
            printWindow.document.write(`<td>${item.quantity}</td>`);
            printWindow.document.write('</tr>');
        });

        printWindow.document.write('</tbody>');
        printWindow.document.write('</table></td></tr>');
    });

    printWindow.document.write('</tbody>');
    printWindow.document.write('</table>');
    printWindow.document.write('</body></html>');

    // Print and close the window
    printWindow.document.close();
    printWindow.print();
    printWindow.close();
};

// Example helper function - replace this with your actual implementation
const calculateTotalQuantity = (items) => {
    return items.reduce((acc, item) => acc + item.quantity, 0);
};
