import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box, TextField,
    Typography,
    Collapse,
    IconButton,
    TablePagination,
    Snackbar, Grid,
    Checkbox,
    Menu,
    MenuItem,
    Dialog,
    DialogTitle,
    DialogActions, Select,
    Button,
    FormControl,
    DialogContent,
    Toolbar,
    AppBar,
    Slide,
    ListSubheader,
    InputLabel,
    Alert,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PrintIcon from '@mui/icons-material/Print';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment } from '@mui/material';
import { printSelectedRows } from './Print/ExpensesPrint';
import { styled, keyframes } from '@mui/system';
import EditNoteIcon from '@mui/icons-material/EditNote';


const slideInFromLeft = keyframes`
  0% {
    transform: translateX(-20px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const AnimatedTypography = styled(Typography)(({ theme }) => ({
    animation: `${slideInFromLeft} 0.5s ease-out`,
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const ExpensesTable = () => {
    const [user, setUser] = useState({});
    const [token, setToken] = useState(() => localStorage.getItem('token') || null);

    const [fetchedData, setFetchedData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openRow, setOpenRow] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [deleteMessage, setDeleteMessage] = useState('');
    const [alertOpen, setAlertOpen] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [expenses, setExpenses] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const [rows, setRows] = useState([{ category: '', amount: '' }]);
    const [fullname, setFullName] = useState('');
    const [phone, setPhone] = useState('');
    const [type, setType] = useState('');
    const [description, setDescription] = useState('');
    const [date, setDate] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false); // State for Snackbar visibility
    const [snackbarMessage, setSnackbarMessage] = useState(''); // State for Snackbar message
    const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // State for Snackbar severity

    const selectedRowsData = fetchedData.filter((expense) => selectedRows.includes(expense.id));


    const handleSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    useEffect(() => {
        if (token) {
            localStorage.setItem('token', token);
        }
    }, [token]);

    useEffect(() => {
        const fetchProfile = async () => {
            if (!token) return; // Exit if no token

            try {
                const response = await axios.get('https://admin.bizstor.lk/api/profile', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setUser(response.data);
            } catch (error) {
                console.log('');
            }
        };

        fetchProfile();
    }, [token]);

    useEffect(() => {
        if (user.id) {
            fetchData();
        }
    }, [user.id]);

    const fetchData = () => {
        setLoading(true);

        axios.get('https://admin.bizstor.lk/api/get-expenses-category-data')
            .then(response => {
                setExpenses(response.data.expenses);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching product data:', error);
                setLoading(false);
            });

        axios.get('https://admin.bizstor.lk/api/get-customer-data')
            .then(response => {
                setCustomers(response.data.customers || []);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching customer data:', error);
                setLoading(false);
            });

        axios.get('https://admin.bizstor.lk/api/get-expense-data')
            .then(response => {
                if (user.id) {
                    const nisalOrders = response.data.filter(expense =>
                        expense.usertoken.toLowerCase() === user.id.toString().toLowerCase()
                    );
                    setFetchedData(nisalOrders);
                    setFilteredData(nisalOrders);
                }
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching customer data:', error);
                setLoading(false);
            });


        axios.get('https://admin.bizstor.lk/api/suppliers')
            .then(response => {
                setSuppliers(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching customer data:', error);
                setLoading(false);
            });
    };



    const handleAddRow = () => {
        // Create a new item with default values
        const newItem = {
            id: Date.now(), // Unique ID for the item
            category: '',    // Default product
            amount: 0,    // Default quantity
        };

        setSelectedExpenses((prevState) => ({
            ...prevState,
            items: [...prevState.items, newItem],
        }));
    };


    useEffect(() => {
        fetchData();
    }, []);

    const handleRowClick = (index) => {
        setOpenRow(openRow === index ? null : index);
    };

    const handleRowSelect = (event, expenseId) => {
        const selectedIndex = selectedRows.indexOf(expenseId);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedRows, expenseId);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedRows.slice(1));
        } else if (selectedIndex === selectedRows.length - 1) {
            newSelected = newSelected.concat(selectedRows.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedRows.slice(0, selectedIndex),
                selectedRows.slice(selectedIndex + 1),
            );
        }

        setSelectedRows(newSelected);
    };

    const handleExpensesChange = (field, value) => {
        setSelectedExpenses((prevExpenses) => ({
            ...prevExpenses,
            [field]: value,
        }));
    };

    const isSelected = (expenseId) => selectedRows.indexOf(expenseId) !== -1;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const calculateTotalAmount = (items) => {
        return items.reduce((total, item) => total + item.amount, 0);
    };

    const handleDelete = async () => {
        setLoading(true);
        try {
            const promises = selectedRows.map(async (id) => {
                const response = await axios.delete(`https://admin.bizstor.lk/api/delete-expense-data/${id}`);
                return response.status === 200;
            });
            const results = await Promise.all(promises);

            if (results.every((result) => result)) {
                setFetchedData(fetchedData.filter(expense => !selectedRows.includes(expense.id)));
                handleSnackbar('Selected expenses deleted successfully!', 'success');
                setSelectedRows([]);
                setDeleteConfirmationOpen(false);
                fetchData();
            } else {
                setDeleteMessage('Failed to delete selected expenses.');
            }
        } catch (error) {
            console.error('Error deleting data:', error);
            setDeleteMessage('Error deleting expenses.');
        } finally {
            setLoading(false);
        }
    };

    const clearDeleteMessage = () => {
        setDeleteMessage('');
    };

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleOpenDeleteConfirmation = () => {
        setDeleteConfirmationOpen(true);
    };

    const handleCloseDeleteConfirmation = () => {
        setDeleteConfirmationOpen(false);
    };

    const handleEditClick = (expense) => {
        setSelectedExpenses(expense);
        setEditDialogOpen(true);
    };

    const handleCloseEditDialog = () => {
        setEditDialogOpen(false);
        setSelectedExpenses(null);
    };

    const [selectedExpenses, setSelectedExpenses] = useState({
        fullname: '',
        phone: '',
        type: '',
        date: '',
        description: '',
        items: [], // Array to hold the stock items
    });

    const handleSave = async () => {
        setLoading(true);
        try {
            await axios.put(`https://admin.bizstor.lk/api/update-expense-data/${selectedExpenses.id}`, selectedExpenses);
            setAlertOpen(true);
            handleSnackbar('Expenses updated successfully!', 'success');

            fetchData(); // refresh the data after save
            handleCloseEditDialog();

        } catch (error) {
            console.error('Error updating data:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteRow = (index) => {
        // Filter out the item to be deleted based on the index
        const updatedItems = selectedExpenses.items.filter((_, i) => i !== index);

        setSelectedExpenses((prevState) => ({
            ...prevState,
            items: updatedItems,
        }));
    };

    const handleExpensesItemChange = (index, field, value) => {
        const updatedItems = selectedExpenses.items.map((item, itemIndex) => {
            if (index === itemIndex) {
                return {
                    ...item,
                    [field]: value,
                };
            }
            return item;
        });

        setSelectedExpenses((prevExpenses) => ({
            ...prevExpenses,
            items: updatedItems,
        }));
    };

    const handleSelectChange = (event) => {
        const selectedValue = event.target.value;

        // Find if the selected value is a customer or supplier
        const selectedCustomer = customers.find(customer => customer.firstname === selectedValue);
        const selectedSupplier = suppliers.find(supplier => supplier.supplier_name === selectedValue);

        if (selectedCustomer) {
            setFullName(selectedCustomer.firstname);
            setPhone(selectedCustomer.phone || ''); // Set default phone if available
        } else if (selectedSupplier) {
            setFullName(selectedSupplier.supplier_name);
            setPhone(''); // Supplier may not have a phone number
        }

        setType(selectedValue);
    };

    const [selectedMonth, setSelectedMonth] = useState('');

    const handleSearchChange = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchTerm(value);
        filterData(value, selectedMonth);
    };

    const handleMonthChange = (event) => {
        const value = event.target.value;
        setSelectedMonth(value);
        filterData(searchTerm, value);
    };

    const filterData = (term, month) => {
        const filtered = fetchedData.filter(expense => {
            const expenseDate = new Date(expense.date);
            const expenseMonth = expenseDate.toLocaleString('default', { month: 'long' }).toLowerCase();
            return (
                (term === '' ||
                    expense.id.toString().includes(term) ||
                    expense.fullname.toLowerCase().includes(term) ||
                    expense.date.includes(term)) &&
                (month === '' || expenseMonth === month.toLowerCase())
            );
        });
        setFilteredData(filtered);
    };
    return (
        <Box>
            <AnimatedTypography
                component="h1"
                sx={{
                    ml: 5,
                    mt: 3,
                    fontSize: '1rem',
                    color: 'secondary.main',
                    letterSpacing: '0.01em'
                }}
            >
                Dashboard / Report / Expenses 
            </AnimatedTypography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '95%',
                    maxWidth: 'auto',
                    margin: 'auto',
                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                    padding: '10px',
                    borderRadius: '5px',
                    bgcolor: 'background.paper',
                    marginTop: '30px',
                    position: 'relative'
                }}
            >
                <Typography variant="h4">
                    Expenses 
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' }, // Responsive direction
                        justifyContent: 'space-between', // Space between left and right groups
                        alignItems: 'center', // Align items vertically
                        p: 2, // Padding inside the box
                        gap: 2, // Space between sections

                    }}
                >
                    {/* Left side: Search fields */}
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', sm: 'row' }, // Stack vertically on small screens
                            gap: 1, // Space between the TextFields
                            width: '80%', // Ensure it takes full width on smaller screens
                            alignItems: 'center', // Align items vertically

                        }}
                    >
                        <TextField
                            variant="outlined"
                            margin="normal"
                            onChange={handleSearchChange}
                            value={searchTerm}
                            size="small"
                            placeholder="Search…"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{ width: '250px' }}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            onChange={handleSearchChange}
                            value={searchTerm}
                            size="small"
                            type="date"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{ width: '250px' }}
                        />

                        <Select
                            value={selectedMonth}
                            onChange={handleMonthChange}
                            displayEmpty
                            size="small"
                            sx={{
                                width: '250px',
                                height: '40px',
                                mt: 1
                            }}
                        >
                            <MenuItem value="">
                                <em>All Months</em>
                            </MenuItem>
                            <MenuItem value="January">January</MenuItem>
                            <MenuItem value="February">February</MenuItem>
                            <MenuItem value="March">March</MenuItem>
                            <MenuItem value="April">April</MenuItem>
                            <MenuItem value="May">May</MenuItem>
                            <MenuItem value="June">June</MenuItem>
                            <MenuItem value="July">July</MenuItem>
                            <MenuItem value="August">August</MenuItem>
                            <MenuItem value="September">September</MenuItem>
                            <MenuItem value="October">October</MenuItem>
                            <MenuItem value="November">November</MenuItem>
                            <MenuItem value="December">December</MenuItem>
                        </Select>
                    </Box>

                    {/* Right side: Icons */}
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 1, // Space between the icons
                        }}
                    >
                        <IconButton
                            aria-label="print"
                            onClick={() => printSelectedRows(selectedRowsData)}
                            disabled={selectedRows.length === 0}
                        >
                            <PrintIcon />
                        </IconButton>
                        <IconButton
                            aria-label="delete"
                            onClick={handleMenuOpen}
                            disabled={selectedRows.length === 0}
                        >
                            <DeleteIcon
                                sx={{
                                    '&:hover': {
                                        color: 'red',
                                    },
                                }}
                            />
                        </IconButton>
                        <Menu
                            id="long-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                        >
                            <MenuItem onClick={handleOpenDeleteConfirmation}>
                                Delete Selected Expenses
                            </MenuItem>
                        </Menu>
                    </Box>
                </Box>

                <Dialog
                    open={deleteConfirmationOpen}
                    onClose={handleCloseDeleteConfirmation}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Are you sure you want to delete selected expenses?"}</DialogTitle>
                    <DialogActions>
                        <Button onClick={handleCloseDeleteConfirmation} color="primary">
                            No
                        </Button>
                        <Button onClick={handleDelete} color="primary" autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>

                <TableContainer component={Paper} style={{ marginTop: '20px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        indeterminate={selectedRows.length > 0 && selectedRows.length < fetchedData.length}
                                        checked={selectedRows.length === fetchedData.length}
                                        onChange={() => {
                                            if (selectedRows.length === fetchedData.length) {
                                                setSelectedRows([]);
                                            } else {
                                                setSelectedRows(fetchedData.map((expense) => expense.id));
                                            }
                                        }}
                                    />
                                </TableCell>
                                <TableCell><b>ID</b></TableCell>
                                <TableCell><b>Full Name</b></TableCell>
                                <TableCell><b>Total Amount</b></TableCell>
                                <TableCell><b>Date</b></TableCell>
                                <TableCell><b>Action</b></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? (
                                <TableRow>
                                    <TableCell colSpan={8}>
                                        {loading && (
                                            <Box sx={{
                                                position: 'fixed',
                                                top: 0,
                                                left: 0,
                                                width: '100vw',
                                                height: '100vh',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                bgcolor: 'white',
                                                zIndex: 9999
                                            }}>
                                                <l-trio
                                                    size="50"
                                                    speed="1.3"
                                                    color="red"
                                                ></l-trio>
                                            </Box>
                                        )}
                                    </TableCell>
                                </TableRow>
                            ) : (
                                filteredData.sort((a, b) => new Date(b.id) - new Date(a.id))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((expense, index) => (
                                    <React.Fragment key={index}>
                                        <TableRow
                                            hover
                                            onClick={() => handleRowClick(index)}
                                            role="checkbox"
                                            aria-checked={isSelected(expense.id)}
                                            tabIndex={-1}
                                            selected={isSelected(expense.id)}
                                            style={{ backgroundColor: openRow === index ? '#f0f0f0' : 'inherit' }}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={isSelected(expense.id)}
                                                    onChange={(event) => handleRowSelect(event, expense.id)}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="body2" fontWeight="bold">
                                                    {expense.id}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="body2" fontWeight="bold">
                                                    {expense.fullname}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="body2" fontWeight="bold">
                                                    Rs.{calculateTotalAmount(expense.items).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="body2" fontWeight="bold">
                                                    {expense.date}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <IconButton onClick={() => handleEditClick(expense)}>
                                                    <EditNoteIcon
                                                        sx={{
                                                            '&:hover': {
                                                                color: 'black',
                                                            },
                                                        }}
                                                    />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                                                <Collapse in={openRow === index} timeout="auto" unmountOnExit>
                                                    <Box margin={1} padding={2} bgcolor="#f9f9f9" borderRadius={4} boxShadow={3}>
                                                        <Typography variant="h6" gutterBottom component="div" color="primary">
                                                            Expenses Details
                                                        </Typography>
                                                        <Table size="small" aria-label="details">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell><b>Category</b></TableCell>
                                                                    <TableCell><b>Amount</b></TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {expense.items.map((item, idx) => (
                                                                    <TableRow key={idx}>
                                                                        <TableCell>{item.category}</TableCell>
                                                                        <TableCell>Rs.{item.amount}.00</TableCell>
                                                                    </TableRow>
                                                                ))}
                                                                <TableRow>
                                                                    <TableCell colSpan={1} align="right">
                                                                        <Typography variant="body1" fontWeight={700}>Total Amount</Typography>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Typography variant="body1" fontWeight={700}>Rs.{calculateTotalAmount(expense.items)}.00</Typography>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                        <Typography variant="subtitle1" gutterBottom component="div" color="textSecondary" mt={2}>
                                                            Description
                                                        </Typography>
                                                        <Typography variant="body2" gutterBottom component="div">
                                                            {expense.description}
                                                        </Typography>
                                                    </Box>
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={filteredData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />

                <Dialog
                    open={editDialogOpen}
                    onClose={handleCloseEditDialog}
                    TransitionComponent={Transition}
                    fullWidth
                    maxWidth="md"
                >
                    <AppBar sx={{ position: 'relative', bgcolor: 'black' }}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={handleCloseEditDialog} aria-label="close">
                                <CloseIcon sx={{ color: 'white' }} />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1, color: 'white' }} variant="h6" component="div">
                                Edit Expenses
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        {selectedExpenses && (
                            <form>
                                <Grid>
                                    <Grid item xs={6}>
                                        <FormControl variant="outlined" fullWidth size="small">
                                            <InputLabel >Select Person</InputLabel>
                                            <Select
                                                value={type}
                                                onChange={handleSelectChange}
                                                label="Select Person"
                                                fullWidth
                                            >
                                                <ListSubheader>Customers</ListSubheader>
                                                {customers.length > 0 && customers.map(customer => (
                                                    <MenuItem key={customer.id} value={customer.firstname}>
                                                        {customer.firstname}
                                                    </MenuItem>
                                                ))}
                                                <ListSubheader>Suppliers</ListSubheader>
                                                {suppliers.map(supplier => (
                                                    <MenuItem key={supplier.id} value={supplier.supplier_name}>
                                                        {supplier.supplier_name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            margin="normal"
                                            label="Full Name"
                                            fullWidth
                                            size="small"
                                            value={fullname || selectedExpenses.fullname} // Use state variable for value
                                            onChange={(e) => handleExpensesChange('fullname', e.target.value)} // Preserve existing onChange handler
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            margin="normal"
                                            label="phone"
                                            fullWidth
                                            size="small"
                                            value={phone || selectedExpenses.phone}
                                            onChange={(e) => handleExpensesChange('phone', e.target.value)}
                                        />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <TextField
                                            margin="normal"
                                            label="Date"
                                            type="date"
                                            fullWidth
                                            size="small"
                                            value={selectedExpenses.date}
                                            onChange={(e) => handleExpensesChange('date', e.target.value)}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleAddRow}
                                        style={{ backgroundColor: 'black', color: 'white' }}
                                    >
                                        Add Line
                                    </Button>
                                </Box>
                                <Table size="small" aria-label="purchases">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Category</TableCell>
                                            <TableCell>Amount</TableCell>
                                            <TableCell>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {selectedExpenses.items && selectedExpenses.items.map((item, index) => (
                                            <TableRow key={item.id} sx={{ height: '40px' }}> {/* Adjust the height as needed */}
                                                <TableCell sx={{ padding: '8px' }}> {/* Adjust the padding as needed */}
                                                    <FormControl variant="outlined" fullWidth size="small">
                                                        <Select
                                                            value={item.category}
                                                            onChange={(e) => handleExpensesItemChange(index, 'category', e.target.value)}
                                                        >
                                                            {expenses.length > 0 && expenses.map(expense => (
                                                                <MenuItem key={expense.id} value={expense.expenses}>
                                                                    {expense.expenses}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </TableCell>
                                                <TableCell sx={{ padding: '8px' }}> {/* Adjust the padding as needed */}
                                                    <TextField
                                                        value={item.amount}
                                                        onChange={(e) => handleExpensesItemChange(index, 'amount', e.target.value)}
                                                        type="number"
                                                        size="small"
                                                    />
                                                </TableCell>
                                                <TableCell sx={{ padding: '8px' }}> {/* Adjust the padding as needed */}
                                                    <IconButton
                                                        onClick={() => handleDeleteRow(index)}
                                                        aria-label="delete"
                                                        color="inherit"
                                                        size="small"
                                                    >
                                                        <DeleteIcon
                                                            sx={{
                                                                '&:hover': {
                                                                    color: 'red',
                                                                },
                                                                fontSize: '20px', // Adjust the icon size as needed
                                                            }}
                                                        />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>

                                        ))}
                                        <Grid item xs={6}>
                                            <TextField
                                                margin="normal"
                                                label="Description"
                                                fullWidth
                                                multiline
                                                rows={4} // Adjust this number to set the height
                                                size="small"
                                                value={selectedExpenses.description}
                                                onChange={(e) => handleExpensesChange('description', e.target.value)}
                                            />
                                        </Grid>

                                    </TableBody>
                                </Table>
                            </form>
                        )}
                    </DialogContent>
                    <DialogActions>

                        <Button onClick={handleSave} color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>


                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={handleSnackbarClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                >
                    <Alert
                        onClose={handleSnackbarClose}
                        severity={snackbarSeverity}
                        sx={{
                            width: '100%',

                        }}
                    >
                        {snackbarMessage}
                    </Alert>
                </Snackbar>

            </Box>
        </Box>
    );
};

export default ExpensesTable;
