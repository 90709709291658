// Helper function to calculate the total amount
const calculateTotalAmount = (items) => {
  return items.reduce((sum, item) => sum + item.amount, 0);
};

// Helper function to calculate the total quantity
const calculateTotalQuantity = (items) => {
  return items.reduce((sum, item) => sum + item.quantity, 0);
};

export const printSelectedRows = (selectedRowsData) => {
  // Create a new window for printing
  const printWindow = window.open('', '_blank');

  // Set up the document structure and styling
  printWindow.document.write('<html><head><title>Expenses Details</title>');
  printWindow.document.write('<style>');
  printWindow.document.write(`
    body { 
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; 
      margin: 20px; 
      color: #333; 
      background-color: #fafafa;
    }
    table { 
      width: 100%; 
      border-collapse: collapse; 
      margin-bottom: 20px; 
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    }
    th, td { 
      border: 1px solid #ddd; 
      padding: 12px 15px; 
      text-align: left; 
    }
    th { 
      background-color: #4CAF50; 
      color: white; 
      font-weight: bold; 
      text-transform: uppercase;
    }
    tr:nth-child(even) { 
      background-color: #f2f2f2; 
    }
    tr:hover { 
      background-color: #e6f7ff; 
      cursor: pointer; 
    }
    td[colspan="4"] { 
      border: none; 
      padding: 15px 0; 
      font-weight: bold; 
      color: #4CAF50;
      text-transform: uppercase;
    }
    td[colspan="4"] table { 
      margin-top: 10px; 
    }
    td[colspan="4"] th { 
      background-color: #4CAF50; 
      color: white; 
    }
    td[colspan="4"] td { 
      border: 1px solid #ddd; 
      padding: 8px; 
    }
    .total-row { 
      font-weight: bold; 
      background-color: #e8f5e9; 
    }
  `);
  printWindow.document.write('</style>');
  printWindow.document.write('</head><body>');

  // Add the main table structure for selected expenses
  printWindow.document.write('<table>');
  printWindow.document.write('<thead>');
  printWindow.document.write('<tr><th>ID</th><th>Full Name</th><th>Total Amount</th><th>Date</th></tr>');
  printWindow.document.write('</thead>');
  printWindow.document.write('<tbody>');

  selectedRowsData.forEach((row) => {
    printWindow.document.write('<tr>');
    printWindow.document.write(`<td>${row.id}</td>`);
    printWindow.document.write(`<td>${row.fullname}</td>`);
    printWindow.document.write(`<td>Rs.${calculateTotalAmount(row.items)}.00</td>`);
    printWindow.document.write(`<td>${row.date}</td>`);
    printWindow.document.write('</tr>');

    // Add a row for items header
    printWindow.document.write('<tr><td colspan="4">Items Details</td></tr>');

    // Add a nested table for items within each expense
    printWindow.document.write('<tr><td colspan="4"><table style="width:100%; border-collapse: collapse;">');
    printWindow.document.write('<thead>');
    printWindow.document.write('<tr><th>Category</th><th>Amount</th></tr>');
    printWindow.document.write('</thead>');
    printWindow.document.write('<tbody>');

    row.items.forEach(item => {
      printWindow.document.write('<tr>');
      printWindow.document.write(`<td>${item.category}</td>`);
      printWindow.document.write(`<td>Rs.${item.amount}.00</td>`);
      printWindow.document.write('</tr>');
    });

    // Add a row to display the total amount for each expense
    printWindow.document.write('<tr class="total-row">');
    printWindow.document.write('<td colspan="1">Total</td>');
    printWindow.document.write(`<td colspan="1">Rs.${calculateTotalAmount(row.items)}.00</td>`);
    printWindow.document.write('</tr>');

    printWindow.document.write('</tbody>');
    printWindow.document.write('</table></td></tr>');
  });

  printWindow.document.write('</tbody>');
  printWindow.document.write('</table>');
  printWindow.document.write('</body></html>');

  // Print and close the window
  printWindow.document.close();
  printWindow.print();
  printWindow.close();
};
