import { useState, useEffect } from 'react';
import axios from 'axios';

const Damage = ({ onTotalDamagesChange }) => {
    const [user, setUser] = useState({});
    const [token, setToken] = useState(() => localStorage.getItem('token') || null);
    const [fetchedData, setFetchedData] = useState([]);
    const [totalDamage, setTotalDamage] = useState(0);

    useEffect(() => {
        if (token) {
            localStorage.setItem('token', token);
        }
    }, [token]);

    useEffect(() => {
        const fetchProfile = async () => {
            if (!token) return; // Exit if no token

            try {
                const response = await axios.get('https://admin.bizstor.lk/api/profile', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setUser(response.data);
            } catch (error) {
                console.log('');
            }
        };

        fetchProfile();
    }, [token]);

    useEffect(() => {
        if (user.id) {
            fetchData();
        }
    }, [user.id]);

    const fetchData = async () => {
        try {
            const response = await axios.get('https://admin.bizstor.lk/api/get-damage-data');
            if (user.id) {
                console.log('console.log("");:', user.id);
                const damages = response.data.filter(damage =>
                    damage.usertoken.toLowerCase() === user.id.toString().toLowerCase()
                );
                setFetchedData(damages);
            }
        } catch (error) {
            console.error('Error fetching damage data:', error);
        }
    };

    useEffect(() => {
        if (fetchedData.length > 0) {
            const groupedDamages = fetchedData.reduce((acc, damage) => {
                damage.items.forEach(item => {
                    if (damage.type === 'money back') {
                        const key = `${item.product}-${damage.type}`;
                        if (!acc[key]) {
                            acc[key] = {
                                product: item.product,
                                rate: item.rate,
                                quantity: 0,
                                totalPrice: 0,
                                type: damage.type
                            };
                        }
                        acc[key].quantity += item.quantity;
                        acc[key].totalPrice += item.price;
                    }
                });
                return acc;
            }, {});

            const { damageTotalPrice } = Object.values(groupedDamages).reduce(
                (totals, { totalPrice, type }) => {
                    if (type === 'money back') {
                        totals.damageTotalPrice += totalPrice;
                    }
                    return totals;
                },
                {
                    damageTotalPrice: 0,
                }
            );

            setTotalDamage(damageTotalPrice);
            if (onTotalDamagesChange) {
                onTotalDamagesChange(damageTotalPrice);
            }
        }
    }, [fetchedData, onTotalDamagesChange]);

    // Return null to render nothing in the component
    return null;
};

export default Damage;
