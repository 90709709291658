import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    Typography,
    Collapse,
    IconButton,
    TablePagination,
    Snackbar, ListSubheader,
    Checkbox,
    Menu,
    MenuItem,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    Select,
    FormControl,
    InputLabel,
    Slide,
    AppBar,
    Toolbar,
    Grid,
    Alert,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import PaymentsIcon from '@mui/icons-material/Payments';
import MakePayment from '../../Popupdialogbox/Makepayment';
import PrintIcon from '@mui/icons-material/Print';
import EditNoteIcon from '@mui/icons-material/EditNote';
import CloseIcon from '@mui/icons-material/Close';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { quantum, trio } from 'ldrs';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment } from '@mui/material';
//import { printSelectedRows } from './Print/InvoicePrint';
import { styled, keyframes } from '@mui/system';
import logo from '../logo/logo.jpg';
import addressIcon from '../logo/icon/gps.png';
import emailIcon from '../logo/icon/e-mail.png';
import webIcon from '../logo/icon/world-wide-web.png';
import phoneIcon from '../logo/icon/phone.png';


const slideInFromLeft = keyframes`
  0% {
    transform: translateX(-20px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const AnimatedTypography = styled(Typography)(({ theme }) => ({
    animation: `${slideInFromLeft} 0.5s ease-out`,
}));

quantum.register();
trio.register();

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const InvoiceTable = () => {
    const [user, setUser] = useState({});
    const [token, setToken] = useState(() => localStorage.getItem('token') || null);

    const [fetchedData, setFetchedData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    const [loading, setLoading] = useState(false);
    const [openRow, setOpenRow] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [deleteMessage, setDeleteMessage] = useState('');
    const [alertOpen, setAlertOpen] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const [paymentDialogOpen, setPaymentDialogOpen] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [payments, setPayments] = useState([]);
    const [open, setOpen] = useState(false);

    const [editOrder, setEditOrder] = useState(null);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [products, setProducts] = useState([]);
    const productPrices = {};
    const [customers, setCustomers] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false); // State for Snackbar visibility
    const [snackbarMessage, setSnackbarMessage] = useState(''); // State for Snackbar message
    const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // State for Snackbar severity
    const [name, setName] = useState('');
    const [companyname, setCompanyName] = useState('');
    const [address, setAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [companyemail, setCompanyEmail] = useState('');
    const [password, setPassword] = useState('');
    const [image, setImage] = useState(null); // State to handle image upload

    const selectedRowsData = fetchedData.filter((order) => selectedRows.includes(order.id));
    const handleClickOpen = () => setOpen(true);

    const handleSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };


    useEffect(() => {
        if (token) {
            localStorage.setItem('token', token);
        }
    }, [token]);

    useEffect(() => {
        const fetchProfile = async () => {
            if (!token) return; // Exit if no token

            try {
                const response = await axios.get('https://admin.bizstor.lk/api/profile', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setUser(response.data);
            } catch (error) {
                console.log('');
            }
        };

        fetchProfile();
    }, [token]);

    useEffect(() => {
        if (user.id) {
            fetchData();
        }
    }, [user.id]);

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axios.get('https://admin.bizstor.lk/api/get-data');

            // Ensure user.id exists before logging and filtering
            if (user.id) {

                // Filter orders based on user.id
                const nisalOrders = response.data.filter(order =>
                    order.usertoken.toLowerCase() === user.id.toString().toLowerCase()
                );

                setFetchedData(nisalOrders);
                setFilteredData(nisalOrders);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };





    useEffect(() => {
        fetchPaymentData();
    }, []);

    const fetchPaymentData = async () => {
        setLoading(true);
        try {
            const response = await axios.get('https://admin.bizstor.lk/api/get-payment-data');
            setPayments(response.data);
        } catch (error) {
            console.error('Error fetching payment data:', error);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        fetchData();
    }, []);

    const handleRowClick = (index) => {
        setOpenRow(openRow === index ? null : index);
    };

    const handleRowSelect = (event, orderId) => {
        const selectedIndex = selectedRows.indexOf(orderId);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedRows, orderId);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedRows.slice(1));
        } else if (selectedIndex === selectedRows.length - 1) {
            newSelected = newSelected.concat(selectedRows.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedRows.slice(0, selectedIndex),
                selectedRows.slice(selectedIndex + 1),
            );
        }

        setSelectedRows(newSelected);
    };

    const isSelected = (orderId) => selectedRows.indexOf(orderId) !== -1;


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const calculateTotalAmount = (items, discount) => {
        // Calculate the total amount considering item-specific taxes
        const totalAmount = items.reduce((acc, item) => {
            const itemTotal = item.price + (item.price * (item.tax / 100));
            return acc + itemTotal;
        }, 0);

        // Apply discount
        const discountedAmount = totalAmount - (totalAmount * (discount / 100));

        // Calculate total amount
        return discountedAmount;
    };

    const calculateDiscountTotalAmount = (items, discount) => {
        // Calculate the total amount considering item-specific taxes
        const totalAmount = items.reduce((acc, item) => {
            const itemTotal = item.price * (discount / 100);
            return acc + itemTotal;
        }, 0);

        // Apply discount
        const discountedTotalAmount = totalAmount - (totalAmount * (discount / 100));

        // Calculate total amount
        return discountedTotalAmount;
    };


    const calculateOrderTotalPayments = (orderId) => {
        // Calculate total payments made for this order
        return payments
            .filter(payment => payment.payment_id === orderId)
            .reduce((total, payment) => total + payment.amount, 0);
    };

    const calculateTotalTaxAmount = (items) => {
        // Calculate the total amount considering item-specific taxes
        const totalAmount = items.reduce((acc, item) => {
            const itemTotal = item.price * (item.tax / 100);
            return acc + itemTotal;
        }, 0);

        return totalAmount;
    };

    const handleDelete = async () => {
        setLoading(true);
        try {
            // Delete selected rows
            const promises = selectedRows.map(async (id) => {
                const response = await axios.delete(`https://admin.bizstor.lk/api/delete-data/${id}`);
                return response.status === 200;
            });
            const results = await Promise.all(promises);

            if (results.every((result) => result)) {
                setFetchedData(fetchedData.filter(order => !selectedRows.includes(order.id)));
                handleSnackbar('Selected invoice deleted successfully!', 'success');
                setSelectedRows([]); // Clear selected rows
                setDeleteConfirmationOpen(false); // Close delete confirmation dialog
                fetchData();
            } else {
                setDeleteMessage('Failed to delete selected orders.');
            }
        } catch (error) {
            console.error('Error deleting data:', error);
            handleSnackbar('Error deleting invoice!', 'error');
        } finally {
            setLoading(false);
        }
    };

    const clearDeleteMessage = () => {
        setDeleteMessage('');
    };

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleOpenDeleteConfirmation = () => {
        setDeleteConfirmationOpen(true);
    };

    const handleCloseDeleteConfirmation = () => {
        setDeleteConfirmationOpen(false);
    };

    const handleOpenPaymentDialog = (order) => {
        setSelectedOrder(order);
        setPaymentDialogOpen(true);
    };



    const handleClosePaymentDialog = () => {
        setPaymentDialogOpen(false);
    };


    {/*updated*/ }

    const handleEditClick = (order) => {
        setEditOrder(order);
        setEditDialogOpen(true);
    };

    const handleCloseEditDialog = () => {
        setEditDialogOpen(false);
        setEditOrder(null);
    };

    const handleEditChange = (field, value) => {
        const updatedOrder = { ...editOrder, [field]: value };

        if (field === 'term' && editOrder.invoicedate) {
            const invoiceDate = new Date(editOrder.invoicedate);
            let dueDate = invoiceDate;

            if (value === 'net 15') {
                dueDate.setDate(invoiceDate.getDate() + 15);
            } else if (value === 'net 30') {
                dueDate.setDate(invoiceDate.getDate() + 30);
            } else if (value === 'net 60') {
                dueDate.setDate(invoiceDate.getDate() + 60);
            }

            updatedOrder.duedate = dueDate.toISOString().split('T')[0]; // Format date as YYYY-MM-DD
        }

        setEditOrder(updatedOrder);
    };


    const handleEditItemChange = (index, field, value) => {
        const updatedItems = [...editOrder.items];
        updatedItems[index][field] = value;
        setEditOrder(prevState => ({ ...prevState, items: updatedItems }));
    };

    const handleAddNewItem = () => {
        // Get the current date in the desired format (e.g., YYYY-MM-DD)
        const currentDate = new Date().toISOString().split('T')[0];

        setEditOrder(prevState => ({
            ...prevState,
            items: [
                ...prevState.items,
                {
                    product: '',
                    quantity: '',
                    rate: '',
                    price: '',
                    servicedate: currentDate,
                    tax: ''
                }
            ]
        }));
    };


    const handleEditSave = async () => {
        setLoading(true); // Start loading

        try {
            const response = await axios.put(`https://admin.bizstor.lk/api/update-order/${editOrder.id}`, {
                fullname: editOrder.fullname,
                customer: editOrder.customer === 'Direct Customer' ? 'Direct Customer' : 'Existing Customer',
                address: editOrder.address,
                email: editOrder.email,
                term: editOrder.term,
                invoicedate: editOrder.invoicedate,
                duedate: editOrder.duedate,
                description: editOrder.description,
                discount: editOrder.discount,
                payamount: editOrder.payamount,

                items: editOrder.items.map(item => ({
                    product: item.product,
                    quantity: item.quantity,
                    rate: item.rate,
                    price: item.price,
                    servicedate: item.servicedate,
                    tax: item.tax,
                }))
            });
            console.log(response.data);
            setEditDialogOpen(false);
            handleSnackbar('Invoice updated successfully!', 'success');
            fetchData();
        } catch (error) {
            console.error('There was an error updating the order:', error.response.data);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        axios.get('https://admin.bizstor.lk/api/get-product-data')
            .then(response => {
                setProducts(response.data.products);
            })
            .catch(error => {
                console.error('Error fetching product data:', error);
            });
    }, []);

    products.forEach(product => {
        productPrices[product.product] = product.price;
    });

    const handleProductChange = (index, value) => {
        const rate = productPrices[value];
        handleEditItemChange(index, 'product', value);
        handleEditItemChange(index, 'rate', rate);
        const quantity = editOrder.items[index].quantity || 0;
        handleEditItemChange(index, 'price', rate * quantity);
    };

    const handleQuantityChange = (index, value) => {
        handleEditItemChange(index, 'quantity', value);
        const rate = editOrder.items[index].rate || 0;
        handleEditItemChange(index, 'price', rate * value);
    };

    const handleRateChange = (index, value) => {
        handleEditItemChange(index, 'rate', value);
        const quantity = editOrder.items[index].quantity || 0;
        handleEditItemChange(index, 'price', value * quantity);
    };

    const handleDeleteItem = (index) => {
        const updatedItems = [...editOrder.items];
        updatedItems.splice(index, 1);
        setEditOrder({ ...editOrder, items: updatedItems });
    };

    useEffect(() => {
        const fetchCustomers = async () => {
            try {
                const response = await axios.get('https://admin.bizstor.lk/api/get-customer-data');
                setCustomers(response.data.customers);
            } catch (error) {
                console.error('Error fetching customers:', error);
            }
        };

        fetchCustomers();
    }, []);


    const handleAutoFill = (field, value) => {
        if (field === 'customer') {
            if (value === 'Direct Customer') {
                setEditOrder(prevState => ({
                    ...prevState,
                    customer: value,
                    fullname: '',
                    email: '',
                    address: ''
                }));
            } else {
                const selectedCustomer = customers.find(customer => customer.id === value);
                setEditOrder(prevState => ({
                    ...prevState,
                    customer: value,
                    fullname: selectedCustomer.firstname,
                    email: selectedCustomer.email,
                    address: selectedCustomer.address
                }));
            }
        } else {
            setEditOrder(prevState => ({
                ...prevState,
                [field]: value
            }));
        }
    };

    const calculateTotalPrice = () => {
        // Check if editOrder and items are available
        if (!editOrder || !editOrder.items) return 0;

        // Calculate total with item-specific taxes included
        const total = editOrder.items.reduce((total, item) => {
            // Ensure item.price and item.tax are valid numbers
            const itemPrice = parseFloat(item.price) || 0;
            const itemTax = parseFloat(item.tax) || 0;

            const itemTotal = itemPrice + (itemPrice * (itemTax / 100));
            return total + itemTotal;
        }, 0);

        // Get discount percentage and pay amount, ensuring they are valid numbers
        const discountPercentage = parseFloat(editOrder.discount) || 0;
        const payAmount = parseFloat(editOrder.payamount) || 0;

        // Calculate discount amount and final total
        const discountAmount = (total * discountPercentage) / 100;
        const finalTotal = total - discountAmount - payAmount;

        return finalTotal.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const [selectedMonth, setSelectedMonth] = useState('');

    const handleSearchChange = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchTerm(value);
        filterData(value, selectedMonth);
    };

    const handleMonthChange = (event) => {
        const value = event.target.value;
        setSelectedMonth(value);
        filterData(searchTerm, value);
    };

    const filterData = (term, month) => {
        const filtered = fetchedData.filter(order => {
            const orderDate = new Date(order.invoicedate);
            const orderMonth = orderDate.toLocaleString('default', { month: 'long' }).toLowerCase();
            return (
                (term === '' || order.invoiceNo.toString().includes(term) ||
                    order.fullname.toLowerCase().includes(term) ||
                    order.invoicedate.includes(term)) &&
                (month === '' || orderMonth === month.toLowerCase())
            );
        });
        setFilteredData(filtered);
    };

    useEffect(() => {

        const fetchProfile = async () => {
            if (!token) return; // Exit if no token

            try {
                const response = await axios.get('https://admin.bizstor.lk/api/profile', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setUser(response.data);
                setName(response.data.name);
                setCompanyName(response.data.companyname);
                setAddress(response.data.address);
                setPhone(response.data.phone);
                setCompanyEmail(response.data.companyemail);
                setEmail(response.data.email);
            } catch (error) {
                console.log('');
            }
        };

        fetchProfile();
    }, [token]);


    const printSelectedRows = (data) => {
        const printWindow = window.open('', '_blank');
        const currentDate = new Date().toLocaleDateString();

        let printContent = `
          <html>
            <head>
              <title>Invoice</title>
              <style>
                body { 
                  font-family: Arial, sans-serif; 
                  margin: 20px; 
                  line-height: 1.6;
                  color: #333;
                }
                .header { 
                  display: flex;
                flex-direction:space-between; 
                }
                  .header h2 { 
      color: black;
      display: flex; 
      align-items: center;
      white-space: nowrap; /* Ensure the company name is in one row */
      margin: 0; /* Remove default margin */
    }
                 .header logo{
                 
                 display: flex;
                align-items: center;
                } 
                }
                .header img { 
                  max-width: 150px;
                  margin-right: 10px 
                }
                .header .contact { 
                  text-align: right; 
                }
                .header-table {
                  width: 100%;
                  margin-top: 20px;
                  margin-bottom: 20px;
                  display: flex;
                  justify-content: flex-end; 
                }
                .header-table th {
                  padding: 8px;
                  text-align: left; 
                }
                h1, h2 { 
                  text-align: center; 
                  color: #0056b3;
                }
                h4 {
                  color: #333;
                }
                .summary, .items, .description { 
                  margin-bottom: 20px; 
                }
                .summary { 
                  margin-top: 20px; 
                }
                .items table {
                  width: 100%; 
                  border-collapse: collapse;
                  margin-top: 20px;
                }
                .items th, .items td { 
                  border: 1px solid #ddd; 
                  padding: 8px; 
                  text-align: left;
                }
                .items th {
                  background-color: #f2f2f2;
                }
                .right-align {
                  text-align: right;
                }
                .summary p, .items p, .right-align p {
                  margin: 5px 0;
                }
                .status-PAID {
                  color: #3d9c45;
                  padding-left: 20px;
                  padding-right: 20px;
                  display: inline-block;
                }
                .status-PENDING {
                  color: yellow;
                  padding-left: 20px;
                  padding-right: 20px;
                  display: inline-block;
                }
                .details {
                display: flex;
                justify-content: space-between;
                margin-top: 20px;
                
            }
            .customer-details {
                width: 48%;
                text-align:left; 
            }
                .invoice-details {
                text-align:right; 
            }
                  .customer-details h3 {
                text-align:left;
                color: #0056b3;
            }
                 .invoice-details h3 {
                text-align:right;
                color: #0056b3;
            }  
                 
              </style>
            </head>
            <body >
              <div class="header">
              <div class="logo" style="display: flex; align-items: center; justify-content: flex-start;">
              <img src=${`https://admin.bizstor.lk/storage/app/public/images/${user.image}`} alt="Logo" style="width: 120px; height: auto; margin-right: 15px;">
              </div>
              <h2 style="color:black; display: flex; align-items: center;">${user.companyname}</h2>
              

                <table class="header-table">
                <tr>
                  <td>
                    <p ><img style="height:15px; margin-right:10px" src=${addressIcon}><strong>Address:</strong>${user.address}</p>
                    <p><img style="height:15px; margin-right:10px"  src=${emailIcon}><strong>Email:</strong> ${user.companyemail}</p>
                    <p><img style="height:15px; margin-right:10px"  src=${webIcon}><strong>Web:</strong>${user.website}</p>
                    <p><img style="height:15px; margin-right:10px"  src=${phoneIcon}><strong>Phone:</strong>${user.phone}</p>
                  </td>
                </tr>
              </table>
              </div>
              <hr>
              <h1>INVOICE</h1>
        `;

        data.forEach(order => {
            const totalAmount = order.items.reduce((acc, item) => acc + (item.quantity * item.rate), 0);
            const grandTotal = calculateTotalAmount(order.items, order.discount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            const discount =  calculateDiscountTotalAmount(order.items, order.discount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            const taxamount = calculateTotalTaxAmount(order.items).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            const paidAmount = order.payamount + calculateOrderTotalPayments(order.id);
            const isPaid = order.term === 'due on receipt' ? 'PAID' : (parseFloat(grandTotal) <= (order.payamount + calculateOrderTotalPayments(order.id)) ? 'PAID' : 'PENDING');

            printContent += `
              <div class="">
                <div class="details">
                <div class="customer-details">
                <h3 style="margin-top: 30px;">Customer Details</h3>
                <p><strong>Full Name:</strong> ${order.fullname}</p>
                <p><strong>Email:</strong> ${order.email}</p>
                <p><strong>Customer:</strong> ${order.customer}</p>
                <p><strong>Billing Address:</strong> ${order.address}</p>
                </div>
                <div class="invoice-details"> 
                <h3 style="margin-top: 30px;">Invoice Details</h3>
                <p><strong>Invoice No:</strong> ${order.invoiceNo}</p>
                <p><strong>Invoice Date:</strong> ${order.invoicedate}</p>
                <p><strong>Due Date:</strong> ${order.duedate}</p>
                <p><strong>Term:</strong> ${order.term}</p>
                </div>
              </div>
              <h1 style="margin-top: 30px; text-align: center;">
                 <span class="${isPaid === 'PAID' ? 'status-PAID' : 'status-PENDING'}">${isPaid}</span>
                </h1>
            `;

            printContent += `
              <div class="items">
                <h2></h2>
                <table border="1" cellpadding="5" cellspacing="0">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Product</th>
                      <th>Qty</th>
                      <th>Rate</th>
                      <th>Tax</th>
                      <th>Price</th>
                    </tr>
                  </thead>
                  <tbody>
            `;
            order.items.forEach((item, index) => {
                printContent += `
                  <tr>
                    <td>${index + 1}</td>
                    <td>${item.product}</td>
                    <td>${item.quantity}</td>
                    <td>${Number(item.rate).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                    <td>${item.tax}</td>
                    <td>${Number(item.price).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                  </tr>`;
            });
                printContent += `
                  <tr>
                    <td colspan="5" style="text-align:right;"><strong>TOTAL</strong></td>
                    <td class="total">Lkr.${totalAmount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                </tr>
                
                <tr>
                    <td colspan="5" style="text-align:right;"><strong>DISCOUNT</strong></td>
                    <td class="total">Lkr.${discount}</td>
                </tr>
                <tr>
                    <td colspan="5" style="text-align:right;"><strong>TAX</strong></td>
                    <td class="total">Lkr.${taxamount}</td>
                </tr>
                <tr>
                    <td colspan="5" style="text-align:right;"><strong>GRAND TOTAL</strong></td>
                    <td class="total">Lkr.${grandTotal}</td>
                </tr>
                <tr>
                    <td colspan="5" style="text-align:right;"><strong>PAYMENT</strong></td>
                    <td class="total">Lkr.${order.term === 'due on receipt'
                        ? grandTotal
                        : (order.payamount + calculateOrderTotalPayments(order.id)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                    ${order.term !== 'due on receipt' ? `
                    </td>
                </tr>
                <tr>
                    <td colspan="5" style="text-align:right;"><strong>PAYABLE</strong></td>
                    <td class="total">Lkr.${(parseFloat(grandTotal) - parseFloat(paidAmount)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                </tr>
                ` : ''}
                `;
           
                
            printContent += `</tbody></table>`;

            printContent += `
              <div class="right-align">
              </div>
    
             <p style="margin-top: 30px;"><strong>Authorized Signature:</strong></p><br>
            <p style="margin-top: 5px;">________________________</p>
            </div>
          `;
        });

        printContent += '</body></html>';

        printWindow.document.write(printContent);
        printWindow.document.close();
        printWindow.focus();

        setTimeout(() => {
            printWindow.print();
        }, 1000);
    };





    return (
        <Box>
            <AnimatedTypography
                component="h1"
                sx={{
                    ml: 5,
                    mt: 3,
                    fontSize: '1rem',
                    color: 'secondary.main',
                    letterSpacing: '0.01em'
                }}
            >
                / Dashboard / Report / Invoice
            </AnimatedTypography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '95%',
                    maxWidth: 'auto',
                    margin: 'auto',
                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                    padding: '10px',
                    borderRadius: '5px',
                    bgcolor: 'background.paper',
                    marginTop: '30px',
                    position: 'relative'
                }}
            >
                <Typography variant="h4">
                    Invoice
                </Typography>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' }, // Responsive direction
                        justifyContent: 'space-between', // Space between left and right groups
                        alignItems: 'center', // Align items vertically
                        p: 2, // Padding inside the box
                        gap: 2, // Space between sections
                    }}
                >
                    {/* Left side: Search fields */}
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', sm: 'row' },
                            gap: 1, // Space between the TextFields
                            width: '80%', // Ensure it takes full width on smaller screens
                            alignItems: 'center', // Align items vertically

                        }}
                    >
                        <TextField
                            variant="outlined"
                            margin="normal"
                            onChange={handleSearchChange}
                            value={searchTerm}
                            size="small"
                            placeholder="Search…"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{ width: '250px' }}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            onChange={handleSearchChange}
                            value={searchTerm}
                            size="small"
                            type="date"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{ width: '250px' }}
                        />

                        <Select
                            value={selectedMonth}
                            onChange={handleMonthChange}
                            displayEmpty
                            size="small"
                            sx={{
                                width: '250px',
                                height: '40px',
                                mt: 1
                            }}
                        >
                            <MenuItem value="">
                                <em>All Months</em>
                            </MenuItem>
                            <MenuItem value="January">January</MenuItem>
                            <MenuItem value="February">February</MenuItem>
                            <MenuItem value="March">March</MenuItem>
                            <MenuItem value="April">April</MenuItem>
                            <MenuItem value="May">May</MenuItem>
                            <MenuItem value="June">June</MenuItem>
                            <MenuItem value="July">July</MenuItem>
                            <MenuItem value="August">August</MenuItem>
                            <MenuItem value="September">September</MenuItem>
                            <MenuItem value="October">October</MenuItem>
                            <MenuItem value="November">November</MenuItem>
                            <MenuItem value="December">December</MenuItem>
                        </Select>
                    </Box>

                    {/* Right side: Icons */}
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 1, // Space between the icons
                            mt: { xs: 2, sm: 0 }, // Add margin-top on small screens
                            justifyContent: { xs: 'center', sm: 'flex-end' }, // Center on small screens
                            width: { xs: '80%', sm: 'auto' }, // Full width on small screens

                        }}
                    >

                        <IconButton
                            aria-label="print"
                            onClick={() => printSelectedRows(selectedRowsData)}
                            disabled={selectedRows.length === 0}
                        >
                            <PrintIcon />
                        </IconButton>

                        <IconButton
                            aria-label="delete"
                            onClick={handleMenuOpen}
                            disabled={selectedRows.length === 0}
                        >
                            <DeleteIcon
                                sx={{
                                    '&:hover': {
                                        color: 'red',
                                    },
                                }}
                            />
                        </IconButton>

                    </Box>
                </Box>


                <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                >
                    <MenuItem onClick={handleOpenDeleteConfirmation}>
                        Delete Selected Invoice
                    </MenuItem>
                </Menu>
                <Snackbar
                    open={alertOpen}
                    autoHideDuration={6000}
                    onClose={() => setAlertOpen(false)}
                    message="Deleted successfully!"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                />
                <Dialog
                    open={deleteConfirmationOpen}
                    onClose={handleCloseDeleteConfirmation}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Are you sure you want to delete selected orders?"}</DialogTitle>
                    <DialogActions>
                        <Button onClick={handleCloseDeleteConfirmation} color="primary">
                            No
                        </Button>
                        <Button onClick={handleDelete} color="primary" autoFocus>
                            Yes
                        </Button>
                    </DialogActions>

                    {loading && (
                        <Box sx={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100vw',
                            height: '100vh',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            bgcolor: 'white',
                            zIndex: 9999
                        }}>
                            <l-trio
                                size="50"
                                speed="1.3"
                                color="red"
                            ></l-trio>
                        </Box>
                    )}
                </Dialog>

                <TableContainer component={Paper} style={{ marginTop: '20px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        indeterminate={selectedRows.length > 0 && selectedRows.length < fetchedData.length}
                                        checked={selectedRows.length === fetchedData.length}
                                        onChange={() => {
                                            if (selectedRows.length === fetchedData.length) {
                                                setSelectedRows([]);
                                            } else {
                                                setSelectedRows(fetchedData.map((order) => order.id));
                                            }
                                        }}
                                    />
                                </TableCell>
                                <TableCell><b>Invoice No</b></TableCell>
                                <TableCell><b>Name</b></TableCell>
                                <TableCell><b>Customer Type</b></TableCell>
                                <TableCell><b>Term</b></TableCell>
                                <TableCell><b>Invoice Date</b></TableCell>
                                <TableCell><b>Due Date</b></TableCell>
                                <TableCell><b>Total Amount</b></TableCell>
                                <TableCell><b>Status</b></TableCell>
                                <TableCell><b>Action</b></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? (
                                <Box sx={{
                                    position: 'fixed',
                                    top: 0,
                                    left: 0,
                                    width: '100vw',
                                    height: '100vh',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    bgcolor: 'white',
                                    zIndex: 9999
                                }}>
                                    <l-trio
                                        size="50"
                                        speed="1.3"
                                        color="red"
                                    ></l-trio>
                                </Box>
                            ) : (

                                filteredData
                                    .sort((a, b) => new Date(b.id) - new Date(a.id))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((order, index) => (
                                        <React.Fragment key={index}>
                                            <TableRow
                                                hover
                                                onClick={() => handleRowClick(index)}
                                                role="checkbox"
                                                aria-checked={isSelected(order.id)}
                                                tabIndex={-1}
                                                selected={isSelected(order.id)}
                                                style={{ backgroundColor: openRow === index ? '#f0f0f0' : 'inherit' }}
                                            >
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        checked={isSelected(order.id)}
                                                        onChange={(event) => handleRowSelect(event, order.id)}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="body2" fontWeight="bold">
                                                        {order.invoiceNo}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="body2" fontWeight="bold">
                                                        {order.fullname}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="body2" fontWeight="bold">
                                                        {order.customer}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="body2" fontWeight="bold">
                                                        {order.term}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="body2" fontWeight="bold">
                                                        {order.invoicedate}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="body2" fontWeight="bold">
                                                        {order.duedate}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="body2" fontWeight="bold">
                                                        Rs.{calculateTotalAmount(order.items, order.discount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                    </Typography>
                                                </TableCell>
                                                {/* Status cell based on 'term' condition */}
                                                <TableCell>

                                                    <Typography
                                                        variant="body2"
                                                        fontWeight="bold"
                                                        style={{
                                                            color:
                                                                order.term === 'due on receipt' ? 'green' : // If term is 'due on receipt', show 'paid'
                                                                    order.payamount === 0 ? 'red' : // If payamount is explicitly 0, show 'unpaid'
                                                                        calculateTotalAmount(order.items, order.discount) <= (order.payamount + calculateOrderTotalPayments(order.id)) ? 'green' : // If total amount matches, show 'paid'
                                                                            'orange' // Otherwise, show 'pending'
                                                        }}
                                                    >
                                                        {order.term === 'due on receipt' ? 'paid' : // If term is 'due on receipt', show 'paid'
                                                            order.payamount === 0 ? 'unpaid' : // If payamount is explicitly 0, show 'unpaid'
                                                                calculateTotalAmount(order.items, order.discount) <= (order.payamount + calculateOrderTotalPayments(order.id)) ? 'paid' : // If total amount matches, show 'paid'
                                                                    'pending' // Otherwise, show 'pending'
                                                        }
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Box alignItems="center">
                                                        <IconButton onClick={() => handleEditClick(order)}>
                                                            <EditNoteIcon sx={{
                                                                '&:hover': {
                                                                    color: 'black',
                                                                },
                                                            }} />
                                                        </IconButton>
                                                        {order.term !== 'due on receipt' && (
                                                            <IconButton onClick={() => handleOpenPaymentDialog(order)}>
                                                                <PaymentsIcon
                                                                    sx={{
                                                                        '&:hover': {
                                                                            color: 'black',
                                                                        },
                                                                    }}
                                                                />
                                                            </IconButton>
                                                        )}
                                                    </Box>
                                                </TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                                                    <Collapse in={openRow === index} timeout="auto" unmountOnExit>
                                                        <Box margin={1} padding={2} bgcolor="#f9f9f9" borderRadius={4} boxShadow={3}>
                                                            <Typography variant="h6" gutterBottom component="div" color="primary">
                                                                Order Details
                                                            </Typography>
                                                            <Table size="small" aria-label="purchases">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell><b>Product</b></TableCell>
                                                                        <TableCell><b>Qty</b></TableCell>
                                                                        <TableCell><b>Rate</b></TableCell>
                                                                        <TableCell><b>Price</b></TableCell>
                                                                        {/**       <TableCell><b>Service Date</b></TableCell>*/}
                                                                        <TableCell><b>Tax</b></TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {order.items.map((item, idx) => (
                                                                        <TableRow key={idx}>
                                                                            <TableCell>{item.product}</TableCell>
                                                                            <TableCell>{item.quantity}</TableCell>
                                                                            <TableCell>Rs.{item.rate.toFixed(2)}</TableCell>
                                                                            <TableCell>Rs.{item.price.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                                                                            {/**    <TableCell>{item.servicedate}</TableCell>*/}
                                                                            <TableCell>{item.tax}</TableCell>
                                                                        </TableRow>
                                                                    ))}
                                                                    <TableRow>
                                                                        <TableCell colSpan={3} align="right">
                                                                            <Typography variant="body1" fontWeight={600}>Total</Typography>
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <Typography variant="body1" fontWeight={600}>
                                                                                Rs.{order.items.reduce((acc, item) => acc + (item.quantity * item.rate), 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                                            </Typography>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell colSpan={3} align="right">
                                                                            <Typography variant="body1" fontWeight={600}>Discount</Typography>
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <Typography variant="body1" fontWeight={600}>{order.discount}%</Typography>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell colSpan={3} align="right">
                                                                            <Typography variant="body1" fontWeight={600}>Pay Amount</Typography>
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <Typography variant="body1" fontWeight={600}>
                                                                                Rs. {order.term === 'due on receipt'
                                                                                    ? calculateTotalAmount(order.items, order.discount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                                                    : (order.payamount + calculateOrderTotalPayments(order.id)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                                            </Typography>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    {order.term !== 'due on receipt' && (
                                                                        <TableRow>
                                                                            <TableCell colSpan={3} align="right">
                                                                                <Typography variant="body1" fontWeight={600}>Pay Due</Typography>
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                <Typography variant="body1" fontWeight={600}>
                                                                                    Rs.{(calculateTotalAmount(order.items, order.discount) - (order.payamount + calculateOrderTotalPayments(order.id))).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                                                </Typography>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    )}
                                                                    <TableRow>
                                                                        <TableCell colSpan={3} align="right">
                                                                            <Typography variant="body1" fontWeight={600}>Grand Total</Typography>
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <Typography variant="body1" fontWeight={600}>
                                                                                Rs.{calculateTotalAmount(order.items, order.discount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                                            </Typography>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </TableBody>

                                                            </Table>
                                                            <Typography variant="subtitle1" gutterBottom component="div" color="textSecondary" mt={2}>
                                                                Description
                                                            </Typography>
                                                            <Typography variant="body2" gutterBottom component="div">
                                                                {order.description}
                                                            </Typography>
                                                        </Box>
                                                    </Collapse>
                                                </TableCell>


                                            </TableRow>
                                        </React.Fragment>
                                    ))
                            )}
                        </TableBody>
                    </Table>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 15]}
                        component="div"
                        count={filteredData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </TableContainer>

                <Dialog open={paymentDialogOpen} onClose={handleClosePaymentDialog}>
                    <DialogTitle>Make Payment</DialogTitle>
                    <DialogContent>
                        <MakePayment order={selectedOrder} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClosePaymentDialog} color="primary">
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>

                {/*popup dialog */}
                <Box>
                    <Dialog
                        open={editDialogOpen}
                        onClose={() =>
                            setEditDialogOpen(false)}
                        TransitionComponent={Transition}
                        maxWidth="xl"
                    >
                        <AppBar sx={{ position: 'relative', bgcolor: 'black' }}>
                            <Toolbar>
                                <IconButton edge="start" color="inherit" onClick={handleCloseEditDialog} aria-label="close">
                                    <CloseIcon sx={{ color: 'white' }} />
                                </IconButton>
                                <Typography sx={{ ml: 2, flex: 1, color: 'white' }} variant="h6" component="div">
                                    Edit Order
                                </Typography>
                            </Toolbar>
                        </AppBar>
                        <DialogContent>
                            <Grid container spacing={2} mt={1}>
                                <Grid item xs={12} sm={6} md={3}>
                                    <FormControl fullWidth variant="outlined" size="small">
                                        <InputLabel>Customer</InputLabel>
                                        <Select
                                            label="Customer"
                                            value={editOrder?.customer || ''}
                                            onChange={(e) => handleAutoFill('customer', e.target.value)}
                                            fullWidth
                                            size="small"
                                        >
                                            <MenuItem value="Direct Customer">Direct Customer</MenuItem>
                                            <ListSubheader>Existing Customers</ListSubheader>
                                            {customers.map((customer) => (
                                                <MenuItem key={customer.id} value={customer.id}>
                                                    {customer.firstname}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField
                                        label="Full Name"
                                        value={editOrder?.fullname || ''}
                                        onChange={(e) => handleAutoFill('fullname', e.target.value)}
                                        fullWidth
                                        size="small"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField
                                        label="Email"
                                        value={editOrder?.email || ''}
                                        onChange={(e) => handleAutoFill('email', e.target.value)}
                                        fullWidth
                                        size="small"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField
                                        label="Address"
                                        value={editOrder?.address || ''}
                                        onChange={(e) => handleAutoFill('address', e.target.value)}
                                        fullWidth
                                        size="small"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <FormControl fullWidth size="small">
                                        <InputLabel>Term</InputLabel>
                                        <Select
                                            value={editOrder?.term || ''}
                                            onChange={(e) => handleEditChange('term', e.target.value)}
                                            label="Term"
                                        >
                                            <MenuItem value="due on receipt">Due on Receipt</MenuItem>
                                            <MenuItem value="net 15">Net 15</MenuItem>
                                            <MenuItem value="net 30">Net 30</MenuItem>
                                            <MenuItem value="net 60">Net 60</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField
                                        label="Invoice Date"
                                        value={editOrder?.invoicedate || ''}
                                        onChange={(e) => handleEditChange('invoicedate', e.target.value)}
                                        fullWidth
                                        type='date'
                                        size="small"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField
                                        label="Due Date"
                                        value={editOrder?.duedate || ''}
                                        onChange={(e) => handleEditChange('duedate', e.target.value)}
                                        InputProps={{ readOnly: true }}
                                        fullWidth
                                        size="small"
                                    />
                                </Grid>
                            </Grid>

                            <Box
                                sx={{
                                    textAlign: 'right',
                                    marginTop: '10px',
                                    marginRight: '10px',
                                    padding: '10px',
                                }}
                            >
                                <h3 style={{ margin: 0 }}>Total </h3>
                                <p style={{ fontSize: '24px', margin: 0 }}>Lkr. {calculateTotalPrice()}</p>
                            </Box>

                            <Box sx={{ display: 'flex', justifyContent: 'flex-start', marginTop: '20px', marginBottom: '10px' }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleAddNewItem}
                                    style={{ backgroundColor: 'black', color: 'white' }}
                                    sx={{
                                        height: '30px',
                                    }}
                                >
                                    <PlaylistAddIcon />
                                </Button>
                            </Box>



                            <TableContainer
                                size="small"
                                sx={{
                                    maxHeight: '400px', // Set a max height for the container
                                    overflowY: 'auto',  // Enable vertical scrolling for overflow
                                    '@media (max-width:600px)': { // Media query for small screens
                                        '& .MuiTableCell-root': {
                                            padding: '4px', // Reduce padding for small screens
                                            fontSize: '0.8rem' // Decrease font size for readability
                                        },
                                        '& .MuiTextField-root': {
                                            minWidth: '100px', // Ensure text fields have a minimum width
                                        },
                                        '& .MuiSelect-root': {
                                            minWidth: '100px', // Ensure selects have a minimum width
                                        },
                                        '& .MuiIconButton-root': {
                                            padding: '4px' // Reduce padding for icon buttons
                                        }
                                    }
                                }}
                            >
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Product</TableCell>
                                            <TableCell>Qty</TableCell>
                                            <TableCell>Rate</TableCell>
                                            <TableCell>Price</TableCell>
                                            <TableCell>Tax</TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {editOrder?.items.map((item, index) => (
                                            <TableRow key={index} sx={{ height: '40px' }}>
                                                <TableCell sx={{ padding: '8px' }}>
                                                    <FormControl fullWidth size="small" variant="outlined">
                                                        <InputLabel>Product</InputLabel>
                                                        <Select
                                                            value={item.product}
                                                            onChange={(e) => handleProductChange(index, e.target.value)}
                                                            label="Product"
                                                            sx={{ width: '100%' }}
                                                        >
                                                            {Object.keys(productPrices).map((product) => (
                                                                <MenuItem key={product} value={product}>
                                                                    {product}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </TableCell>
                                                <TableCell sx={{ padding: '8px' }}>
                                                    <TextField
                                                        label="Qty"
                                                        value={item.quantity}
                                                        onChange={(e) => handleQuantityChange(index, e.target.value)}
                                                        fullWidth
                                                        size="small"
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        label="Rate"
                                                        value={item.rate}
                                                        onChange={(e) => handleRateChange(index, e.target.value)}
                                                        fullWidth
                                                        size="small"
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        label="Price"
                                                        value={item.price}
                                                        onChange={(e) => handleEditItemChange(index, 'price', e.target.value)}
                                                        fullWidth
                                                        size="small"
                                                        InputProps={{ readOnly: true }}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        label="Tax"
                                                        value={item.tax}
                                                        onChange={(e) => handleEditItemChange(index, 'tax', e.target.value)}
                                                        fullWidth
                                                        size="small"
                                                        type='number'
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <IconButton onClick={() => handleDeleteItem(index)}
                                                        sx={{
                                                            '&:hover': {
                                                                color: 'red',
                                                            },
                                                        }}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        margin="normal"
                                        label="Description"
                                        multiline
                                        rows={3}
                                        size="small"
                                        value={editOrder?.description || ''}
                                        onChange={(e) => handleAutoFill('description', e.target.value)}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid container item xs={12} sm={6} spacing={2} justifyContent="flex-end">
                                    <Grid item xs={6}>
                                        <TextField
                                            margin="normal"
                                            label="Discount"
                                            size="small"
                                            type='number'
                                            value={editOrder?.discount || ''}
                                            onChange={(e) => handleEditChange('discount', e.target.value)}
                                            fullWidth
                                        />
                                        <TextField
                                            margin="normal"
                                            label="Pay Amount"
                                            size="small"
                                            type='number'
                                            value={editOrder?.payamount || ''}
                                            onChange={(e) => handleEditChange('payamount', e.target.value)}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setEditDialogOpen(false)} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={handleEditSave} color="primary">
                                Save
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Box>
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={handleSnackbarClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                >
                    <Alert
                        onClose={handleSnackbarClose}
                        severity={snackbarSeverity}
                        sx={{
                            width: '100%',

                        }}
                    >
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </Box>
        </Box>
    );
};

export default InvoiceTable;
