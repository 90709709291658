import React from 'react';
import { Box, Tabs, Tab, Typography, Paper, useTheme, useMediaQuery } from '@mui/material';
import Invoice from '../../Tested/reportstest/invoice';
import Stock from '../../Tested/reportstest/stock';
import Expenses from '../../Tested/reportstest/expenses';
import Return from '../../Tested/reportstest/return';
import Customer from '../CustomerReports';
import Summary from '../Alltest';
import ProfitandLoss from '../ProfitandLoss';
import SalesbyProduct from '../SalesbyProduct';
import SalesbyCustomer from '../SalesbyCustomer';
import Investment from '../../Tested/reportstest/investment';
import BalanceSheet from '../../Reports/BalanceSheet';


// Create a functional component for each tab's content
const TabPanel = ({ value, index, children }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const AllReport = () => {
  const [tabValue, setTabValue] = React.useState(0);
  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm')); // Check if screen is small

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Paper square>
        <Box
          sx={{
            overflowX: isSmScreen ? 'auto' : 'hidden', // Scrollable on small screens, hidden on large screens
            display: 'flex',
            justifyContent: isSmScreen ? 'flex-start' : 'center', // Center tabs on large screens
          }}
        >
          <Tabs
            value={tabValue}
            onChange={handleChange}
            aria-label="report tabs"
            variant={isSmScreen ? 'scrollable' : 'standard'} // Scrollable on small screens
            scrollButtons={isSmScreen ? 'auto' : false} // Show scroll buttons on small screens
            allowScrollButtonsMobile={isSmScreen}
          >
            <Tab label="invoice" />
            <Tab label="inventory" />
            <Tab label="expenses" />
            <Tab label="returns" />
            <Tab label="Investment" />
            <Tab label="customer" />
            <Tab label="summary" />
            <Tab label="profit & loss" />
            <Tab label="sales by customer" />
            <Tab label="sales by product" />
            <Tab label="Balance Sheet" />
          </Tabs>
        </Box>
      </Paper>
      <TabPanel value={tabValue} index={0}>
        <Invoice />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <Stock />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <Expenses />
      </TabPanel>
      <TabPanel value={tabValue} index={3}>
        <Return />
      </TabPanel>
      <TabPanel value={tabValue} index={4}>
        <Investment />
      </TabPanel>
      <TabPanel value={tabValue} index={5}>
        <Customer />
      </TabPanel>
      <TabPanel value={tabValue} index={6}>
        <Summary />
      </TabPanel>
      <TabPanel value={tabValue} index={7}>
        <ProfitandLoss />
      </TabPanel>
      <TabPanel value={tabValue} index={8}>
        <SalesbyCustomer />
      </TabPanel>
      <TabPanel value={tabValue} index={9}>
        <SalesbyProduct />
      </TabPanel>
      <TabPanel value={tabValue} index={10}>
        <BalanceSheet />
      </TabPanel>
    </Box>
  );
};

export default AllReport;
