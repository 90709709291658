import BG from '../Auth2/bg.jpg';
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { 
  TextField, 
  Button, 
  Typography, 
  Container, 
  Box,
  Snackbar, 
  Alert,
} from '@mui/material';
import { styled, keyframes } from '@mui/system';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
const slideInFromLeft = keyframes`
  0% {
    transform: translateX(-20px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const AnimatedTypography = styled(Typography)(({ theme }) => ({
  animation: `${slideInFromLeft} 0.5s ease-out`,
}));

const Login = ({ setToken }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [snackbarOpen, setSnackbarOpen] = useState(false); 
  const [snackbarMessage, setSnackbarMessage] = useState(''); 
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); 
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState(0);

  const handleSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleLogin = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const response = await axios.post('https://admin.bizstor.lk/api/login', { email, password });
  
      // Check if the response has a forbidden status due to inactive account
      if (response.status === 403) {
        handleSnackbar('Account is not active!', 'error');
        setLoading(false);
        return;
      }
  
      const token = response.data.token;
      localStorage.setItem('token', token);
      setToken(token);
  
      handleSnackbar('Login successfully!', 'success');
      setLoading(false);
      navigate('/dashboard');
    } catch (error) {
      handleSnackbar('Login failed!', 'error');
      setLoading(false);
    }
  };
  

  React.useEffect(() => {
    setKey(prevKey => prevKey + 1); 
  }, []);


  const handleGoogleSuccess = async (response) => {
    try {
      const { credential } = response;
      // Send the credential to the backend to verify and get a token
      const res = await axios.get(`https://admin.bizstor.lk/api/auth/google/callback?credential=${credential}`);
      const token = res.data.token;
      localStorage.setItem('token', token);
      setToken(token);
      handleSnackbar('Google login successful!', 'success');
      navigate('/dashboard');
    } catch (error) {
      handleSnackbar('Google login failed!', 'error');
    }
  };

  const handleGoogleError = () => {
    handleSnackbar('Google login failed!', 'error');
  };

  return (
    <GoogleOAuthProvider clientId="28836141187-vamhlaq70sgeoahd22hro2dqehetbl27.apps.googleusercontent.com">
    <Container maxWidth="xs">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          backgroundImage: `url(${BG})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          width: '100vw', // Make sure the width covers the full viewport
          position: 'fixed', // Ensures the background stays fixed
          top: 0,
          left: 0,
          zIndex: -1, // Background stays behind content
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            p: 4,
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
            borderRadius: 2,
            bgcolor: 'white', // Slightly transparent background for the form box
            zIndex: 1, // Ensures the form is above the background
          }}
        >
          <h1>BIZSTOR</h1>
          <AnimatedTypography key={key} component="h1" variant="h6">
            Login
          </AnimatedTypography>
          <Box component="form" onSubmit={handleLogin} sx={{ mt: 1 }}>
            <TextField
              variant="standard"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              variant="standard"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Typography variant="body2" sx={{ mt: 1 }}>
              <Link to="/forgot-password" style={{ textDecoration: 'none' }}>Forgot Password?</Link>
            </Typography>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                borderRadius: 20,
                background: 'gray',
                '&:hover': {
                  backgroundColor: 'black',
                },
              }}
            >
              Login
            </Button>
            <Typography variant="body2">
              Don't have an account? <Link to="/register" style={{ textDecoration: 'none' }}>Register here</Link>
            </Typography>
        
          {/** <GoogleLogin
          onSuccess={handleGoogleSuccess}
          onError={handleGoogleError}
        />*/}  

            {loading && (
              <Box sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100vw',
                height: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                bgcolor: 'white', 
                zIndex: 9999
              }}>
                <l-trio
                  size="50"
                  speed="1.3"
                  color="red"
                ></l-trio>
              </Box>
            )}
            <Snackbar
              open={snackbarOpen}
              autoHideDuration={6000}
              onClose={handleSnackbarClose}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
              <Alert
                onClose={handleSnackbarClose}
                severity={snackbarSeverity}
                sx={{ width: '100%' }}
              >
                {snackbarMessage}
              </Alert>
            </Snackbar>
          </Box>
        </Box>
      </Box>
    </Container>
    </GoogleOAuthProvider>
  );
};

export default Login;
