import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Box,
    TextField,
    InputAdornment,
    Select,
    MenuItem,
    IconButton,
    CircularProgress
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import PrintIcon from '@mui/icons-material/Print';
import { printTable } from './Print/printUtils'; // Ensure this path is correct

const DataTable = () => {
    const [orders, setOrders] = useState([]);
    const [productTotals, setProductTotals] = useState([]);
    const [totalTaxAmount, setTotalTaxAmount] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [totalDiscount, setTotalDiscount] = useState(0);
    const [groupedData, setGroupedData] = useState([]);
    const [totalReplaceQuantity, setTotalReplaceQuantity] = useState(0);
    const [totalMoneyBackPrice, setTotalMoneyBackPrice] = useState(0);
    const [loading, setLoading] = useState(false);
    const [searchDate, setSearchDate] = useState('');
    const [selectedPeriod, setSelectedPeriod] = useState('');
    const [selectedMonth, setSelectedMonth] = useState('');

    useEffect(() => {
        fetchOrderData();
    }, [searchDate, selectedPeriod, selectedMonth]);

    const fetchOrderData = async () => {
        setLoading(true);
        try {
            const response = await axios.get('https://admin.bizstor.lk/api/get-data');
            const data = response.data;
            setOrders(data);

            const { productTotals, totalTaxAmount, totalAmount, totalDiscount } = calculateTotals(data);
            setProductTotals(productTotals);
            setTotalTaxAmount(totalTaxAmount);
            setTotalAmount(totalAmount);
            setTotalDiscount(totalDiscount);

            filterData(data);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const calculateTotals = (data) => {
        const productMap = new Map();
        let totalTaxAmount = 0;
        let totalAmount = 0;
        let totalDiscount = 0;

        data.forEach(order => {
            order.items.forEach(item => {
                const itemTax = (item.price * (item.tax / 100)) || 0;
                totalTaxAmount += itemTax;
                const itemTotal = item.price + itemTax;

                const discountPercentage = parseFloat(order.discount) || 0;
                const discountAmount = (itemTotal * discountPercentage) / 100;
                totalDiscount += discountAmount;

                const discountedItemTotal = itemTotal - discountAmount;
                totalAmount += discountedItemTotal;

                if (productMap.has(item.product)) {
                    const currentData = productMap.get(item.product);
                    productMap.set(item.product, {
                        quantity: currentData.quantity + item.quantity,
                        amount: (currentData.amount || 0) + discountedItemTotal,
                        discount: (currentData.discount || 0) + discountAmount,
                        tax: (currentData.tax || 0) + itemTax
                    });
                } else {
                    productMap.set(item.product, {
                        quantity: item.quantity,
                        amount: discountedItemTotal,
                        discount: discountAmount,
                        tax: itemTax
                    });
                }
            });
        });

        const productTotals = Array.from(productMap, ([product, data]) => ({
            product,
            quantity: data.quantity,
            amount: data.amount || 0,
            discount: data.discount || 0,
            tax: data.tax || 0
        }));

        return { productTotals, totalTaxAmount, totalAmount, totalDiscount };
    };

    const filterData = (data) => {
        let filtered = [...data];
        let replaceQuantity = 0;
        let moneyBackPrice = 0;

        if (searchDate) {
            filtered = filtered.filter(order => order.invoicedate === searchDate);
        } else if (selectedPeriod) {
            const now = new Date();
            switch (selectedPeriod) {
                case 'current-month':
                    filtered = filtered.filter(order => {
                        const orderDate = new Date(order.invoicedate);
                        return orderDate.getMonth() === now.getMonth() && orderDate.getFullYear() === now.getFullYear();
                    });
                    break;
                case 'last-month':
                    filtered = filtered.filter(order => {
                        const orderDate = new Date(order.invoicedate);
                        return orderDate.getMonth() === now.getMonth() - 1 && orderDate.getFullYear() === now.getFullYear();
                    });
                    break;
                case 'last-3-months':
                    filtered = filtered.filter(order => {
                        const orderDate = new Date(order.invoicedate);
                        const threeMonthsAgo = new Date(now.setMonth(now.getMonth() - 3));
                        return orderDate >= threeMonthsAgo && orderDate <= new Date();
                    });
                    break;
                case 'last-year':
                    filtered = filtered.filter(order => {
                        const orderDate = new Date(order.invoicedate);
                        return orderDate.getFullYear() === now.getFullYear() - 1;
                    });
                    break;
                default:
                    break;
            }
        } else if (selectedMonth) {
            filtered = filtered.filter(order => {
                const orderDate = new Date(order.invoicedate);
                return orderDate.getMonth() === selectedMonth - 1; // month is zero-based
            });
        }

        const { productTotals, totalTaxAmount, totalAmount, totalDiscount } = calculateTotals(filtered);
        setProductTotals(productTotals);
        setTotalTaxAmount(totalTaxAmount);
        setTotalAmount(totalAmount);
        setTotalDiscount(totalDiscount);

        // Group and calculate replace quantity and money back price
        const grouped = groupItemsByProductAndType(filtered);
        grouped.forEach(item => {
            if (item.type === 'replace') {
                replaceQuantity += item.totalQuantity;
            } else if (item.type === 'money back') {
                moneyBackPrice += item.totalPrice;
            }
        });

        setGroupedData(grouped);
        setTotalReplaceQuantity(replaceQuantity);
        setTotalMoneyBackPrice(moneyBackPrice);
    };

    const groupItemsByProductAndType = (data) => {
        const grouped = {};

        data.forEach(order => {
            order.items.forEach(item => {
                const key = `${item.product}-${order.type}`;

                if (!grouped[key]) {
                    grouped[key] = { ...item, type: order.type, totalQuantity: 0, totalPrice: 0 };
                }

                grouped[key].totalQuantity += item.quantity;
                grouped[key].totalPrice += item.price;
            });
        });

        return Object.values(grouped);
    };

    const handleSearchChange = (event) => {
        setSearchDate(event.target.value);
        setSelectedPeriod('');
        setSelectedMonth('');
    };

    const handlePeriodChange = (event) => {
        setSelectedPeriod(event.target.value);
        setSearchDate('');
        setSelectedMonth('');
    };

    const handleMonthChange = (event) => {
        setSelectedMonth(event.target.value);
        setSearchDate('');
        setSelectedPeriod('');
    };

    const handlePrint = () => {
        const tableElement = document.querySelector('table');
        if (tableElement) {
            printTable(tableElement);
        }
    };

    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    p: 2,
                    flexWrap: 'wrap'
                }}
            >
                <TextField
                    variant="outlined"
                    margin="normal"
                    size="small"
                    type="date"
                    onChange={handleSearchChange}
                    value={searchDate}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        )
                    }}
                    sx={{ width: '200px' }}
                />
                <Select
                    value={selectedPeriod}
                    onChange={handlePeriodChange}
                    displayEmpty
                    size="small"
                    sx={{ width: '200px' }}
                >
                    <MenuItem value=""><em>All</em></MenuItem>
                    <MenuItem value="current-month">Current Month</MenuItem>
                    <MenuItem value="last-month">Last Month</MenuItem>
                    <MenuItem value="last-3-months">Last 3 Months</MenuItem>
                    <MenuItem value="last-year">Last Year</MenuItem>
                </Select>
                <Select
                    value={selectedMonth}
                    onChange={handleMonthChange}
                    displayEmpty
                    size="small"
                    sx={{ width: '200px' }}
                >
                    <MenuItem value=""><em>All Months</em></MenuItem>
                    {Array.from({ length: 12 }, (_, index) => (
                        <MenuItem key={index} value={index + 1}>{new Date(0, index).toLocaleString('default', { month: 'long' })}</MenuItem>
                    ))}
                </Select>
                <IconButton color="primary" onClick={handlePrint}>
                    <PrintIcon />
                </IconButton>
            </Box>

            {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                    <CircularProgress />
                </Box>
            ) : (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '95%',
                        maxWidth: 'auto',
                        margin: 'auto',
                        boxShadow: 1,
                        padding: '10px',
                        borderRadius: '5px',
                        bgcolor: 'background.paper',
                        marginTop: '30px',
                        position: 'relative'
                    }}
                >
                    <Typography variant="h4" component="h1" gutterBottom>
                        Sales
                    </Typography>
                    <TableContainer component={Paper} style={{ marginTop: '20px' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Product</TableCell>
                                    <TableCell>Quantity</TableCell>
                                    <TableCell>Amount</TableCell>
                                    <TableCell>Discount</TableCell>
                                    <TableCell>Tax</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {productTotals.map((productData, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{productData.product}</TableCell>
                                        <TableCell>{productData.quantity}</TableCell>
                                        <TableCell>Rs.{(productData.amount || 0).toFixed(2)}</TableCell>
                                        <TableCell>Rs.{(productData.discount || 0).toFixed(2)}</TableCell>
                                        <TableCell>Rs.{(productData.tax || 0).toFixed(2)}</TableCell>
                                    </TableRow>
                                ))}
                                <TableRow>
                                    <TableCell colSpan={4}>
                                        <Typography variant="h6"><b>Total Tax Amount:</b></Typography>
                                    </TableCell>
                                    <TableCell><b>Rs.{(totalTaxAmount || 0).toFixed(2)}</b></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={4}>
                                        <Typography variant="h6"><b>Total Discount:</b></Typography>
                                    </TableCell>
                                    <TableCell><b>Rs.{(totalDiscount || 0).toFixed(2)}</b></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={4}>
                                        <Typography variant="h6"><b>Total Income (excluding tax and discount):</b></Typography>
                                    </TableCell>
                                    <TableCell><b>Rs.{((totalAmount || 0) + (totalDiscount || 0) - (totalTaxAmount || 0)).toFixed(2)}</b></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={4}>
                                        <Typography variant="h6"><b>Total Sales:</b></Typography>
                                    </TableCell>
                                    <TableCell><b>Rs.{(totalAmount || 0).toFixed(2)}</b></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            )}
        </Box>
    );
};

export default DataTable;
