import React, { useState } from 'react';
import axios from 'axios';
import {
  TextField,
  Button,
  Typography,
  Box,
  Snackbar,
  Alert,
} from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import BG from '../Auth2/bg.jpg';

const Register = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [cpassword, setCPassword] = useState('');
  const [status, setStatus] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const clientId = '28836141187-vamhlaq70sgeoahd22hro2dqehetbl27.apps.googleusercontent.com';

  const handleSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleRegister = async (e) => {
    setLoading(true);
    e.preventDefault();
    if (password.length <= 6) {
      handleSnackbar('Password must be greater than 6 characters!', 'error');
      setLoading(false);
    } 
    else if (password !== cpassword) {
      handleSnackbar('Password did not match!', 'error');
      setLoading(false);
    }
    else {
      try {
        await axios.post('https://admin.bizstor.lk/api/register', { name, email, password, status: 'active' });
        handleSnackbar('Registered successfully!', 'success');
        setLoading(false);
        navigate('/');
      } catch (error) {
        handleSnackbar('Registration failed!', 'error');
        setLoading(false);
      }
    }
  };

  const handleGoogleSuccess = async (credentialResponse) => {
    try {
      // Send Google token to Laravel backend for verification and registration
      const response = await axios.post('http://localhost:8000/api/register/google', {
        token: credentialResponse.credential,
      });

      // Display success message or navigate as needed
      handleSnackbar('Google Registration successful!', 'success');
      navigate('/');
    } catch (error) {
      handleSnackbar('Google Registration failed!', 'error');
    }
  };


  // Handle Google sign-in failure
  const handleGoogleFailure = (error) => {
    console.error('Google Sign-In Failed:', error);
  };

  return (
    <GoogleOAuthProvider clientId="28836141187-vamhlaq70sgeoahd22hro2dqehetbl27.apps.googleusercontent.com">
      <Box
        sx={{
          minHeight: '100vh',
          width: '100vw',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundImage: `url(${BG})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            p: 4,
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
            borderRadius: 2,
            bgcolor: 'white',
            maxWidth: '400px',
            width: '100%',
          }}
        >
          <h1>BIZSTOR</h1>
          <Typography component="h1" variant="h5">
            Register
          </Typography>
          <Box component="form" onSubmit={handleRegister} sx={{ mt: 1 }}>
            <TextField
              variant="standard"
              margin="normal"
              required
              fullWidth
              id="name"
              label="Name"
              name="name"
              autoComplete="name"
              autoFocus
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              variant="standard"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              variant="standard"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <TextField
              variant="standard"
              margin="normal"
              required
              fullWidth
              name="cpassword"
              label="Confirm Password"
              type="password"
              id="cpassword"
              autoComplete="current-password"
              value={cpassword}
              onChange={(e) => setCPassword(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, borderRadius: 20, background: 'gray', '&:hover': { backgroundColor: 'black' } }}
            >
              Register
            </Button>
           {/**<div>
              <h6>Register with Google</h6>
              <GoogleLogin
                onSuccess={handleGoogleSuccess}
                onError={handleGoogleFailure}
              />
            </div> */} 
            <Typography variant="body2" sx={{ mt: 2 }}>
              Already have an account? <Link to="/" style={{ textDecoration: 'none' }}>Login here</Link>
            </Typography>
          </Box>
        </Box>

        {loading && (
                    <Box sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100vw',
                        height: '100vh',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        bgcolor: 'white',
                        zIndex: 9999
                    }}>
                        <l-trio
                            size="50"
                            speed="1.3"
                            color="red"
                        ></l-trio>
                    </Box>
                )}

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </GoogleOAuthProvider>
  );
};

export default Register;
