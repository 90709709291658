import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Box,
    Button,
    Dialog,
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Slide,
    TextField,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Snackbar,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    ListSubheader
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PaymentsIcon from '@mui/icons-material/Payments';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const EditOrder = ({ order }) => {
        const [rows, setRows] = useState([{ id: 1, product: '', quantity: '', rate: '', price: '', servicedate: new Date().toISOString().split('T')[0], tax: '' }]);
    const [orderData, setOrderData] = useState(order);
    const [open, setOpen] = useState(false);
    const [customers, setCustomers] = useState([]);
    const [selectedOption, setSelectedOption] = useState('Direct Customer');
    const [selectedCustomerData, setSelectedCustomerData] = useState({
        fullname: '',
        email: '',
        address: ''
    });
    const [term, setTerm] = useState('due on receipt');
    const [discount, setDiscount] = useState(0);
    const [payamount, setPayamount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [description, setDescription] = useState('');
    const [invoicedate, setInvoiceDate] = useState(new Date().toISOString().split('T')[0]);
    const [duedate, setDueDate] = useState('');
    const [fullname, setFullname] = useState('');
    const [email, setEmail] = useState('');
    const [address, setBillingAddress] = useState('');
    const [type, setType] = useState('');


    const handleOptionChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedOption(selectedValue);

        if (selectedValue === 'Direct Customer') {
            setSelectedCustomerData({
                id: '',
                fullname: '',
                email: '',
                address: '',
            });
        } else {
            const selectedCustomer = customers.find((customer) => customer.id === selectedValue);
            if (selectedCustomer) {
                setSelectedCustomerData({
                    fullname: selectedCustomer.firstname,
                    email: selectedCustomer.email,
                    address: selectedCustomer.address,
                });
            }
        }
    };



    const handleDirectCustomerChange = (field, value) => {
        if (field === 'fullname') {
            setFullname(value);
        } else if (field === 'email') {
            setEmail(value);
        } else if (field === 'address') {
            setBillingAddress(value);
        }
    };

    useEffect(() => {
        const fetchCustomers = async () => {
            try {
                const response = await axios.get('https://admin.bizstor.lk/api/get-customer-data');
                setCustomers(response.data.customers);
            } catch (error) {
                console.error('Error fetching customers:', error);
            }
        };

        fetchCustomers();
    }, []);

    useEffect(() => {
        if (order) {
            setOrderData(order);
            setSelectedOption(order.selectedOption || 'Direct Customer');
            setSelectedCustomerData({
                fullname: order.fullname || '',
                email: order.email || '',
                address: order.address || ''
            });
            setTerm(order.term || 'due on receipt');
            setDiscount(order.discount || 0);
            setPayamount(order.payamount || 0);
            setDescription(order.description || ''); 
            setInvoiceDate(order.invoicedate || ''); 
            setDueDate(order.duedate || ''); 
        }
    }, [order]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setOrderData({ ...orderData, [name]: value });
    };

    const getAllData = () => {
        return {
            selectedOption,
            selectedCustomerData,
            term,
            type,
            discount,
            payamount,
            description,
            invoicedate,
            duedate,
            items: orderData.items
        };
    };


    const handleSave = async () => {
        const allData = getAllData();
        console.log('All input data:', allData);
        
        setLoading(true);
        
        try {
            const response = await axios.put(`https://admin.bizstor.lk/api/orders/${order.id}`, allData);
            
            if (response.status === 200) {
                setAlertOpen(true);
                setOpen(false);
            }
        } catch (error) {
            console.error('Error updating order:', error);
            // Handle error appropriately here (e.g., show an error message)
        } finally {
            setLoading(false);
        }
    };
    


    const calculateTotalAmount = () => {
        return orderData.items.reduce((total, item) => total + (item.quantity * item.rate), 0);
    };

    const calculateBalancedue = () => {
        return calculateTotalAmount() - discount;
    };

    const calculateDiscount = () => {
        return discount;
    };



    const handleDeleteRow = (index) => {
        const newItems = orderData.items.filter((_, i) => i !== index);
        setOrderData({ ...orderData, items: newItems });
    };

    const handleAddRow = () => {
        setOrderData({
            ...orderData,
            items: [...orderData.items, { product: '', quantity: 0, rate: 0, price: 0, servicedate: '', tax: 0 }]
        });
    };

    useEffect(() => {
        if (order) {
            setOrderData(order);
            setSelectedOption(order.selectedOption || 'Direct Customer');
            setSelectedCustomerData({
                fullname: order.fullname || '',
                email: order.email || '',
                address: order.address || ''
            });
            setTerm(order.term || 'due on receipt');
            setDiscount(order.discount || '');
            setPayamount(order.payamount || '');
            setDescription(order.description || '');
            setInvoiceDate(order.invoicedate || '');
            setDueDate(order.duedate || '');
        }
    }, [order]);

    const handleInputChange = (index, field, value) => {
        const newItems = orderData.items.map((item, i) => {
            if (i === index) {
                const updatedItem = { ...item, [field]: value };
                if (field === 'product') {
                    updatedItem.rate = productPrices[value] || '';
                    updatedItem.price = (updatedItem.rate || 0) * (item.quantity || 0);
                }
                if (field === 'quantity' || field === 'rate') {
                    updatedItem.price = (updatedItem.rate || 0) * (updatedItem.quantity || 0);
                }
                return updatedItem;
            }
            return item;
        });

        setOrderData({ ...orderData, items: newItems });

        if (field === 'product' && value !== '' && index === orderData.items.length - 1) {
            handleAddRow();
        }
    };

 



    const handleClose = () => setOpen(false);
    const handleClickOpen = () => setOpen(true);

    const [products, setProducts] = useState([]);

    useEffect(() => {
        // Fetch product data from Laravel backend
        axios.get('https://admin.bizstor.lk/api/get-product-data')
            .then(response => {
                setProducts(response.data.products);
            })
            .catch(error => {
                console.error('Error fetching product data:', error);
            });
    }, []);

    const productPrices = {};
    products.forEach(product => {
        productPrices[product.product] = product.price;
    });

    useEffect(() => {
        const invoiceDateObj = new Date(invoicedate);
        let daysToAdd = 0;

        switch (term) {
            case 'net 15':
                daysToAdd = 15;
                break;
            case 'net 30':
                daysToAdd = 30;
                break;
            case 'net 60':
                daysToAdd = 60;
                break;
            default:
                daysToAdd = 0;
        }

        invoiceDateObj.setDate(invoiceDateObj.getDate() + daysToAdd);
        setDueDate(invoiceDateObj.toISOString().split('T')[0]);
    }, [term, invoicedate]);


    return (
        <React.Fragment>
            <Box>
                <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
                    <Button variant="outlined" onClick={handleClickOpen} style={{ backgroundColor: 'black', color: 'white' }}>
                        Edit Order
                        <PaymentsIcon sx={{ marginLeft: '10px' }} />
                    </Button>
                </Box>
                <Dialog
                    fullScreen
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Transition}
                >
                    <AppBar sx={{ position: 'relative', bgcolor: 'black' }}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                                <CloseIcon sx={{ color: 'white' }} />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1, color: 'white' }} variant="h6" component="div">
                                Edit Order
                            </Typography>
                        </Toolbar>
                    </AppBar>

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            maxWidth: '1200px',
                            margin: '0 auto',
                            boxShadow: 1,
                            padding: '20px',
                            borderRadius: '5px',
                            bgcolor: 'background.paper',
                            marginTop: '30px',
                            position: 'relative',
                        }}
                    >
                        <Typography variant="h6">
                            <b>Invoice Update</b>
                        </Typography>
                        <hr style={{ borderTop: '1px solid orange', width: '100%', margin: '10px 0' }} />

                        <TableContainer>
                            <Table sx={{ marginBottom: '30px' }}>
                                <TableHead />
                                <TableBody>
                                    <TableRow>
                                        <TableCell style={{ width: '33%' }}>
                                            <FormControl fullWidth variant="outlined" size="small">
                                                <InputLabel>Customer</InputLabel>
                                                <Select
                                                    value={selectedOption}
                                                    onChange={handleOptionChange}
                                                    fullWidth
                                                    label="Customer"
                                                    variant="outlined"
                                                    inputProps={{ style: { minWidth: '100%' } }}
                                                >
                                                    <MenuItem value="Direct Customer">Direct Customer</MenuItem>
                                                    <ListSubheader>Existing Customers</ListSubheader>
                                        {customers.map((customer) => (
                                            <MenuItem key={customer.id} value={customer.id}>
                                                {customer.firstname} - {customer.email}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                            </FormControl>
                                        </TableCell>
                                        <TableCell style={{ width: '33%' }}>
                                            <TextField
                                                label="Full Name"
                                                variant="outlined"
                                                value={selectedCustomerData.fullname}
                                                onChange={(e) => handleDirectCustomerChange('fullname', e.target.value)}
                                                disabled={selectedOption !== 'Direct Customer'}
                                                fullWidth
                                                size="small"
                                            />
                                        </TableCell>
                                        <TableCell style={{ width: '33%' }}>
                                            <TextField
                                                label="Email"
                                                variant="outlined"
                                                value={selectedCustomerData.email}
                                                onChange={(e) => handleDirectCustomerChange('email', e.target.value)}
                                                disabled={selectedOption !== 'Direct Customer'}
                                                fullWidth
                                                size="small"
                                            />
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell>
                                            <TextField
                                                label="Billing Address"
                                                variant="outlined"
                                                value={selectedCustomerData.address}
                                                onChange={(e) => handleDirectCustomerChange('address', e.target.value)}
                                                disabled={selectedOption !== 'Direct Customer'}
                                                fullWidth
                                                size="small"
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <FormControl variant="outlined" fullWidth size="small">
                                                <InputLabel id="term-label">Terms</InputLabel>
                                                <Select
                                                    labelId="term-label"
                                                    id="term"
                                                    value={term}
                                                    onChange={(e) => setTerm(e.target.value)}
                                                    label="Terms"
                                                >
                                                    <MenuItem value="due on receipt">Due on Receipt</MenuItem>
                                                    <MenuItem value="net 15">Net 15</MenuItem>
                                                    <MenuItem value="net 30">Net 30</MenuItem>
                                                    <MenuItem value="net 60">Net 60</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </TableCell>

                                        <TableCell>
                                            <FormControl variant="outlined" fullWidth size="small">
                                                <InputLabel id="type-label">Type</InputLabel>
                                                <Select
                                                    labelId="type-label"
                                                    id="type"
                                                    value={orderData.type || ''}
                                                    onChange={(e) => setOrderData({ ...orderData, type: e.target.value })}
                                                    label="Type"
                                                >
                                                    <MenuItem value="sell">Sell</MenuItem>
                                                    <MenuItem value="return">Return</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </TableCell>

                                    </TableRow>
                                    <TableRow>
                            <TableCell>
                                <TextField
                                    type="date"
                                    label="Invoice Date"
                                    value={invoicedate}
                                    onChange={(e) => setInvoiceDate(e.target.value)}
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    style={{ width: '100%' }} // Set width to 100% of parent cell

                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    type="date"
                                    label="Due Date"
                                    value={duedate}
                                    onChange={(e) => setDueDate(e.target.value)}
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    style={{ width: '100%' }} // Set width to 100% of parent cell

                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </TableCell>
                        </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>

                        
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'right',
                        justifyContent: 'flex-end',
                        marginTop: '10px',
                        textAlign: 'right',
                    }}
                >
                    <Typography variant="subtitle1">
                        {term === 'due on receipt' ? 'TOTAL' : 'BALANCE DUE'}
                    </Typography>
                    <Typography variant="h4" fontWeight="bold">
                        Rs.{calculateBalancedue()}.00
                    </Typography>
                </Box>


            <Box display="flex" justifyContent="flex-end" marginTop="15px">
                <Button variant="contained" color="primary" onClick={handleAddRow} size="small" style={{ backgroundColor: 'black', color: 'white' }}
                >
                    Add Line
                </Button>
            </Box>

                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Product</TableCell>
                                        <TableCell>Quantity</TableCell>
                                        <TableCell>Rate</TableCell>
                                        <TableCell>Price</TableCell>
                                        <TableCell>Service Date</TableCell>
                                        <TableCell>Tax</TableCell>
                                        <TableCell>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {orderData.items.map((item, index) => (
                                        <TableRow key={index}>
                                            <TableCell>
                                            <FormControl fullWidth>
                                            <Select
                                                    type="text"
                                                    value={item.product}
                                                    onChange={(e) => handleInputChange(index, 'product', e.target.value)}
                                                    fullWidth
                                                    size="small"
                                                >
                                                {Object.keys(productPrices).map((product) => (
                                                <MenuItem key={product} value={product}>
                                                    {product}
                                                </MenuItem>
                                            ))}
                                             </Select>
                                             </FormControl>
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    type="number"
                                                    value={item.quantity}
                                                    onChange={(e) => handleInputChange(index, 'quantity', e.target.value)}
                                                    fullWidth
                                                    size="small"
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    type="number"
                                                    value={item.rate}
                                                    onChange={(e) => handleInputChange(index, 'rate', e.target.value)}
                                                    fullWidth
                                                    size="small"
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    type="number"
                                                    value={item.price }
                                                    onChange={(e) => handleInputChange(index, 'price', e.target.value)}
                                                    fullWidth
                                                    disabled
                                                    size="small"
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    type="date"
                                                    value={item.servicedate }
                                                    onChange={(e) => handleInputChange(index, 'servicedate', e.target.value)}
                                                    fullWidth
                                                    size="small"
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    type="number"
                                                    value={item.tax }
                                                    onChange={(e) => handleInputChange(index, 'tax', e.target.value)}
                                                    fullWidth
                                                    size="small"
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <IconButton color="error" onClick={() => handleDeleteRow(index)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>


                        <Grid container spacing={1} style={{ marginTop: '15px' }}>
                <Grid item xs={6}>
                    <TextField
                        label="Description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        variant="outlined"
                        fullWidth
                        multiline  // Enable multiline input
                        rows={4}  // Adjust the number of rows as needed
                        size="small"
                    />
                </Grid>

                <Grid item xs={6} style={{ textAlign: 'right' }}>
                    <TextField
                        label="Discount (%)"
                        type="number"
                        value={discount}
                        onChange={(e) => setDiscount(Number(e.target.value))}
                        variant="outlined"
                        fullWidth
                        size="small"
                        sx={{
                            width: '25%',  // Adjust the width as needed
                            marginRight: '20px', // Adjust margin as needed
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container justifyContent="flex-end">
      <Grid item xs={6} style={{ textAlign: 'right' }}>
        <TextField
          label="Pay Amount"
          type="number"
          value={payamount}
          onChange={(e) => setPayamount(e.target.value)}
          variant="outlined"
          fullWidth
          size="small"
          sx={{
            width: '25%',  // Adjust the width as needed
            marginRight: '20px', // Adjust margin as needed
          }}
        />
      </Grid>
    </Grid>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column', // Stack items vertically
                    alignItems: 'right', // Center items horizontally
                    justifyContent: 'flex-end', // Align items to the end (right in this case)
                    marginTop: '20px', // Adjust margin as needed
                    textAlign: 'right', // Center text inside Typography
                }}
            >
                <Typography variant="subtitle1">
                    Total Amount : Rs.{calculateTotalAmount() || 0}.00
                </Typography>
                <Typography variant="subtitle1">
                    Pay amount: Rs.{payamount || 0}.00
                </Typography>
                <Typography variant="subtitle1">
                    Discount : Rs.-{calculateDiscount()}.00
                </Typography>
                <Typography variant="subtitle1">

                    {term === 'due on receipt' ? 'Sub Total :' : 'Balance Due :'} Rs.{calculateBalancedue() || 0}.00
                </Typography>
            </Box>

                        <Box display="flex" justifyContent="flex-end" mt={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSave}
                                disabled={loading}
                                startIcon={loading ? <PaymentsIcon /> : null}
                            >
                                {loading ? 'Saving...' : 'Update Order'}
                            </Button>
                        </Box>

                        <Snackbar
                            open={alertOpen}
                            autoHideDuration={6000}
                            onClose={() => setAlertOpen(false)}
                            message="Order saved successfully"
                        />
                    </Box>
                </Dialog>
            </Box>
        </React.Fragment>
    );
};

export default EditOrder;
