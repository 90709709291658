import logo from './logo/logo.jpg'; // Import the logo image
import addressIcon from './logo/icon/gps.png'; // Import the address icon
import emailIcon from './logo/icon/e-mail.png'; // Import the email icon
import webIcon from './logo/icon/world-wide-web.png'; // Import the web icon
import phoneIcon from './logo/icon/phone.png'; // Import the phone icon

const calculateTotalAmount = (rows, discount) => {
    const total = rows.reduce((sum, row) => {
        const rowPrice = parseFloat(row.price || 0);
        const rowTax = row.tax ? (rowPrice * (parseFloat(row.tax) / 100)) : 0;
        return sum + rowPrice + rowTax;
    }, 0);

    const discountAmount = (total * (parseFloat(discount) || 0)) / 100;

    return total - discountAmount;
};

const calculateTotal = (rows) => {
    const total = rows.reduce((sum, row) => {
        const rowPrice = parseFloat(row.price || 0);
        return sum + rowPrice;
    }, 0);


    return total;
};

const calculateDiscountAmount = (rows, discount) => {
    const total = rows.reduce((sum, row) => {
        const rowPrice = parseFloat(row.price || 0);
        const rowTax = row.tax ? (rowPrice * (parseFloat(row.tax) / 100)) : 0;
        return sum + rowPrice + rowTax;
    }, 0);

    const discountAmount = (total * (parseFloat(discount) || 0)) / 100;

    return discountAmount;
};

const calculateTotalTaxAmount = (items) => {
    // Calculate the total amount considering item-specific taxes
    const totalAmount = items.reduce((acc, item) => {
        const itemTotal = item.price * (item.tax / 100);
        return acc + itemTotal;
    }, 0);

    return totalAmount;
};

export const printFormData = (data) => {
    const printWindow = window.open('', '_blank');
    const total = calculateTotal(data.items);
    const totalAmount = calculateTotalAmount(data.items, data.discount);
    const totalDiscount = calculateDiscountAmount(data.items, data.discount);
    const taxamount = calculateTotalTaxAmount(data.items);
    const payAmount = parseFloat(data.payamount || 0);
    const payDue = totalAmount - payAmount;
    const status = data.term === 'due on receipt' ? 'PAID' : totalAmount > payAmount ? 'PENDING' : 'Unpaid'

    const htmlContent = `
    <html>
    <head>
        <title>Invoice</title>
        <style>
        body {
            font-family: Arial, sans-serif;
            padding: 20px;
            color: #333;
            background-color: #f5f5f5;
            box-shadow: 0 0 10px rgba(0,0,0,0.1); 
        }
        h1,  h3 {
            text-align: center;
            color: #0056b3;
            margin: 10px 0;
        }
              h2, {
            text-align: center;
            color: #333;
            margin: 10px 0;
        }
        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;
            border-bottom: 2px solid #ddd;
            padding-bottom: 10px;
            background-color: #fff;
            padding: 10px;
            border-radius: 8px;
        }
        .header .logo {
            display: flex;
            align-items: center;
        }
        .header .logo img {
            width: 120px;
            height: auto;
            margin-right: 15px;
        }
        .header .company-info {
            text-align: right;
            font-size: 14px;
        }
        .header .company-info p {
            margin: 5px 0;
            display: flex;
            align-items: center;
        }
        .header .company-info img {
            width: 10%; 
            height: auto;
            margin-right: 8px;
        }
        .company-info-gap {
            margin-top: 20px;
            font-size: 14px;
        }
        .details {
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
        }
        .customer-details {
            width: 48%;
            text-align:left; 
        }
        .invoice-details {
            text-align:right; 
        }
        .customer-details h3 {
            text-align:left;
        }
        .invoice-details h3 {
            text-align:right;
        }
        table {
            width: 100%;
            border-collapse: collapse;
            margin-bottom: 20px;
            background-color: #fff;
            border-radius: 8px;
        }
        th, td {
            border: 1px solid #ddd;
            padding: 12px;
            text-align: left;
            font-size: 14px;
        }
        th {
            background-color: #f4f4f4;
        }
        .total {
            font-weight: bold;
            background-color: #f4f4f4;
        }
        .payment-table {
            margin-top: 20px;
            background-color: #fff;
            padding: 20px;
            border-radius: 8px;
        }
        .bordered {
            border: none;
        }
        .signature {
            margin-top: 30px;
            padding: 10px;
            background-color: #fff;
            border-top: 2px solid #ddd;
            border-radius: 8px;
        }
        .signature p {
            margin: 5px 0;
        }
            .company-info-gap img {
            width: 15px;
            height: auto;
            margin-right: 8px;
        }
            .status {
            text-align: center;
            font-size: 1.5em;
            font-weight: bold;
            margin-bottom: 20px;
            color: ${status === 'PAID' ? '#3d9c45' : '#c9a838'};
        }
    </style>
    </head>
    <body>
        <div class="header">
        <div class="logo">
        <img src=${`https://admin.bizstor.lk/storage/app/public/images/${data.image}`} alt="Logo">
        <h2> ${data.company}</h2>
        </div>
        <div class="company-info-gap">
        <p><img src="${addressIcon}" alt="Address Icon"> Address: ${data.address}</p>
        <p><img src="${emailIcon}" alt="Email Icon"> Email: ${data.uemail}</p>
        ${data.web ? `<p><img src="${webIcon}" alt="Web Icon"> Web: ${data.web}</p>` : ''}
        <p><img src="${phoneIcon}" alt="Phone Icon"> Phone: ${data.phone}</p>
        </div>

        </div>
        <h1>INVOICE</h1>
        <div class="details">
        <div class="customer-details">
            <h3>Customer Details</h3>
            <p><strong>Name :</strong> ${data.fullname}</p>
            <p><strong>Email :</strong> ${data.email}</p>
            <p><strong>Customer :</strong> ${data.customer}</p>
            <p><strong>Billing Address :</strong> ${data.address}</p>
        </div>
        <div class="invoice-details">    
            <h3>Invoice Details</h3>
            <p><strong>Invoice No :</strong> ${data.invoiceNo}</p>
            <p><strong>Invoice Date :</strong> ${data.invoicedate}</p>
            <p><strong>Due Date :</strong> ${data.duedate}</p>
            <p><strong>Term :</strong> ${data.term}</p>
        </div>
        </div>
        <div>
        <div class="status">
    ${status}
</div>

        </div>
        <h3></h3>
        <table class="bordered">
            <thead>
                <tr>
                    <th>#</th>
                    <th>Product</th>
                    <th>Qty</th>
                    <th>Rate</th>
                    <th>Tax</th>
                    <th>Price</th>
                </tr>
            </thead>
            <tbody>
                ${data.items.map((item, index) => `
                <tr>
                    <td>${index + 1}</td>
                    <td>${item.product}</td>
                    <td>${item.quantity}</td>
                    <td>${Number(item.rate).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                    <td>${item.tax}%</td>
                    <td>${Number(item.price).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                </tr>
                `).join('')}
                <tr>
                    <td colspan="5" class="total" style="text-align:right;">TOTAL</td>
                    <td class="total">Lkr.${total.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                </tr>
                <tr>
                    <td colspan="5" class="total" style="text-align:right;">DISCOUNT</td>
                    <td class="total">Lkr.${totalDiscount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                </tr>
                <tr>
                    <td colspan="5" class="total" style="text-align:right;">TAX</td>
                    <td class="total">Lkr.${(taxamount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                </tr>
                <tr>
                    <td colspan="5" class="total" style="text-align:right;">GRAND TOTAL</td>
                    <td class="total">Lkr.${totalAmount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                </tr>
            </tbody>
        </table>
        ${data.term !== 'due on receipt' ? `
        <div class="payment-table">
            <h3>Payment Details</h3>
            <table class="bordered">
                <tr>
                    <td><strong>Payment:</strong></td>
                    <td>Lkr.${payAmount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                </tr>
                <tr>
                    <td><strong>Payable:</strong></td>
                    <td>Lkr.${payDue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                </tr>
            </table>
        </div>
        ` : ''}
        <div class="signature">
            <p><strong>Authorized Signature:</strong></p>
            <p style="margin-top: 60px;">________________________</p>
        </div>
    </body>
    </html>
    `;

    printWindow.document.write(htmlContent);
    printWindow.document.close();

    setTimeout(() => {
        printWindow.print();
    }, 1000);
};
