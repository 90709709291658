import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Typography,
  Container,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  IconButton,
  Avatar,
  Snackbar,
  Alert,
  Slide,
  AppBar,
  Toolbar,
  DialogContentText, DialogTitle, CircularProgress, Grid,Tab,Tabs,Paper
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import LogoutIcon from '@mui/icons-material/Logout';
import CloseIcon from '@mui/icons-material/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  
function UserProfile() {
  const [user, setUser] = useState({});
  const [open, setOpen] = useState(false); // State to handle dialog open/close
  const [name, setName] = useState('');
  const [companyname, setCompanyName] = useState('');
  const [address, setAddress] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [website, setWebsite] = useState('');
  const [companyemail, setCompanyEmail] = useState('');
  const [password, setPassword] = useState('');
  const [image, setImage] = useState(null); // State to handle image upload
  const [companyimage, setCompanyImage] = useState(null); // State to handle image upload
  const navigate = useNavigate();
  const [token, setToken] = useState(() => localStorage.getItem('token') || null);
  const [snackbarOpen, setSnackbarOpen] = useState(false); // State for Snackbar visibility
  const [snackbarMessage, setSnackbarMessage] = useState(''); // State for Snackbar message
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // State for Snackbar severity
  const [loading, setLoading] = useState(false);
  const [openLogoutDialog, setOpenLogoutDialog] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    if (token) {
      localStorage.setItem('token', token);
    }
  }, [token]);

  useEffect(() => {

    const fetchProfile = async () => {
      if (!token) return; // Exit if no token

      try {
        const response = await axios.get('https://admin.bizstor.lk/api/profile', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUser(response.data);
        setName(response.data.name);
        setCompanyName(response.data.companyname);
        setAddress(response.data.address);
        setPhone(response.data.phone);
        setCompanyEmail(response.data.companyemail);
        setEmail(response.data.email);
        setWebsite(response.data.website);
      } catch (error) {
        console.log('');
      }
    };

    fetchProfile();
  }, [token]);

  const handleOpenLogoutDialog = () => {
    setOpenLogoutDialog(true);
  };

  const handleCloseLogoutDialog = () => {
    setOpenLogoutDialog(false);
  };

  const handleLogout = async () => {
    setLoading(true);
    try {
      await axios.post('https://admin.bizstor.lk/api/logout', null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      localStorage.removeItem('token');
      setToken('');
      handleSnackbar('Logout successfully!', 'success');
      navigate('/');
    } catch (error) {
      handleSnackbar('Failed to logout!', 'error');
    } finally {
      setLoading(false);
      setOpenLogoutDialog(false);
    }
  };


  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleUpdateProfile = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append('name', name);
    formData.append('companyname', companyname);
    formData.append('address', address);
    formData.append('phone', phone);
    formData.append('email', email);
    formData.append('website', website);
    formData.append('companyemail', companyemail);
    if (password) {
      formData.append('password', password);
    }
    if (image) {
      formData.append('image', image);

    }

    try {
      const response = await axios.post('https://admin.bizstor.lk/api/profile/update', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      setUser(response.data.user);
      handleSnackbar('Profile updated successfully!', 'success');
      handleCloseDialog();
      setLoading(false);
    } catch (error) {
      handleSnackbar('Failed to update profile!', 'error');
      setLoading(false);
    }
  };

  const handleSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };




  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box sx={{ minHeight: '100vh', p: 4 }}>
      <Container maxWidth="md">
        <Paper
          elevation={3}
          sx={{
            p: 3,
            mt: 3,
            borderRadius: '0.5rem',
            backgroundColor: '#fff',
            overflow: 'hidden',
          }}
        >
          <form>
            <Grid container spacing={2}>
              {/* Profile Image Section */}
              <Grid item xs={12} md={4}>
                <Box sx={{ textAlign: 'center' }}>
                  <Avatar
              src={`https://admin.bizstor.lk/storage/app/public/images/${user.image}`}
              alt={user.name}
              sx={{
                width: 120,
                height: 120,
                mb: 5,
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                border: '3px solid #4caf50',
              }}
                  />
                </Box>
              </Grid>

              {/* Profile Info Section */}
              <Grid item xs={12} md={6}>
                <Box sx={{ mb: 3 }}>
                  <Typography variant="h5" sx={{ color: '#333' }}>{user.name}</Typography>
                </Box>
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  sx={{
                    mb: 3,
                    '& .MuiTab-root': { fontWeight: 600, textTransform: 'none' },
                    '& .Mui-selected': { color: '#0062cc', borderBottom: '2px solid #0062cc' },
                  }}
                >
                  <Tab label="User" />
                  <Tab label="Company" />
                </Tabs>
              </Grid>

              
            </Grid>

            <Grid container spacing={2} mt={1}>
              <Grid item xs={12} md={4}>
                <Box sx={{ padding: '14%', mt: -3 }}>
                <IconButton
                color="error"
                onClick={handleOpenLogoutDialog}
                sx={{ bgcolor: '#ffebee', marginRight: '10px' }}
              >
                <LogoutIcon />
              </IconButton>
              <IconButton
                color="primary"
                onClick={handleOpenDialog}
                sx={{ bgcolor: '#e3f2fd' }}
              >
                <EditIcon />
              </IconButton>                
              </Box>
              </Grid>

              {/* Profile Tabs Content */}
              <Grid item xs={12} md={8}>
                {tabValue === 0 && (
                  <Box>
                  <Typography sx={{ fontSize: 12, color: '#818182', fontWeight: 600, mb: 1 }}>Name : {user.name}</Typography>
                  <Typography sx={{ fontSize: 12, color: '#818182', fontWeight: 600, mt: 1 }}>Email : {user.email}</Typography>
                  </Box>
                )}
                {tabValue === 1 && (
                  <Box>
                  <Typography sx={{ fontSize: 12, color: '#818182', fontWeight: 600, mb: 1 }}>Name : {user.companyname}</Typography>
                  <Typography sx={{ fontSize: 12, color: '#818182', fontWeight: 600, mb: 1 }}>Email : {user.companyemail}</Typography>
                  <Typography sx={{ fontSize: 12, color: '#818182', fontWeight: 600, mb: 1 }}>Address : {user.address}</Typography>
                  <Typography sx={{ fontSize: 12, color: '#818182', fontWeight: 600, mb: 1 }}>Website : {user.website}</Typography>
                  <Typography sx={{ fontSize: 12, color: '#818182', fontWeight: 600, mb: 1 }}>Phone : {user.phone}</Typography>
                  </Box>
                )}
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Container>


      <Dialog
        open={openLogoutDialog}
        onClose={handleCloseLogoutDialog}
        aria-labelledby="logout-dialog-title"
      >
        <DialogTitle id="logout-dialog-title">Confirm Logout</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to log out?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseLogoutDialog} color="primary" disabled={loading}>
            Cancel
          </Button>
          <Button onClick={handleLogout} color="primary" autoFocus disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Logout'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for editing profile */}
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        TransitionComponent={Transition}

      >
        <AppBar sx={{ position: 'relative', bgcolor: 'black' }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleCloseDialog} aria-label="close">
              <CloseIcon sx={{ color: 'white' }} />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1, color: 'white' }} variant="h6" component="div">
              Edit Profile
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <TextField
            margin="dense"
            label="Name"
            type="text"
            size='small'
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Email"
            type="email"
            size='small'
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled
          />
          <TextField
            margin="dense"
            label="Company Name"
            type="text"
            size='small'
            fullWidth
            value={companyname}
            onChange={(e) => setCompanyName(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Address"
            type="text"
            size='small'
            fullWidth
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Phone"
            type="text"
            size='small'
            fullWidth
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Website"
            type="text"
            size='small'
            fullWidth
            value={website}
            onChange={(e) => setWebsite(e.target.value)}
          />

          <TextField
            margin="dense"
            label="Company Email"
            type="email"
            size='small'
            fullWidth
            value={companyemail}
            onChange={(e) => setCompanyEmail(e.target.value)}
          />
          {/**  <TextField
              margin="dense"
              label="Password"
              type="password"
              size='small'
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />*/}
          {/**  <Button
              variant="contained"
              component="label"
              sx={{
                mt: 2,
                backgroundColor: 'black',
                '&:hover': {
                  backgroundColor: 'black',
                },
              }}
            >
              Upload Logo
              <input
                type="file"
                hidden
                accept="image/*"
                onChange={handleImageChange}
              />
            </Button> */}

          <input
            accept="image/*"
            type="file"
            onChange={handleImageChange}
            style={{ marginTop: '20px' }}
          />
          <Typography variant="body1" sx={{ mt: 1, fontSize: '1rem', color: '#555' }}>
            Note: Please upload PNG file to logo.
          </Typography>
          {/**   <Button
              variant="contained"
              component="label"
              sx={{
                mt: 2,
                ml: 2,
                backgroundColor: 'black',
                '&:hover': {
                  backgroundColor: 'black',
                },
              }}
            >
              Upload Company Logo
              <input
                type="file"
                hidden
                accept="image/*"
                onChange={handleImageChange}
              />
            </Button>*/}
        </DialogContent>
        <DialogActions>
          {/** <Button onClick={handleCloseDialog} color="secondary">
            Cancel
          </Button>*/}
          <Button onClick={handleUpdateProfile} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      {loading && (
        <Box sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          bgcolor: 'white',
          zIndex: 9999
        }}>
          <l-trio
            size="50"
            speed="1.3"
            color="red"
          ></l-trio>
        </Box>
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{
            width: '100%',

          }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

    </Box>
  );
}


export default UserProfile;
