import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    CircularProgress,
    Typography,
    Collapse,
    IconButton,
    TablePagination,
    Snackbar,
    Checkbox,
    Menu,
    MenuItem,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import PaymentsIcon from '@mui/icons-material/Payments';
import MakePayment from '../Popupdialogbox/Makepayment';
import EditOder from '../Popupdialogbox/EditOder';
import PrintIcon from '@mui/icons-material/Print';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { quantum, trio } from 'ldrs';

quantum.register();
trio.register();

const productPrices = {
    "DOT4 250ml (White)": 850,
    "DOT3 250ml (Red)": 580,
    "DOT3 250ml (White)": 580,
    "DOT3 100ml (Red)": 290,
    "DOT3 100ml (White)": 290,
    "DOT3 50ml (Red)": 170,
    "DOT3 50ml (White)": 170,
    "Green 1L": 750,
    "4L + 4 Water(L)": 1800,
    "4L Pure": 1350
};

const InvoiceTable = () => {
    const [fetchedData, setFetchedData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openRow, setOpenRow] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [deleteMessage, setDeleteMessage] = useState('');
    const [alertOpen, setAlertOpen] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]); 
    const [anchorEl, setAnchorEl] = useState(null); 
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false); 
    const [paymentDialogOpen, setPaymentDialogOpen] = useState(false);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [payments, setPayments] = useState([]);
    const [open, setOpen] = useState(false);



    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axios.get('https://admin.bizstor.lk/api/get-data');
            setFetchedData(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        fetchPaymentData();
    }, []);

    const fetchPaymentData = async () => {
        setLoading(true);
        try {
            const response = await axios.get('https://admin.bizstor.lk/api/get-payment-data');
            setPayments(response.data);
        } catch (error) {
            console.error('Error fetching payment data:', error);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        fetchData();
    }, []);

    const handleRowClick = (index) => {
        setOpenRow(openRow === index ? null : index);
    };

    const handleRowSelect = (event, orderId) => {
        const selectedIndex = selectedRows.indexOf(orderId);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedRows, orderId);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedRows.slice(1));
        } else if (selectedIndex === selectedRows.length - 1) {
            newSelected = newSelected.concat(selectedRows.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedRows.slice(0, selectedIndex),
                selectedRows.slice(selectedIndex + 1),
            );
        }

        setSelectedRows(newSelected);
    };

    const isSelected = (orderId) => selectedRows.indexOf(orderId) !== -1;
    const handleClickOpen = () => setOpen(true);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const calculateTotalAmount = (items) => {
        return items.reduce((acc, item) => acc + item.price, 0);
    };


    const getPriceForProduct = (productName) => {
        return productPrices[productName] || 0;
    };

    const handleDelete = async () => {
        setLoading(true);
        try {
            // Delete selected rows
            const promises = selectedRows.map(async (id) => {
                const response = await axios.delete(`https://admin.bizstor.lk/api/delete-data/${id}`);
                return response.status === 200;
            });
            const results = await Promise.all(promises);

            if (results.every((result) => result)) {
                setFetchedData(fetchedData.filter(order => !selectedRows.includes(order.id)));
                setDeleteMessage('Selected orders deleted successfully.');
                setAlertOpen(true); // Open Snackbar on successful delete
                setSelectedRows([]); // Clear selected rows
                setDeleteConfirmationOpen(false); // Close delete confirmation dialog
            } else {
                setDeleteMessage('Failed to delete selected orders.');
            }
        } catch (error) {
            console.error('Error deleting data:', error);
            setDeleteMessage('Error deleting orders.');
        } finally {
            setLoading(false);
        }
    };

    const clearDeleteMessage = () => {
        setDeleteMessage('');
    };

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleOpenDeleteConfirmation = () => {
        setDeleteConfirmationOpen(true);
    };

    const handleCloseDeleteConfirmation = () => {
        setDeleteConfirmationOpen(false);
    };

    const handleOpenPaymentDialog = (order) => {
        setSelectedOrder(order);
        setPaymentDialogOpen(true);
    };

    const handleOpenEditDialog = (order) => {
        setSelectedOrder(order);
        setEditDialogOpen(true);
    };

    const handleCloseEditDialog = () => {
        setEditDialogOpen(false);
    };

    const handleClosePaymentDialog = () => {
        setPaymentDialogOpen(false);
    };

    const calculateOrderTotalPayments = (orderId) => {
        // Calculate total payments made for this order
        return payments
            .filter(payment => payment.payment_id === orderId)
            .reduce((total, payment) => total + payment.amount, 0);
    };


    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                maxWidth: '1200px',
                margin: '0 auto',
                boxShadow: 1,
                padding: '20px',
                borderRadius: '5px',
                bgcolor: 'background.paper',
                marginTop: '30px',
                position: 'relative'
            }}
        >
            <Typography variant="h4" component="h1" gutterBottom>
                Invoice updated
            </Typography>
            {/* Snackbar for Alert */}
            <Snackbar
                open={alertOpen}
                autoHideDuration={6000}
                onClose={() => setAlertOpen(false)}
                message="Deleted successfully!"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            />
            {/* Delete Confirmation Dialog */}
            <Dialog
                open={deleteConfirmationOpen}
                onClose={handleCloseDeleteConfirmation}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Are you sure you want to delete selected orders?"}</DialogTitle>
                <DialogActions>
                    <Button onClick={handleCloseDeleteConfirmation} color="primary">
                        No
                    </Button>
                    <Button onClick={handleDelete} color="primary" autoFocus>
                        Yes
                    </Button>
                </DialogActions>

                {/* Loading indicator */}
                {loading && (
                    <Box sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100vw',
                        height: '100vh',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        bgcolor: 'white', // Optional: to give a slightly white overlay
                        zIndex: 9999
                    }}>
                        <l-trio
                            size="50"
                            speed="1.3"
                            color="red"
                        ></l-trio>
                    </Box>
                )}
            </Dialog>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
                <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={handleMenuOpen}
                    disabled={selectedRows.length === 0}
                >
                    <PrintIcon />
                </IconButton>
                <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={handleMenuOpen}
                    disabled={selectedRows.length === 0}
                >
                    <DeleteIcon sx={{
                        '&:hover': {
                            color: 'red',
                        },
                    }} />
                </IconButton>
                <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                >
                    <MenuItem onClick={handleOpenDeleteConfirmation}>Delete Selected Orders</MenuItem>
                </Menu>
            </Box>
            <TableContainer component={Paper} style={{ marginTop: '20px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell padding="checkbox">
                                <Checkbox
                                    indeterminate={selectedRows.length > 0 && selectedRows.length < fetchedData.length}
                                    checked={selectedRows.length === fetchedData.length}
                                    onChange={() => {
                                        if (selectedRows.length === fetchedData.length) {
                                            setSelectedRows([]);
                                        } else {
                                            setSelectedRows(fetchedData.map((order) => order.id));
                                        }
                                    }}
                                />
                            </TableCell>
                            <TableCell><b>Full Name</b></TableCell>
                            <TableCell><b>Customer Type</b></TableCell>
                            <TableCell><b>Term</b></TableCell>
                            <TableCell><b>Invoice Date</b></TableCell>
                            <TableCell><b>Due Date</b></TableCell>
                            <TableCell><b>Type</b></TableCell>
                            <TableCell><b>Total Amount</b></TableCell>
                            <TableCell><b>Status</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <Box sx={{
                                position: 'fixed',
                                top: 0,
                                left: 0,
                                width: '100vw',
                                height: '100vh',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                bgcolor: 'white', // Optional: to give a slightly white overlay
                                zIndex: 9999
                            }}>
                                <l-trio
                                    size="50"
                                    speed="1.3"
                                    color="red"
                                ></l-trio>
                            </Box>
                        ) : (
                            // Render fetched data
                            fetchedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((order, index) => (
                                <React.Fragment key={index}>
                                    <TableRow
                                        hover
                                        onClick={() => handleRowClick(index)}
                                        role="checkbox"
                                        aria-checked={isSelected(order.id)}
                                        tabIndex={-1}
                                        selected={isSelected(order.id)}
                                        style={{ backgroundColor: openRow === index ? '#f0f0f0' : 'inherit' }}
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={isSelected(order.id)}
                                                onChange={(event) => handleRowSelect(event, order.id)}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body2" fontWeight="bold">
                                                {order.fullname}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body2" fontWeight="bold">
                                                {order.customer}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body2" fontWeight="bold">
                                                {order.term}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body2" fontWeight="bold">
                                                {order.invoicedate}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body2" fontWeight="bold">
                                                {order.duedate}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body2" fontWeight="bold">
                                                {order.type}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body2" fontWeight="bold">
                                                Rs.{calculateTotalAmount(order.items)}.00
                                            </Typography>
                                        </TableCell>
                                        {/* Status cell based on 'term' condition */}
                                        <TableCell>

                                            <Typography
                                                variant="body2"
                                                fontWeight="bold"
                                                style={{
                                                    color:
                                                        order.term === 'due on receipt' ? 'green' : // If term is 'due on receipt', show 'paid'
                                                            order.payamount === 0 ? 'red' : // If payamount is explicitly 0, show 'unpaid'
                                                                calculateTotalAmount(order.items) === (order.payamount + calculateOrderTotalPayments(order.id)) ? 'green' : // If total amount matches, show 'paid'
                                                                    'orange' // Otherwise, show 'pending'
                                                }}
                                            >
                                                {order.term === 'due on receipt' ? 'paid' : // If term is 'due on receipt', show 'paid'
                                                    order.payamount === 0 ? 'unpaid' : // If payamount is explicitly 0, show 'unpaid'
                                                        calculateTotalAmount(order.items) === (order.payamount + calculateOrderTotalPayments(order.id)) ? 'paid' : // If total amount matches, show 'paid'
                                                            'pending' // Otherwise, show 'pending'
                                                }
                                            </Typography>
                                        </TableCell>



                                    </TableRow>


                                    <TableRow>
                                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
                                            <Collapse in={openRow === index} timeout="auto" unmountOnExit>
                                                <Box margin={1} padding={2} bgcolor="#f9f9f9" borderRadius={4} boxShadow={3}>
                                                    <Typography variant="h6" gutterBottom component="div" color="primary">
                                                        Order Details
                                                    </Typography>
                                                    <Table size="small" aria-label="purchases">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell><b>Product</b></TableCell>
                                                                <TableCell><b>Qty</b></TableCell>
                                                                <TableCell><b>Rate</b></TableCell>
                                                                <TableCell><b>Price</b></TableCell>
                                                                <TableCell><b>Service Date</b></TableCell>
                                                                <TableCell><b>Tax</b></TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {order.items.map((item, idx) => (
                                                                <TableRow key={idx}>
                                                                    <TableCell>{item.product}</TableCell>
                                                                    <TableCell>{item.quantity}</TableCell>
                                                                    <TableCell>Rs.{item.rate}.00</TableCell>
                                                                    <TableCell>Rs.{item.price}.00</TableCell>
                                                                    <TableCell>{item.servicedate}</TableCell>
                                                                    <TableCell>{item.tax}</TableCell>
                                                                </TableRow>
                                                            ))}
                                                            <TableRow>
                                                                <TableCell colSpan={3} align="right" ><b>Grand Total</b></TableCell>
                                                                <TableCell>
                                                                    {/* Calculate the grand total */}
                                                                    Rs.{order.items.reduce((acc, item) => acc + (item.quantity * item.rate), 0)}.00
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell colSpan={3} align="right">
                                                                    <Typography variant="body1" fontWeight={600}>Discount</Typography>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Typography variant="body1" fontWeight={600}>{order.discount}%</Typography>
                                                                </TableCell>
                                                            </TableRow>
                                                             {/*<TableRow>
                                                                <TableCell colSpan={3} align="right">
                                                                    <Typography variant="body1" fontWeight={700}>Total Amount</Typography>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Typography variant="body1" fontWeight={700}>
                                                                        Rs.{calculateTotalAmount(order.items)}.00
                                                                    </Typography>
                                                                </TableCell>
                                                            </TableRow>*/}
                                                            <TableRow>
                                                                <TableCell colSpan={3} align="right">
                                                                    <Typography variant="body1" fontWeight={600}>Pay Amount</Typography>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Typography variant="body1" fontWeight={600}>
                                                                        Rs. {order.term === 'due on receipt'
                                                                            ? calculateTotalAmount(order.items)
                                                                            : (order.payamount + calculateOrderTotalPayments(order.id))}.00
                                                                    </Typography>
                                                                </TableCell>
                                                            </TableRow>
                                                            {order.term !== 'due on receipt' && (
                                                                <TableRow>
                                                                    <TableCell colSpan={3} align="right">
                                                                        <Typography variant="body1" fontWeight={600}>Pay Due</Typography>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Typography variant="body1" fontWeight={600}>
                                                                            Rs.{calculateTotalAmount(order.items) - (order.payamount + calculateOrderTotalPayments(order.id))}.00
                                                                        </Typography>
                                                                    </TableCell>
                                                                </TableRow>
                                                            )}
                                                        </TableBody>

                                                    </Table>
                                                    <Typography variant="subtitle1" gutterBottom component="div" color="textSecondary" mt={2}>
                                                        Description
                                                    </Typography>
                                                    <Typography variant="body2" gutterBottom component="div">
                                                        {order.description}
                                                    </Typography>
                                                    <Box display="flex" justifyContent="flex-end" alignItems="center">
                                                        <IconButton onClick={() => handleOpenEditDialog(order)}>
                                                            <EditNoteIcon />
                                                        </IconButton>
                                                        {order.term !== 'due on receipt' && (
                                                            <IconButton onClick={() => handleOpenPaymentDialog(order)}>
                                                                <PaymentsIcon />
                                                            </IconButton>
                                                        )}
                                                    </Box>
                                                </Box>
                                            </Collapse>
                                        </TableCell>


                                    </TableRow>
                                </React.Fragment>
                            ))
                        )}
                    </TableBody>
                </Table>

                <TablePagination
                    rowsPerPageOptions={[5, 10, 15]}
                    component="div"
                    count={fetchedData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>

            <Dialog open={paymentDialogOpen} onClose={handleClosePaymentDialog}>
                <DialogTitle>Make Payment</DialogTitle>
                <DialogContent>
                    <MakePayment order={selectedOrder} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClosePaymentDialog} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={editDialogOpen} onClose={handleCloseEditDialog}>
                <DialogTitle>Edit Oder</DialogTitle>
                <DialogContent>
                    <EditOder order={selectedOrder} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseEditDialog} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

        </Box>
    );
};

export default InvoiceTable;
