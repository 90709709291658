import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
} from '@mui/material';

const BalanceSheet = () => {
  const [user, setUser] = useState({});
  const [token, setToken] = useState(() => localStorage.getItem('token') || null);
  const [investments, setInvestments] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchInvestments();
  }, []);

  useEffect(() => {
    if (token) {
      localStorage.setItem('token', token);
    }
  }, [token]);

  useEffect(() => {
    const fetchProfile = async () => {
      if (!token) return;
      try {
        const response = await axios.get('https://admin.bizstor.lk/api/profile', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUser(response.data);
      } catch (error) {
        console.log('');
      }
    };
    fetchProfile();
  }, [token]);

  useEffect(() => {
    if (user.id) {
      fetchInvestments();
    }
  }, [user.id]);

  const fetchInvestments = async () => {
    try {
      const response = await axios.get('https://admin.bizstor.lk/api/get-investment');
      if (user.id) {
        const filteredInvestments = response.data.investment.filter(
          (investment) => investment.usertoken.toLowerCase() === user.id.toString().toLowerCase()
        );
        setInvestments(filteredInvestments);
      } else {
        setInvestments([]); // In case user.id is not found
      }
    } catch (error) {
      console.error('Error fetching investment data:', error);
    } finally {
      setLoading(false); // Ensure loading is set to false after fetching
    }
  };

  // Group investments by person for `from === 'bank'`
  const fromBankByPerson = investments
    .filter((investment) => investment.from === 'bank')
    .reduce((acc, investment) => {
      const person = investment.person || 'Unknown';
      acc[person] = (acc[person] || 0) + Number(investment.amount);
      return acc;
    }, {});

  // Group investments by person for `to === 'bank'`
  const toBankByPerson = investments
    .filter((investment) => investment.to === 'bank')
    .reduce((acc, investment) => {
      const person = investment.person || 'Unknown';
      acc[person] = (acc[person] || 0) + Number(investment.amount);
      return acc;
    }, {});

  // Calculate total difference for each person
  const differenceByPerson = Object.keys(fromBankByPerson).map((person) => {
    const fromBankAmount = fromBankByPerson[person] || 0;
    const toBankAmount = toBankByPerson[person] || 0;
    return {
      person,
      balance: Math.abs((fromBankAmount - toBankAmount)).toFixed(2), // Use Math.abs() to get absolute value
    };
  });
  

  // Total sum for `from === 'bank'` and `to === 'bank'`
  const totalFromBank = investments
    .filter((investment) => investment.from === 'bank')
    .reduce((sum, investment) => sum + (Number(investment.amount) || 0), 0);

  const totalToBank = investments
    .filter((investment) => investment.to === 'bank')
    .reduce((sum, investment) => sum + (Number(investment.amount) || 0), 0);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '95%',
        maxWidth: '100%', // Changed to 100%
        margin: 'auto',
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
        padding: '10px',
        borderRadius: '5px',
        bgcolor: 'background.paper',
        marginTop: '30px',
        position: 'relative',
        paddingLeft: '20px'
      }}
    >
      <Typography variant="h4" gutterBottom>
        Balance Sheet
      </Typography>

      <Grid container spacing={2} sx={{mt:2}}>
        {/* Table for 'to === bank' data */}
        <Grid item xs={12}>
          <TableContainer>
            <Typography variant="h6" gutterBottom>
              Assets
            </Typography>
            <Table sx={{ width: '100%' }}> {/* Ensure full width of the table */}
              <TableHead>
                <TableRow>
                  <TableCell><b>Assets</b></TableCell>
                  <TableCell align="right"><b>TOTAL</b></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={2} align="center">
                      Loading...
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRow>
                    <TableCell>Bank</TableCell>
                    <TableCell align="right">
                        Lkr. {(totalToBank - totalFromBank).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                    <TableCell><b>Total Assets</b></TableCell>
                    <TableCell align="right">
                      <strong>
                        Lkr. {(totalToBank - totalFromBank).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      </strong>
                    </TableCell>
                  </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={12}>
          <TableContainer>
            <Typography variant="h6" gutterBottom>
              Liabilities and shareholder's equity
            </Typography>
            <Table sx={{ width: '100%' }}> {/* Ensure full width of the table */}
              <TableHead>
                <TableRow>
                  <TableCell><b>Current Liabilities</b></TableCell>
                  <TableCell align="right"><b></b></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={2} align="center">
                      Loading...
                    </TableCell>
                  </TableRow>
                ) : (
                  differenceByPerson.map(({ person, balance }) => (
                    <TableRow key={person}>
                      <TableCell>{person}</TableCell>
                      <TableCell align="right">Lkr. {balance.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</TableCell>
                    </TableRow>
                  ))
                )}
                <TableRow>
                    <TableCell><b>Total Liabilities</b></TableCell>
                    <TableCell align="right">
                      <strong>
                      Lkr. {differenceByPerson.reduce((total, { balance }) => total + parseFloat(balance), 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}                      </strong>
                    </TableCell>
                  </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BalanceSheet;
