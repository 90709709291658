import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    Typography,
    TextField,
    IconButton,
    Grid
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import PrintIcon from '@mui/icons-material/Print';
import { styled, keyframes } from '@mui/system';
import { printCustomerReport } from '../Reports/Print/printCustomerReport';

const slideInFromLeft = keyframes`
  0% {
    transform: translateX(-20px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const AnimatedTypography = styled(Typography)(({ theme }) => ({
    animation: `${slideInFromLeft} 0.5s ease-out`,
}));

const CustomerReport = () => {
    const [user, setUser] = useState({});
    const [token, setToken] = useState(() => localStorage.getItem('token') || null);
    const [loading, setLoading] = useState(false);
    const [fetchedData, setFetchedData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    useEffect(() => {
        if (token) {
            localStorage.setItem('token', token);
        }
    }, [token]);

    useEffect(() => {
        const fetchProfile = async () => {
            if (!token) return;

            try {
                const response = await axios.get('https://admin.bizstor.lk/api/profile', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setUser(response.data);
            } catch (error) {
                console.log('');
            }
        };

        fetchProfile();
    }, [token]);

    useEffect(() => {
        if (user.id) {
            fetchData();
        }
    }, [user.id]);

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axios.get('https://admin.bizstor.lk/api/get-data');
            if (user.id) {
                console.log('console.log("");:', user.id);

                const Orders = response.data.filter(order =>
                    order.usertoken.toLowerCase() === user.id.toString().toLowerCase()
                );

                setFetchedData(Orders);
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const filterData = (query, data) => {
        if (!query) {
            return data;
        } else {
            return data.filter((d) =>
                d.fullname.toLowerCase().includes(query.toLowerCase())
            );
        }
    };

    const calculateTotals = (data) => {
        const totalsByCustomer = {};

        data.forEach(order => {
            const customerName = order.fullname;
            let orderTotal = 0;

            order.items.forEach(item => {
                const itemTax = (item.price * (item.tax / 100)) || 0; // Calculate tax
                const itemTotal = item.price + itemTax;
                orderTotal += itemTotal;
            });

            const discountPercentage = order.discount || 0; // Default to 0 if undefined or null
            const discountAmount = (orderTotal * discountPercentage) / 100; // Calculate discount amount
            const finalAmount = orderTotal - discountAmount; // Apply discount to the order total

            if (totalsByCustomer[customerName]) {
                totalsByCustomer[customerName] += finalAmount;
            } else {
                totalsByCustomer[customerName] = finalAmount;
            }
        });

        return totalsByCustomer;
    };

    const uniqueData = Array.from(new Set(fetchedData.map(order => order.fullname)))
        .map(fullname => {
            const order = fetchedData.find(order => order.fullname === fullname);
            return {
                ...order,
                total: calculateTotals(fetchedData)[fullname] || 0
            };
        });

    const dataFiltered = filterData(searchQuery, uniqueData, user);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Box>
            <AnimatedTypography
                component="h1"
                sx={{
                    ml: 5,
                    mt: 3,
                    fontSize: '1rem',
                    color: 'secondary.main',
                    letterSpacing: '0.01em'
                }}
            >
                Dashboard / Report / Sales By Customer
            </AnimatedTypography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '95%',
                    maxWidth: 'auto',
                    margin: 'auto',
                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                    padding: '10px',
                    borderRadius: '5px',
                    bgcolor: 'background.paper',
                    marginTop: '30px',
                    position: 'relative'
                }}
            >
                <Typography variant="h4" >
                    Sales By Customer
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        mb: 2,
                        ml: 2
                    }}
                >
                    <TextField
                        variant="outlined"
                        margin="normal"
                        onChange={(e) => setSearchQuery(e.target.value)}
                        value={searchQuery}
                        size="small"
                        placeholder="Search…"
                        InputProps={{
                            startAdornment: (
                                <IconButton sx={{ padding: 0, mr: 1 }}>
                                    <SearchIcon />
                                </IconButton>
                            ),
                        }}
                        sx={{ width: '250px' }}
                    />
                    <IconButton
                        aria-label="print"
                        onClick={() => printCustomerReport(dataFiltered)}
                        sx={{ ml: 2 }}
                    >
                        <PrintIcon />
                    </IconButton>
                </Box>


                {/**   <Grid container sx={{ marginTop: 2, margin: 'auto', maxWidth: '95%' }}>
                    <Grid item xs={6} sx={{ textAlign: 'left' }}>
                        <Typography variant="subtitle1"><b></b></Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: 'right' }}>
                        <Typography variant="subtitle1"><b>Total</b></Typography>
                    </Grid>
                </Grid>*/}


                <TableContainer sx={{ marginTop: 2, margin: 'auto', maxWidth: '95%' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ textAlign: 'left' }}><Typography variant="subtitle2" fontWeight="bold">customer</Typography>
                                </TableCell>
                                <TableCell align="right"><Typography variant="subtitle2" fontWeight="bold">TOTAL</Typography></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dataFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((order) => (
                                <TableRow key={order.id}>
                                    <TableCell sx={{ textAlign: 'left' }}>{order.fullname}</TableCell>
                                    <TableCell sx={{ textAlign: 'right' }}>Lkr.{order.total.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    {/**   <TablePagination
                        rowsPerPageOptions={[5, 10, 15]}
                        component="div"
                        count={dataFiltered.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />*/}
                </TableContainer>
            </Box>
            {loading && (
                <Box sx={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: 'white',
                    zIndex: 9999
                }}>
                    <l-trio
                        size="50"
                        speed="1.3"
                        color="red"
                    ></l-trio>
                </Box>
            )}
        </Box>
    );
};

export default CustomerReport;
